<template>
  <div v-if="!loading && booking">
    <v-card flat style="background-color: var(--v-background-base) !important">
      <!------------------------------------------ TOP BAR WITH QUICK SEARCH ------------------------------------------>
      <v-toolbar color="greyBase" dark :extended="!$vuetify.breakpoint.mobile" flat extension-height="55">
        <v-toolbar-title>
          <v-btn icon class="mr-2 primaryText--text" @click="$router.go(-1)">
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-tooltip top v-if="booking.movementType">
            <template v-slot:activator="{ on }">
              <v-avatar size="24" v-on="on" :color="booking.movementType == 'EXPORT' ? 'deep-orange' : 'blue' " class="mx-1">
                <h6 style="color: white">
                  {{ booking.movementType.charAt(0) }}
                </h6>
              </v-avatar>
            </template>
            <span>{{ $Format.capitalizeFirstLetter(booking.movementType) }} Booking</span>
          </v-tooltip>
        
          <v-menu offset-y rounded>
            <template v-slot:activator="{ on: menuOn, attrs: menuAttrs }">
              <v-tooltip top>
                <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                  <v-btn :color="bookingTypeColor(booking.type)" icon rounded v-on="{ ...tooltipOn, ...menuOn }"
                    v-bind="{ ...tooltipAttrs, ...menuAttrs }" small class="mx-1">
                    <v-icon small>{{ bookingTypeIcon(booking.type) }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $Format.capitalizeFirstLetter(booking.type) }}</span>
              </v-tooltip>
            </template>
            <v-list dense color="greyBase" rounded>
              <v-list-item v-for="(item, index) in bookingTypes" :key="index" @click=" (booking.type = item.value), (bookingChange = true), manaulUpdateBookingType() ">
                <v-list-item-action class="mr-1 pr-1">
                  <v-btn icon>
                    <v-icon small>{{ item.icon }}</v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-content class="ml-0 pl-0">
                  <v-list-item-title>{{ item.value }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-menu offset-y rounded>
            <template v-slot:activator="{ on: menuOn, attrs: menuAttrs }">
              <v-tooltip top>
                <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                  <v-btn icon rounded v-on="{ ...tooltipOn, ...menuOn }" v-bind="{ ...tooltipAttrs, ...menuAttrs }"
                    small class="mx-1" v-if='booking.serviceType === "FCL" || booking.serviceType === "LCL"'>
                    <v-chip small color="indigo" class="mx-1 mr-2">{{ booking.serviceType }}</v-chip>
                  </v-btn>
                  <v-btn v-else icon rounded v-on="{ ...tooltipOn, ...menuOn }" v-bind="{ ...tooltipAttrs, ...menuAttrs }" small class="mx-1">
                    <v-chip v-if='booking.serviceType === "BREAKBULK"' small color="indigo" class="mx-1 mr-2">B/B</v-chip>
                  </v-btn>
                </template>
                <span>{{ booking.serviceType.toUpperCase() }}</span>
              </v-tooltip>
            </template>
            <v-list dense color="greyBase" rounded>
              <v-list-item v-for="(item, index) in serviceTypes" :key="index" @click=" (booking.serviceType = item.value), (bookingChange = true), manaulUpdateServiceType() ">
                <v-list-item-content class="ml-0 pl-0">
                  <v-list-item-title>{{ item.value }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-tooltip top v-if="booking.user">
            <template v-slot:activator="{ on }">
              <v-avatar size="24" class="mr-2" v-on="on" :color="booking.user.avatar ? 'white' : 'secondary'">
                <v-img v-if="booking.user.avatar" contain :src="booking.user.avatar"></v-img>
                <h4 v-else>{{ booking.user.firstname.charAt(0) }}</h4>
              </v-avatar>
            </template>
            <span style="font-size: 12px">{{ booking.user.firstname }} {{ booking.user.surname }}
              {{ "(" + formatDate(booking.createdAt).fullDate + ")" }}</span>
          </v-tooltip>

          <b class="mr-3">Manage Booking: {{ booking.orderNo }}</b>
          <v-menu offset-y rounded v-if="booking.state !== 'Cancelled' && booking.state !== 'Futile'">
            <template v-slot:activator="{ on }">

              <v-btn :color="statusColor(booking.state)" rounded v-on="on" small>{{ booking.state }}
                <v-icon small right>arrow_drop_down</v-icon></v-btn>
            </template>
            <v-list dense color="greyBase" rounded>
              <v-list-item
                v-for="(status, index) in bookingStatus.filter((x) => x.name !== 'Cancelled' || x.name !== 'Futile')"
                :key="index" @click="(booking.state = status.name),updateManaulStatus(),saveBooking()">
                <v-list-item-action class="mr-1 pr-1">
                  <v-avatar size="18" :color="status.color"></v-avatar>
                </v-list-item-action>
                <v-list-item-content class="ml-0 pl-0">
                  <v-list-item-title>{{ status.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu offset-y rounded v-else>
            <template v-slot:activator="{ on }">
              <v-btn :color="statusColor(booking.state)" rounded v-on="on" small>{{ booking.state }}
                <v-icon small right>arrow_drop_down</v-icon></v-btn>
            </template>
            <v-list dense color="greyBase" rounded>
              <v-list-item v-for="(status, index) in bookingStatus.filter( (x) => x.name === 'Cancelled' || x.name === 'Futile' )" 
              :key="index" @click="(booking.state = status.name), (bookingChange = true), updateManaulStatus()">
                <v-list-item-action class="mr-1 pr-1">
                  <v-avatar size="18" :color="status.color"></v-avatar>
                </v-list-item-action>
                <v-list-item-content class="ml-0 pl-0">
                  <v-list-item-title>{{ status.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-chip outlined style="border: none" class="ml-2" :key="routeKey">
            <v-tooltip bottom v-for="(location, index) in fullRoute" :key="index">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-chip pill small outlined class="primaryText--text">
                    <v-avatar size="24" left>
                      <v-img contain v-if="location.locode" :src="`https://cdn.loglive.io/flags/4x3/${location.locode .substring(0, 2) .toLowerCase()}.svg`"></v-img> </v-avatar>{{ location.locode }} </v-chip>
                  <v-icon class="mx-3" color="grey" small v-if="index < fullRoute.length - 1">east</v-icon>
                </div>
              </template>
              <span style="font-size: 12px">{{ location.type }}: {{ location.locode }}</span>
            </v-tooltip>
          </v-chip>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div v-if="!$vuetify.breakpoint.mobile" :key="userUpdateKey" class="mr-2">
          <v-tooltip top v-for="user in activeUsers" :key="user.connectionId">
            <template v-slot:activator="{ on, attrs }">
              <v-avatar v-bind="attrs" v-on="on" style="cursor: pointer" color="secondary" size="48"
                class="mt-0 pt-0 mx-1">
                <span v-if="!user.user.avatar" class="primaryText--text text-h5">{{ $Format.avatarAbbreviation(user.user.name) }}</span>
                <img v-else :src="user.user.avatar" referrerpolicy="no-referrer" />
              </v-avatar>
            </template>
            <span style="font-size: 12px">{{ user.user.name }}</span>
          </v-tooltip>
        </div>
      </v-toolbar>
      <!------------------------------------------ MAIN BODY ------------------------------------------>
      <v-card class="mx-auto" :max-width="!$vuetify.breakpoint.mobile ? '94vw' : '100%'" :style="{ 'margin-top': !$vuetify.breakpoint.mobile ? '-64px' : '15px', 'border-radius': !$vuetify.breakpoint.mobile ? '50px' : '0px', }">
        <v-card-text :style="{ height: !$vuetify.breakpoint.mobile ? '88vh' : '90vh', 'overflow-y': 'auto', }">
          <BookingHeader :booking="booking" :key="headerKey" />
          <v-divider></v-divider>
          <v-tabs>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab @click="tab = 0">
              <v-icon left :color="tab == 0 ? 'primary' : 'grey'">bar_chart</v-icon>Overview
            </v-tab>
            <!-- 
            <v-tab @click="tab = 1">
              <v-icon left :color="tab == 1 ? 'primary' : 'grey'">info</v-icon
              >Details
            </v-tab> -->

            <v-tab @click="tab = 2">
              <v-icon left :color="tab == 2 ? 'primary' : 'grey'">list</v-icon>Content
            </v-tab>

            <v-tab @click="tab = 3">
              <v-icon left :color="tab == 3 ? 'primary' : 'grey'">group</v-icon>Relationships
            </v-tab>

            <v-tab @click="tab = 4">
              <v-icon left :color="tab == 4 ? 'primary' : 'grey'">mode_of_travel</v-icon>Tracking
            </v-tab>
            <v-tab @click="tab = 5">
              <v-icon left :color="tab == 5 ? 'primary' : 'grey'">fact_check</v-icon>Stock Allocation
            </v-tab>
            <v-tab @click="tab = 6">
              <v-icon left :color="tab == 6 ? 'primary' : 'grey'">gps_fixed</v-icon>Advanced Tracking (Beta)
            </v-tab>
          </v-tabs>
          <v-row>
            <v-col cols="12">
              <v-expand-transition>
                <div v-if="tab == 0">
                  <BookingOverview :key="containerKey" :booking="booking" :related="related" :countries="countries" :container="booking.bookingContainers"
                    :linkedFiles="linkedFiles" :loadingContainers="loadingContainers"
                    :suggestedDealTerms="suggestedDealTerms" :ventSettings="ventSettings" @updateVents="updateVents()"
                    @updateBooking="saveBooking()" @refreshContainers="getContainers()"
                    @refreshRegimeVents="getRegimeCodeSettings()" @createFile="viewFileDialog()"
                    @cancelBooking="confirmCancel = true" @submitInttra="checkSubmitBooking()"
                    @updateInttra="checkSubmitUpdateBooking()" @bookingChange="saveBooking()" />
                </div>
              </v-expand-transition>
              <v-expand-transition>
                <div v-if="tab == 6">
                 <BookingContainerTracking :booking="booking" :containers="booking.bookingContainers.filter(x=> !x.isDeleted && (x.status == 'Allocated' || x.status == null ||  x.status == 'Short Shipped') && x.bookingId == booking.id)" />
                </div>
              </v-expand-transition>
              <v-expand-transition>
                <div v-if="tab == 3">
                  <BookingRelationship :booking="booking" :countries="countries" :containers="booking.bookingContainers.filter( (x) => !x.isDeleted && (x.status == 'Allocated' || x.status == null || x.status == 'Short Shipped') && x.bookingId == booking.id ) " :related="related" :linkedOrganisations="linkedOrganisations"
                    :loadingLinkedOrganisations="loadingLinkedOrganisations" :loadingRelatedOrganisations="loadingRelatedOrganisations" @bookingChange="saveBooking()" />
                </div>
              </v-expand-transition>
              <v-expand-transition>
                <div v-if="tab == 4">
                  <BookingTracking :booking="booking" :containers="booking.bookingContainers.filter( (x) => !x.isDeleted && (x.status == 'Allocated' || x.status == null ||  x.status == 'Short Shipped') && x.bookingId == booking.id ) " />
                </div>
              </v-expand-transition>
              <v-expand-transition>
                <div v-if="tab == 5">
                  <AssignManagedStock :orders="[booking]" :booking="booking" />
                </div>
              </v-expand-transition>
              <v-expand-transition>
                <v-card v-if="tab == 1" flat class="mb-0 pb-0">
                  <v-card-text class="mb-0 pb-0 mt-0 pt-0">
                    <v-row class="mt-2">
                      <v-col cols="12" sm="4" md="3" class="mt-0 pt-0 mx-0 px-0">
                      </v-col>
                      <v-col cols="12" sm="4" md="3" class="mt-0 pt-0">
                        <v-list dense>
                          <v-divider></v-divider>
                          <v-subheader style="font-size: 16px"><v-icon class="mr-2"
                              color="grey">mode_of_travel</v-icon>Transport Details</v-subheader>
                          <v-divider></v-divider>
                          <v-list-item @click="shippingLineModal = true">
                            <v-list-item-action v-if="!booking.shippingLine">
                              <v-icon color="secondary">directions_boat</v-icon>
                            </v-list-item-action>
                            <v-list-item-avatar v-else>
                              <v-avatar color="white" size="36" v-if="booking.shippingLine">
                                <v-img v-if="booking.shippingLine && booking.shippingLine.logo " :src="booking.shippingLine.logo" contain></v-img>
                              </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title v-if="booking.shippingLine">{{ booking.shippingLine.name }}</v-list-item-title>
                              <v-list-item-title v-else>-</v-list-item-title>
                              <v-list-item-subtitle>Shipping Line</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <div v-if="booking.sailingSchedule">
                            <v-list-item v-if="booking.sailingSchedule && booking.sailingSchedule.sailingScheduleLegs .length == 0 ">
                              <v-list-item-content>
                                <span style="color: grey; font-size: 12px">No Vessel Legs</span>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-for="leg in booking.sailingSchedule .sailingScheduleLegs" :key="leg.id">
                              <v-list-item-avatar>
                                <v-chip>{{ leg.leg }}</v-chip>
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title>
                                  <v-chip outlined style="border: none" small>
                                    <v-avatar size="20" left>
                                      <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${leg.portOfLoadValue.substring(0,2).toLowerCase()}.svg`"></v-img>
                                    </v-avatar>
                                    {{ leg.portOfLoadValue }}
                                  </v-chip>
                                  <v-icon small class="mx-1">arrow_forward</v-icon>
                                  <v-chip outlined style="border: none" small>
                                    <v-avatar size="20" left>
                                      <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${leg.portOfDischargeValue.substring(0,2).toLowerCase()}.svg`"></v-img>
                                    </v-avatar>
                                    {{ leg.portOfDischargeValue }}
                                  </v-chip>
                                </v-list-item-title>
                                <v-list-item-subtitle class="pl-2">
                                  {{ formatDate(leg.etd).isoFormat }}
                                  <v-icon small class="mx-1">arrow_forward</v-icon>
                                  {{ formatDate(leg.eta).isoFormat }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle class="pl-2" v-if="leg.vesselDescription">
                                  <v-icon small color="grey" class="mr-2">directions_boat</v-icon>
                                  {{ leg.vesselDescription }}
                                  <span v-if="leg.voyage">
                                    - {{ leg.voyage }}</span>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </div>
                        </v-list>
                      </v-col>
                      <v-col cols="12" sm="4" md="3" class="mt-0 pt-0">
                        <v-list dense>
                          <v-subheader style="font-size: 16px">
                            <v-icon class="mr-2" color="grey">insights</v-icon>
                            Miscellaneous</v-subheader>
                          <v-divider></v-divider>
                          <v-list-item @click="getRegimeCodes(true)">
                            <v-list-item-action>
                              <v-icon :color="!booking.regimeCode ? 'secondary' : 'grey' ">tag</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title v-if="booking.regimeCode">
                                {{ booking.regimeCode }}
                                <v-chip outlined small v-if="booking.regime && booking.regime.steri">
                                  <v-icon left color="blue" small>ac_unit</v-icon>Steri Code
                                </v-chip>
                              </v-list-item-title>
                              <v-list-item-title v-else>-</v-list-item-title>
                              <v-list-item-subtitle v-if="booking.regime">
                                <v-icon class="mr-2" color="grey" small>thermostat</v-icon>Set Point: {{ booking.regime.setPointTemp }}°C
                                <v-icon class="mx-2" color="red" small>thermostat</v-icon>Max: {{ booking.regime.maxProductTemp }}°C
                                <v-icon class="mx-2" color="blue" small>thermostat</v-icon>Min {{ booking.regime.minProductTemp }}°C
                              </v-list-item-subtitle>
                              <v-list-item-subtitle> </v-list-item-subtitle>
                              <v-list-item-subtitle>
                                Regime Code
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item @click="caProductModal = true">
                            <v-list-item-action>
                              <v-icon :color="!booking.caProduct ? 'secondary' : 'grey' ">thermostat_auto</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title v-if="booking.caProduct">
                                {{ booking.caProduct }}
                              </v-list-item-title>
                              <v-list-item-title v-else>-</v-list-item-title>
                              <v-list-item-subtitle>
                                Controlled Atmosphere
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-action>
                              <v-icon color="secondary">widgets</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                Containers
                              </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-chip v-if="booking.bookingContainers && onlyShowActiveContainers " small>{{ booking.bookingContainers.filter( (x) => !x.isDeleted && (x.status == "Allocated" || x.status == null || x.status == "Short Shipped") && x.bookingId == booking.id ).length }}</v-chip>
                            </v-list-item-action>
                          </v-list-item>
                          <v-list dense :key="containerKey" class="my-0 py-0">
                            <v-list-item v-for="product in productSummary" :key="product.id" style="height: 60px">
                              <v-list-item-content>
                                <v-list-item-title style="font-size: 14px">
                                  {{ $Format.capitalizeFirstLetter(product.productName) }}
                                  <span style="font-size: 12px; color: grey" v-if="product.quantity">
                                    - {{ product.quantity }} {{ product.quantityType }}
                                  </span>
                                  <span style="font-size: 12px; color: grey" v-if="product.pallets">
                                    - {{ product.pallets }} Pallets
                                  </span>
                                </v-list-item-title>
                                <v-list-item-subtitle style="font-size: 12px" v-if="product.nettWeight">
                                  NETT: {{ product.nettWeight }} KG - GROSS:
                                  {{ product.grossWeight }} KG
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-if="product.industrial || product.organic || product.hazardous " class="text-wrap">
                                  <v-chip x-small color="blue-grey" class="mx-1" v-if="product.industrial"><v-icon small
                                      left>factory</v-icon>
                                    Industrial Use</v-chip>
                                  <v-chip x-small color="green" class="mx-1" v-if="product.industrial"><v-icon small
                                      left>spa</v-icon>
                                    Organic</v-chip>
                                  <v-chip x-small color="black" class="white--text mx-1" v-if="product.hazardous">
                                    <v-icon color="orange" left>warning</v-icon>
                                    Hazardous</v-chip>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>

                          <v-list-item>
                            <v-list-item-action>
                              <v-icon :color="'secondary'">warning</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-subtitle>
                                Hazardous Content
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-switch v-model="booking.isHazardous" @change="setFlagUpdate()" color="blue"
                                readonly></v-switch>
                            </v-list-item-action>
                          </v-list-item>
                          <v-divider></v-divider>
                        </v-list>
                      </v-col>
                      <v-col cols="12" sm="4" md="3" class="mt-0 pt-0"> </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-expand-transition>
              <v-expand-transition>
                <v-card flat v-if="tab == 2">
                  <v-card-text class="pt-0">
                    <v-list dense subheader>
                      <!-- <v-divider></v-divider> -->
                      <v-toolbar flat dense style="font-size: 16px">
                        <v-icon class="mr-2" color="secondary">widgets</v-icon>
                        Containers
                        <v-row class="ml-2 pl-2">
                          <v-tooltip top v-if="chosenContainers.length > 0">
                            <template v-slot:activator="{ on }">
                              <v-btn @click="massAction('edit')" v-on="on" rounded outlined small class="mx-1" icon
                                color="amber darken-2"><v-icon small>edit</v-icon></v-btn>
                            </template>
                            <span>Edit Selection</span>
                          </v-tooltip>

                          <v-tooltip top v-if="chosenContainers.length > 0">
                            <template v-slot:activator="{ on }">
                              <v-btn @click="massAction('split_booking')" v-on="on" rounded outlined small class="mx-1"
                                color="blue" icon><v-icon small>call_split</v-icon></v-btn>
                            </template>
                            <span>Split Selection</span>
                          </v-tooltip>

                          <v-tooltip top v-if="chosenGhostContainers.length > 0">
                            <template v-slot:activator="{ on }">
                              <v-btn @click="massAction('split_booking_ghost')" v-on="on" rounded outlined small
                                class="mx-1" color="blue" icon><v-icon small>call_split</v-icon></v-btn>
                            </template>
                            <span>Split Ghost Containers</span>
                          </v-tooltip>

                          <v-tooltip top v-if="chosenContainers.length > 0">
                            <template v-slot:activator="{ on }">
                              <v-btn @click="massAction('mark_ghost')" v-on="on" rounded outlined small icon
                                class="mx-1" color="blue-grey"><v-icon small>category</v-icon></v-btn>
                            </template>
                            <span>Set as Ghost</span>
                          </v-tooltip>

                          <v-tooltip top v-if="chosenContainers.length > 0">
                            <template v-slot:activator="{ on }">
                              <v-btn @click="massAction('link_to_file')" v-on="on" rounded outlined small icon
                                class="mx-1" color="teal"><v-icon small>link</v-icon></v-btn>
                            </template>
                            <span>Link to File</span>
                          </v-tooltip>

                          <v-tooltip top v-if="chosenContainers.length > 0">
                            <template v-slot:activator="{ on }">
                              <v-btn @click="massAction('remove')" v-on="on" rounded outlined icon small class="mx-1"
                                color="red"><v-icon small>cancel</v-icon></v-btn>
                            </template>
                            <span>Remove</span>
                          </v-tooltip>

                          <!-- TEMPORARY IMPORT OF PROPHET CODE FOR TESTING -->

                          <v-tooltip top v-if="chosenContainers.length > 0 && (booking.shipperId === 3892 || booking.customerId === 3892 )">
                            <template v-slot:activator="{ on }">
                              <v-btn @click="massAction('dispatch')" v-on="on" rounded outlined icon small class="mx-1"
                                color="orange lighten-1" :loading="creatingDispatch"
                                :disabled="disalbedDispatch"><v-icon small>send_time_extension</v-icon></v-btn>
                            </template>
                            <span>Create QX Dispatch</span>
                          </v-tooltip>

                          <v-tooltip top v-if="chosenContainers.length > 0 && (booking.shipperId === 3892 || booking.customerId === 3892 )">
                            <template v-slot:activator="{ on }">
                              <v-btn @click="massAction('xml')" v-on="on" rounded outlined icon small class="mx-1"
                                color="pink" :loading="createXMLLoading"><v-icon small>article</v-icon></v-btn>
                            </template>
                            <span>Create XML</span>
                          </v-tooltip>


                          <v-tooltip top v-if="chosenContainers.length > 0 && (booking.shipperId === 3892 || booking.customerId === 3892 )">
                            <template v-slot:activator="{ on }">
                              <v-btn @click="massAction('loadOut')" v-on="on" rounded outlined icon small class="mx-1"
                                color="purple" :loading="gettingLoadoutData"><v-icon small>email</v-icon></v-btn>
                            </template>
                            <span>Create Loadout Mail</span>
                          </v-tooltip>

                          <!-- TEMPORARY IMPORT OF PROPHET CODE FOR TESTING -->
                        </v-row>
                        <v-spacer></v-spacer>
                        <v-switch label="Only Show Active" hide-details dense v-model="onlyShowActiveContainers"
                          class="mr-2" color="success"></v-switch>
                        <!------------------------------------------ BUTTONS START ------------------------------------------>
                        <v-chip style="height: 40px" class="pr-0">
                          <v-chip v-if="booking.bookingContainers && onlyShowActiveContainers " small>TOTAL: {{ booking.bookingContainers.filter( (x) => !x.isDeleted && (x.status == "Allocated" || x.status == null  || x.status == "Short Shipped") && x.bookingId == booking.id ).length }}</v-chip>
                          <v-chip v-else-if="booking.bookingContainers" small>TOTAL:
                            {{ booking.bookingContainers.length }}</v-chip>
                          <v-btn-toggle mandatory outlined v-model="toggleContainerView" rounded color="blue">
                            <v-btn small outlined>
                              <v-icon left small :color="toggleContainerView == 0 ? 'blue' : 'white' ">confirmation_number</v-icon>
                              <span style="text-transform: none; color: white">Table</span>
                            </v-btn>
                            <v-btn small outlined>
                              <v-icon small left :color="toggleContainerView == 1 ? 'blue' : 'white' ">widgets</v-icon>
                              <span style="text-transform: none; color: white">Cards</span>
                            </v-btn>
                          </v-btn-toggle>
                          <v-tooltip top open-delay="500">
                            <template v-slot:activator="{ on }">
                              <div v-on="on">
                                <v-menu offset-y nudge-left :close-on-content-click="false"
                                  style="height: 20vh; margin-right: 8vw">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" icon><v-icon>view_week</v-icon></v-btn>
                                  </template>
                                  <v-card color="greyRaised" style="overflow: hidden">
                                    <v-list style=" overflow: hidden; column-count: 3; max-width: 26vw; height: fit-content; ">
                                      <v-list-item v-for="( item, index ) in containerHeaders.filter( (x) => x.value != 'actions' )" :key="index" style=" display: inline-block; width: max-content; ">
                                        <v-list-item-action>
                                          <v-checkbox v-model="item.show" @change="toggleColumn(item)"
                                            :label="item.text" hide-details></v-checkbox>
                                        </v-list-item-action>
                                      </v-list-item>
                                    </v-list>
                                    <v-row class="pt-5 pb-4" style="overflow-y: hidden">
                                      <v-col cols="12" md="12" sm="12" lg="12" class="d-flex justify-center">
                                        <v-btn @click="saveCustom" :loading="loadingSaveCustom" outlined color="primary">SAVE</v-btn>
                                      </v-col>
                                    </v-row>
                                  </v-card>
                                </v-menu>
                              </div>
                            </template>
                            Customize Layout
                          </v-tooltip>
                          <v-tooltip top open-delay="500">
                            <template v-slot:activator="{ on }">
                              <v-btn v-on="on" icon @click="addContainerDetails('Create')" color="primary"><v-icon>add_circle_outline</v-icon></v-btn>
                            </template>
                            Add Container
                          </v-tooltip>
                          <v-text-field dense outlined hide-details clearable autofocus placeholder="Search Containers"
                            persistent-placeholder prepend-inner-icon="search" v-model="tableSearch" color="white"
                            style="max-width: 15vw; border-radius: 20px">
                          </v-text-field>
                        </v-chip>
                        <!------------------------------------------ BUTTONS END ------------------------------------------>
                      </v-toolbar>
                      <!------------------------------------------ CONTAINERS TABLE ------------------------------------------>
                      <v-card-text class="my-0 py-0 mx-0">
                        <div v-if="toggleContainerView == 0">
                          <v-data-table :items="onlyShowActiveContainers ? activeContainers : allContainers " :key="containerTableKey" :item-class="itemRowBackground" 
                          :headers="booking.movementType == 'EXPORT' ? containerHeaders : importContainerHeaders " :search="tableSearch" dense hide-default-footer disable-pagination :show-select="true"
                          :loading="loadingContainers" height="70vh" @contextmenu:row="viewContainerMenu" style="cursor: pointer">
                            <template v-slot:[`header.data-table-select`]>
                              <v-checkbox :value="selectAll" dense hide-details color="primary"
                                @click="selectAllContainers"></v-checkbox>
                            </template>
                            <template v-slot:[`item.data-table-select`]="{ item }">
                              <v-checkbox :value="chosenContainers .map((x) => x.id) .includes(item.id) " dense hide-details @click=" (selectAll = false), selectOneContainer(item) " color="primary" 
                              v-if="item.bookingId == booking.id && item.status != 'DELETED' && item.status != 'Ghost' && item.status != 'COMPLETED' && item.status != 'CANCELLED' && !item.duplicated && !item.isDeleted "></v-checkbox>
                              <v-checkbox :value="chosenGhostContainers .map((x) => x.id) .includes(item.id) " dense hide-details
                              @click="selectGhostContainer(item)" color="blue-grey" v-if="item.bookingId == booking.id && item.status == 'Ghost' && !item.duplicated && !item.isDeleted"
                              ></v-checkbox>
                            </template>
                            <template v-slot:[`item.shipmentFile.fileNumber`]="{ item }">
                              <div
                                v-if="item.shipmentFile && (!item.shipmentId && (item.shipmentFile.status !== 'Ready' || item.shipmentFile.status !== 'Processed'))"
                                @click="editFile(item)" style="cursor: pointer" disabled="">
                                {{ item.shipmentFile.fileNumber }}
                              </div>
                              <div
                                v-else-if="item.shipmentFile && (item.shipmentId && (item.shipmentFile.status === 'Ready' || item.shipmentFile.status === 'Processed'))"
                                style="cursor: pointer">
                                {{ item.shipmentFile.fileNumber }}
                              </div>
                              <div v-else-if="!item.isDeleted && item.status != 'Ghost'
    ">
                                <v-btn icon @click="editFile(item)" color="grey"><v-icon
                                    small>add_circle_outline</v-icon></v-btn>
                              </div>
                            </template>

                            <template v-slot:[`item.packingListDocumentId`]="{ item }">
                              <div>
                                <v-btn :color="item.packingListDocumentId ? 'success' : 'red'" small left plain>
                                  <v-icon small left>{{ item.packingListDocumentId ? 'check_circle' : 'highlight_off' }}</v-icon>
                                </v-btn>
                              </div>
                            </template>
                            <template v-slot:[`item.dispatchStatus`]="{ item }">
                              <v-chip small outlined
                                :color="item.dispatchStatus === 'success' ? 'success' : item.dispatchStatus === 'error' ? 'danger' : '#F57C00'">

                                {{ $Format.capitalizeFirstLetter(item.dispatchStatus) }}
                              </v-chip>
                            </template>
                            <template v-slot:[`item.loadoutStatus`]="{ item }">
                              <v-btn :color="item.loadoutStatus ? 'success' : 'red'" small left plain>
                                <v-icon small left>{{ item.loadoutStatus ? 'check_circle' : 'highlight_off' }}</v-icon>
                              </v-btn>
                            </template>
                            <template v-slot:[`item.exportNotificationStatus`]="{ item, }">
                              <v-chip v-if="item.exportNotificationStatus" small :color="item.exportNotificationStatus == 'PENDING' ? 'orange' : item.exportNotificationStatus == 'INPROGRESS' ? 'blue' : item.exportNotificationStatus == 'CONFIRMED' ? 'succes' : 'grey' ">
                                {{ item.exportNotificationStatus }}
                              </v-chip>
                            </template>
                            <template v-slot:[`item.allocatedPallets`]="{ item }">
                              <v-tooltip top v-if="item.allocatedPallets">
                                <template v-slot:activator="{ on }">
                                  <v-chip small v-on="on" :color="orderItemStatusColor(item.stockStatus) " style="cursor: pointer" @click="viewStockPallets(item)">
                                    <v-icon small left color="white">{{ orderItemStatusIcon(item.stockStatus) }}</v-icon>
                                    {{ item.allocatedPallets }}
                                  </v-chip>
                                </template>
                                {{ item.stockStatus }}

                              </v-tooltip>
                            </template>
                            <template v-slot:[`item.buyer.name`]="{ item }">
                              <div v-if="item.buyer" @click="editParty('Buyer', item)" style="cursor: pointer">
                                <span v-if="item.buyer.alias">{{ item.buyer.alias }}</span>
                                <span v-else>{{ item.buyer.name }}</span>
                              </div>
                              <div v-else>
                                <v-btn icon @click="editParty('Buyer', item)" color="grey"><v-icon
                                    small>add_circle_outline</v-icon></v-btn>
                              </div>
                            </template>
                            <template v-slot:[`item.consignee.name`]="{ item }">
                              <div v-if="item.consignee" @click="editParty('Consignee', item)" style="cursor: pointer">
                                <span v-if="item.consignee.alias">{{ item.consignee.alias }}</span>
                                <span v-else>{{ item.consignee.name }}</span>
                              </div>
                              <div v-else>
                                <v-btn icon @click="editParty('Consignee', item)" color="grey"><v-icon
                                    small>add_circle_outline</v-icon></v-btn>
                              </div>
                            </template>

                            <template v-slot:[`item.transporter.name`]="{ item }">
                              <v-chip small outlined style="border: none" v-if="item.transporter && item.transporter.name">
                                <v-avatar :color="item.transporter.logo ? 'white' : 'secondary' " size="32" left>
                                  <v-img v-if="item.transporter.logo" :src="item.transporter.logo" contain />
                                  <h3 v-else>
                                    {{ item.transporter.name.charAt(0) }}
                                  </h3>
                                </v-avatar>
                                <span v-if="item.transporter.alias">
                                  {{ item.transporter.alias }}
                                </span>
                                <span v-else>
                                  {{ item.transporter.name }}
                                </span>
                              </v-chip>
                            </template>

                            <template v-slot:[`item.transportCoordinator.name`]="{ item, }">
                              <div v-if="item.isPrivateHaulage || item.isRailage">
                                <v-tooltip top v-if="item.transportCoordinator">
                                  <template v-slot:activator="{ on }">
                                    <v-chip small outlined style="border: none" v-on="on"
                                      @click="editTransportCoordinator(item)">
                                      <v-avatar :color="item.transportCoordinator.logo ? 'white' : 'secondary' " size="32" left>
                                        <v-img v-if="item.transportCoordinator.logo"
                                          :src="item.transportCoordinator.logo" contain />
                                        <h3 v-else> {{ item.transportCoordinator.name.charAt( 0 ) }} </h3>
                                      </v-avatar>
                                      <span v-if="item.transportCoordinator.alias">
                                        {{ item.transportCoordinator.alias }}
                                      </span>
                                      <span v-else>
                                        {{ item.transportCoordinator.name }}
                                      </span>
                                      <v-icon v-if="item.preAdviseRequired" right color="blue">new_releases</v-icon>
                                    </v-chip>
                                  </template>
                                  <span v-if="item.preAdviseRequired">Preadvise: Required</span>
                                  <span v-else>
                                    <span v-if="item.transportCoordinator.alias">
                                      {{ item.transportCoordinator.alias }}
                                    </span>
                                    <span v-else>
                                      {{ item.transportCoordinator.name }}
                                    </span></span>
                                </v-tooltip>
                                <v-btn v-else icon @click="editTransportCoordinator(item)" color="grey"><v-icon
                                    small>add_circle_outline</v-icon></v-btn>
                              </div>
                              <div v-else-if="item.isCarrierHaulage">
                                <v-chip outlined dense @click="editTransportCoordinator(item)">
                                  <v-icon left small>directions_boat</v-icon>
                                  Carrier Haulage
                                </v-chip>
                              </div>
                              <v-btn icon v-else @click="editTransportCoordinator(item)" color="grey"><v-icon
                                  small>add_circle_outline</v-icon></v-btn>
                            </template>

                            <template v-slot:[`item.containerNo`]="{ item }">
                              <div v-if="item.containerNo">
                                {{ item.containerNo }}
                                <v-btn icon color="primary" @click=" (selectedContainer = item), (containerNo = item.containerNo), (containerNoDialog = true) "><v-icon small>edit</v-icon></v-btn>
                              </div>
                              <div v-else-if="!item.isDeleted && item.status != 'Ghost' && item.bookingId == booking.id ">
                              <div v-if="booking.serviceType == 'BREAKBULK'">
                             
                              </div>
                              <div v-else>
                                <v-btn icon @click="addContainerNo(item)" color="grey"><v-icon
                                  small>add_circle_outline</v-icon></v-btn>
                              </div>
                              </div>
                            </template>

                            <template v-slot:[`item.sealNo`]="{ item }">
                              <div v-if="item.sealNo">
                                {{ item.sealNo }}
                                <v-btn icon color="primary" @click=" (selectedContainer = item), (sealNo = item.sealNo), (sealNoDialog = true) "><v-icon small>edit</v-icon></v-btn>
                              </div>
                              <div v-else-if="!item.isDeleted && item.status != 'Ghost' && item.bookingId == booking.id ">
                                <v-btn icon @click="addSealNo(item)" color="grey"><v-icon
                                    small>add_circle_outline</v-icon></v-btn>
                              </div>
                            </template>
                            <template v-slot:[`item.nettWeight`]="{ item }">
                              <v-btn v-if="item.nettWeight" small style="text-transform: none" text @click=" (selectedContainer = item), (weightDialog = true) ">
                                {{ item.nettWeight }} KG
                              </v-btn>
                              <div v-else-if="!item.isDeleted && item.status != 'Ghost' && item.bookingId == booking.id ">
                                <v-btn icon @click=" (selectedContainer = item), (weightDialog = true) " color="grey"><v-icon small>add_circle_outline</v-icon></v-btn>
                              </div>
                            </template>
                            <template v-slot:[`item.grossWeight`]="{ item }">
                              <v-btn v-if="item.grossWeight" small style="text-transform: none" text @click=" (selectedContainer = item), (weightDialog = true) ">
                                <v-avatar class="mr-1" left size="24" v-if="item.loadOutReceived"
                                  color="orange darken-3">
                                  <h4>LI</h4>
                                </v-avatar>
                                {{ item.grossWeight }} KG
                              </v-btn>
                              <div v-else-if="!item.isDeleted && item.status != 'Ghost' && item.bookingId == booking.id ">
                                <v-btn icon @click=" (selectedContainer = item), (weightDialog = true) " color="grey"><v-icon small>add_circle_outline</v-icon></v-btn>
                              </div>
                            </template>

                            <template v-slot:[`item.customerContainerRef`]="{ item }">
                              <v-btn v-if="item.customerContainerRef" small style="text-transform: none" text @click=" (selectedContainer = item), (customerRef = item.customerContainerRef), (customerRefDialog = true) ">
                                {{ item.customerContainerRef }}
                              </v-btn>
                              <div v-else-if="!item.isDeleted && item.status != 'Ghost' && item.bookingId == booking.id ">
                                <v-btn icon @click="addCustomerRef(item)" color="grey"><v-icon
                                    small>add_circle_outline</v-icon></v-btn>
                              </div>
                            </template>
                            <!-- <template v-slot:[`item.vents`]="{ item }">
                              <v-btn v-if="item.vents" small style="text-transform: none" text @click="
                                (selectedContainer = item),
                                (ventDialog = true)
                                ">
                                {{ item.vents }}
                                <span v-if="item.vents != 'Closed' &&
                                  item.vents != ' CA' &&
                                  item.vents != ' MA'
                                  ">
                                  CBM</span>
                              </v-btn>
                              <div v-else-if="!item.isDeleted &&
                                item.status != 'Ghost' &&
                                item.bookingId == booking.id
                                ">
                                <v-btn icon @click="
                                  (selectedContainer = item),
                                  (ventDialog = true)
                                  " color="grey"><v-icon small>add_circle_outline</v-icon></v-btn>
                              </div>
                            </template> -->
                            <!-- <template v-slot:[`item.containerComments`]="{ item }">
                              <v-btn v-if="item.containerComments" small style="text-transform: none" text @click="
                                (selectedContainer = item),
                                (commentDialog = true)
                                ">
                                {{ item.containerComments }}
                              </v-btn>
                              <div v-else-if="!item.isDeleted &&
                                item.status != 'Ghost' &&
                                item.bookingId == booking.id
                                ">
                                <v-btn icon @click="
                                  (selectedContainer = item),
                                  (commentDialog = true)
                                  " color="grey"><v-icon small>add_circle_outline</v-icon></v-btn>
                              </div>
                            </template> -->
                            <template v-slot:[`item.verificationMethod`]="{ item }">
                              <v-btn v-if="item.verificationMethod" small style="text-transform: none" text
                                @click="editVerificationMethod(item)">
                                {{ item.verificationMethod }}
                              </v-btn>
                              <div v-else-if="!item.isDeleted &&
    item.status != 'Ghost' &&
    item.bookingId == booking.id
    ">
                                <v-btn icon @click="addVerificationMethod(item)" color="grey"><v-icon
                                    small>add_circle_outline</v-icon></v-btn>
                              </div>
                            </template>

                            <template v-slot:[`item.features`]="{ item }">
                              <v-tooltip top v-if="item.temporaryProducts.some(
    (product) => product.product === 'Genset'
  )
    ">
                                <template v-slot:activator="{ on }">
                                  <v-btn icon outlined small class="mx-1" v-on="on" color="#D6D46D"><v-icon
                                      small>bolt</v-icon></v-btn>
                                </template>
                                <span>Genset Required</span>
                              </v-tooltip>

                              <!-- <v-tooltip
                                top
                                v-if="
                                  item.temporaryProducts.some(
                                    (product) =>
                                      product.product === 'Port Monitoring'
                                  )
                                "
                              >
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    icon
                                    outlined
                                    small
                                    class="mx-1"
                                    v-on="on"
                                    color="#D6CC99"
                                    ><v-icon small>monitor_heart</v-icon></v-btn
                                  >
                                </template>
                                <span>Port Monitoring Required</span>
                              </v-tooltip> -->

                              <v-tooltip top v-if="item.temporaryProducts.some(
    (product) =>
      product.product === 'Fumigation'
  )
    ">
                                <template v-slot:activator="{ on }">
                                  <v-btn icon outlined small class="mx-1" v-on="on" color="#F1DBBF"><v-icon
                                      small>cleaning_services</v-icon></v-btn>
                                </template>
                                <span>Fumigations Required</span>
                              </v-tooltip>

                              <v-tooltip top v-if="item.temporaryProducts.some(
    (product) =>
      product.product === 'Void Plugs'
  )
    ">
                                <template v-slot:activator="{ on }">
                                  <v-btn icon outlined small class="mx-1" v-on="on" color="#e05b0d"><v-icon
                                      small>power</v-icon></v-btn>
                                </template>
                                <span>Void Plugs</span>
                              </v-tooltip>

                              <v-tooltip top v-if="item.temporaryProducts.some(
    (product) =>
      product.product === 'Blast Freezing'
  )
    ">
                                <template v-slot:activator="{ on }">
                                  <v-btn icon outlined small class="mx-1" v-on="on" color="#13a7d4"><v-icon
                                      small>ac_unit</v-icon></v-btn>
                                </template>
                                <span>Blast Freezing</span>
                              </v-tooltip>

                              <v-tooltip top v-if="item.temporaryProducts.some(
    (product) =>
      product.product === 'Monitoring Service'
  )
    ">
                                <template v-slot:activator="{ on }">
                                  <v-btn icon outlined small class="mx-1" v-on="on" color="#99B080"><v-icon
                                      small>desktop_windows</v-icon></v-btn>
                                </template>
                                <span>
                                  {{
    item.temporaryProducts.find(
      (product) =>
        product.product === "Monitoring Service"
    ).variation +
    " " +
    "Service" +
    " " +
    "Required"
  }}
                                </span>
                              </v-tooltip>

                              <v-tooltip top v-if="item.temporaryProducts.some(
      (product) =>
        product.product === 'ContainerLiners'
    )
    ">
                                <template v-slot:activator="{ on }">
                                  <v-btn icon outlined small class="mx-1" v-on="on" color="#EEF296"><v-icon
                                      small>settings_overscan</v-icon></v-btn>
                                </template>
                                <span>
                                  {{ "Container Liners" + " " + "Required" }}
                                </span>
                              </v-tooltip>

                              <v-tooltip top v-if="item.temporaryProducts.some(
    (product) =>
      product.product === 'SteriInsurance'
  )
    ">
                                <template v-slot:activator="{ on }">
                                  <v-btn icon outlined small class="mx-1" v-on="on" color="#EEF296"><v-icon
                                      small>emergency</v-icon></v-btn>
                                </template>
                                <span>
                                  {{ "Steri Insurance" + " " + "Required" }}
                                </span>
                              </v-tooltip>
                              <v-tooltip top v-if="item.temporaryProducts.some(
    (product) =>
      product.product === 'SmartFresh'
  )
    ">
                                <template v-slot:activator="{ on }">
                                  <v-btn icon outlined small class="mx-1" v-on="on" color="#FFBFFF"><v-icon
                                      small>smart_screen</v-icon></v-btn>
                                </template>
                                <span>
                                  {{ "Smart Fresh" + " " + "Required" }}
                                </span>
                              </v-tooltip>
                              <v-tooltip top v-if="item.temporaryProducts.some(
    (product) => product.product === 'TelemPlus'
  )
    ">
                                <template v-slot:activator="{ on }">
                                  <v-btn icon outlined small class="mx-1" v-on="on" color="#18e00d"><v-icon
                                      small>router</v-icon></v-btn>
                                </template>
                                <span>
                                  Devices Required:
                                  <template v-if="item.temporaryProducts.some(
    (product) =>
      product.product === 'TelemPlus'
  )
    ">
                                    <ul>
                                      <li v-for="(
                                          [variation, count], index
                                        ) in Object.entries(
    item.temporaryProducts
      .filter(
        (product) =>
          product.product === 'TelemPlus'
      )
      .reduce((acc, product) => {
        if (!acc[product.variation]) {
          acc[product.variation] = 0;
        }
        acc[product.variation] +=
          product.count;
        return acc;
      }, {})
  )" :key="index">
                                        {{ count }}X{{ variation }}
                                      </li>
                                    </ul>
                                  </template>
                                </span>
                              </v-tooltip>

                              <v-tooltip top v-if="item.temporaryProducts.some(
    (product) => product.product === 'Otflow'
  )
    ">
                                <template v-slot:activator="{ on }">
                                  <v-btn icon outlined small class="mx-1" v-on="on" color="#6A9C89"><v-icon
                                      small>air</v-icon></v-btn>
                                </template>
                                <span>Otflow Required</span>
                              </v-tooltip>


                              <v-tooltip top v-if="item.temporaryProducts.some( (product) => product.product === 'Zoono' ) ">
                                <template v-slot:activator="{ on }">
                                  <v-btn icon outlined small class="mx-1" v-on="on" color="#5b93c7">
                                    <v-icon small>sanitizer</v-icon>
                                  </v-btn>
                                </template>
                                <span>Zoono Required</span>
                              </v-tooltip>

                              <v-tooltip top v-if="item.temporaryProducts.some(
    (product) =>
      product.product === 'Dunnage Bags'
  )
    ">
                                <template v-slot:activator="{ on }">
                                  <v-btn icon outlined small class="mx-1" v-on="on" color="#F1DBBF"><v-icon
                                      small>shopping_bag</v-icon></v-btn>
                                </template>
                                <span>Dunnage Bags Required</span>
                              </v-tooltip>

                              <v-tooltip top v-if="item.temporaryProducts.some(
    (product) =>
      product.product === 'Late Arrival'
  )
    ">
                                <template v-slot:activator="{ on }">
                                  <v-btn icon outlined small class="mx-1" v-on="on" color="#BB2525"><v-icon
                                      small>watch_later</v-icon></v-btn>
                                </template>
                                <span>Late Arrival Required :
                                  {{
    item.temporaryProducts.find(
      (product) =>
        product.product === "Late Arrival"
    ).variation
  }}</span>
                              </v-tooltip>


                              <v-tooltip top v-if="item.temporaryProducts.some(
    (product) =>
      product.product === 'Early Arrival'
  )
    ">
                                <template v-slot:activator="{ on }">
                                  <v-btn icon outlined small class="mx-1" v-on="on" color="orange"><v-icon
                                      small>watch_later</v-icon></v-btn>
                                </template>
                                <span>Early Arrival Required :
                                  {{
    item.temporaryProducts.find(
      (product) =>
        product.product === "Early Arrival"
    ).variation
  }}</span>
                              </v-tooltip>

                              <!--            -->
                              <v-tooltip top v-if="item.temporaryProducts.some(
      (product) => product.product === 'Latches'
    )
    ">
                                <template v-slot:activator="{ on }">
                                  <v-btn icon outlined small class="mx-1" v-on="on" color="#508D69"><v-icon
                                      small>pivot_table_chart</v-icon></v-btn>
                                </template>
                                <span>Latches Required</span>
                              </v-tooltip>

                              <v-tooltip top v-if="item.temporaryProducts.some(
    (product) => product.product === 'Ratches'
  )
    ">
                                <template v-slot:activator="{ on }">
                                  <v-btn icon outlined small class="mx-1" v-on="on" color="#EA906C"><v-icon
                                      small>settings_input_component</v-icon></v-btn>
                                </template>
                                <span>Ratches Required</span>
                              </v-tooltip>
                              <v-tooltip top v-if="item.temporaryProducts.some(
    (product) => product.product === 'Ethylene Filters'
  )
    ">
                                <template v-slot:activator="{ on }">
                                  <v-btn icon outlined small class="mx-1" v-on="on" color="#EE12CA"><v-icon
                                      small>filter_alt</v-icon></v-btn>
                                </template>
                                <span>Ethylene Filters Required</span>
                              </v-tooltip>
                              <v-tooltip top v-if="item.temporaryProducts.some(
    (product) =>
      product.product === 'Stabling bars'
  )
    ">
                                <template v-slot:activator="{ on }">
                                  <v-btn icon outlined small class="mx-1" v-on="on" color="#5F6F52"><v-icon
                                      small>video_stable</v-icon></v-btn>
                                </template>
                                <span>Stabling bars Required</span>
                              </v-tooltip>

                              <v-tooltip top v-if="item.temporaryProducts.some(
    (product) =>
      product.product === 'Kraft paper'
  )
    ">
                                <template v-slot:activator="{ on }">
                                  <v-btn icon outlined small class="mx-1" v-on="on" color="#C5E898"><v-icon
                                      small>note</v-icon></v-btn>
                                </template>
                                <span>Kraft paper Required</span>
                              </v-tooltip>

                              <v-tooltip top v-if="item.temporaryProducts.some(
    (product) =>
      product.product === 'Absorb Bags'
  )
    ">
                                <template v-slot:activator="{ on }">
                                  <v-btn icon outlined small class="mx-1" v-on="on" color="#DCBFFF"><v-icon
                                      small>backpack</v-icon></v-btn>
                                </template>
                                <span>Absorb Bags Required</span>
                              </v-tooltip>
                            </template>
                            <template v-slot:[`item.regimeCode`]="{ item }">
                              <div v-if="item">{{ booking.regimeCode }}</div>
                            </template>
                            <template v-slot:[`item.products`]="{ item }">
                              <!-- <v-row align="center"> -->
                              <v-tooltip top v-for="product in item.containerProducts" :key="product.id">
                                <template v-slot:activator="{ on }">
                                  <v-chip small outlined style="border: none; font-size: 11px"
                                    @click="editContainerProduct(item, product)" v-on="on">
                                    <v-icon left color="grey" x-small>fiber_manual_record</v-icon>
                                    {{
    $Format.capitalizeFirstLetter(
      product.product.name
    )
  }}
                                    <v-icon v-if="product.industrial" color="blue-grey" small right>factory</v-icon>
                                    <v-icon v-if="product.organic" color="green" small right>spa</v-icon>
                                    <v-icon v-if="product.hazardous" color="orange" small right>warning</v-icon>
                                  </v-chip>
                                </template>
                                {{
    $Format.capitalizeFirstLetter(product.product.name)
  }}
                                <span v-if="product.grossWeight">
                                  - Gross: {{ product.grossWeight }}Kg</span>
                                <span v-if="product.nettWeight">
                                  - Nett: {{ product.nettWeight }}Kg</span>
                                <span v-if="product.quantity">
                                  - {{ product.quantityType }}:
                                  {{ product.quantity }}</span>
                                <span v-if="product.pallets">
                                  - {{ product.pallets }} Pallets
                                </span>
                                <span v-if="product.batchNumber">
                                  - Batch: {{ product.batchNumber }}
                                </span>
                              </v-tooltip>
                              <v-btn icon color="grey" small @click="addContainerProduct(item)"><v-icon
                                  small>add_circle_outline</v-icon></v-btn>
                              <!-- </v-row> -->
                            </template>
                            <template v-slot:[`item.emptyPickup`]="{ item }">
                              <v-chip outlined small style="border: none" v-if="item.EMPTY_PICKUP"
                                @click="addMilestone(item, 'EMPTY_PICKUP')">
                                {{ item.EMPTY_PICKUP.description }}
                              </v-chip>
                              <v-btn v-else-if="!item.isDeleted &&
    item.status != 'Ghost' &&
    item.bookingId == booking.id
    " color="grey" icon @click="addMilestone(item, 'EMPTY_PICKUP')"><v-icon small>add_circle_outline</v-icon></v-btn>
                            </template>
                            <template v-slot:[`item.emptyPickupTime`]="{ item }">
                              <v-chip outlined small style="border: none" v-if="item.EMPTY_PICKUP &&
    (item.EMPTY_PICKUP.date ||
      item.EMPTY_PICKUP.time)
    " @click="addMilestone(item, 'EMPTY_PICKUP')">
                                {{
    formatDate(item.EMPTY_PICKUP.date).isoFormat
  }}
                                {{ item.EMPTY_PICKUP.time }}
                              </v-chip>
                              <v-btn v-else-if="!item.isDeleted &&
    item.status != 'Ghost' &&
    item.bookingId == booking.id
    " color="grey" icon @click="addMilestone(item, 'EMPTY_PICKUP')"><v-icon small>add_circle_outline</v-icon></v-btn>
                            </template>
                            <template v-slot:[`item.firstLoadingPoint`]="{ item }">
                              <v-chip outlined small style="border: none" v-if="item.LOADING_POINT_1"
                                @click="addMilestone(item, 'LOADING_POINT_1')">
                                {{ item.LOADING_POINT_1.description }}
                              </v-chip>
                              <v-btn v-else-if="!item.isDeleted &&
    item.status != 'Ghost' &&
    item.bookingId == booking.id
    " color="grey" icon @click="addMilestone(item, 'LOADING_POINT_1')"><v-icon
                                  small>add_circle_outline</v-icon></v-btn>
                            </template>
                            <template v-slot:[`item.firstLoadingPointTime`]="{ item }">
                              <v-chip outlined small style="border: none" v-if="item.LOADING_POINT_1 &&
    (item.LOADING_POINT_1.date ||
      item.LOADING_POINT_1.time)
    " @click="addMilestone(item, 'LOADING_POINT_1')">
                                {{
    formatDate(item.LOADING_POINT_1.date)
      .isoFormat
  }}
                                {{ item.LOADING_POINT_1.time }}
                              </v-chip>
                              <v-btn v-else-if="!item.isDeleted &&
    item.status != 'Ghost' &&
    item.bookingId == booking.id
    " color="grey" icon @click="addMilestone(item, 'LOADING_POINT_1')"><v-icon
                                  small>add_circle_outline</v-icon></v-btn>
                            </template>
                            <template v-slot:[`item.secondLoadingPoint`]="{ item }">
                              <v-chip outlined small style="border: none" v-if="item.LOADING_POINT_2"
                                @click="addMilestone(item, 'LOADING_POINT_2')">
                                {{ item.LOADING_POINT_2.description }}
                              </v-chip>
                              <v-btn v-else-if="!item.isDeleted &&
    item.status != 'Ghost' &&
    item.bookingId == booking.id
    " color="grey" icon @click="addMilestone(item, 'LOADING_POINT_2')"><v-icon
                                  small>add_circle_outline</v-icon></v-btn>
                            </template>
                            <template v-slot:[`item.secondLoadingPointTime`]="{ item }">
                              <v-chip outlined small style="border: none" v-if="item.LOADING_POINT_2 &&
    (item.LOADING_POINT_2.date ||
      item.LOADING_POINT_2.time)
    " @click="addMilestone(item, 'LOADING_POINT_2')">
                                {{
    formatDate(item.LOADING_POINT_2.date)
      .isoFormat
  }}
                                {{ item.LOADING_POINT_2.time }}
                              </v-chip>
                              <v-btn v-else-if="!item.isDeleted &&
    item.status != 'Ghost' &&
    item.bookingId == booking.id
    " color="grey" icon @click="addMilestone(item, 'LOADING_POINT_2')"><v-icon
                                  small>add_circle_outline</v-icon></v-btn>
                            </template>
                            <template v-slot:[`item.dropOffPoint`]="{ item }">
                              <v-chip outlined small style="border: none" v-if="item.FULL_DROP_OFF"
                                @click="addMilestone(item, 'FULL_DROP_OFF')">
                                {{ item.FULL_DROP_OFF.description }}
                              </v-chip>
                              <v-btn v-else-if="!item.isDeleted &&
    item.status != 'Ghost' &&
    item.bookingId == booking.id
    " color="grey" icon @click="addMilestone(item, 'FULL_DROP_OFF')"><v-icon small>add_circle_outline</v-icon></v-btn>
                            </template>
                            <template v-slot:[`item.dropOffPointTime`]="{ item }">
                              <v-chip outlined small style="border: none" v-if="item.FULL_DROP_OFF &&
    (item.FULL_DROP_OFF.date ||
      item.FULL_DROP_OFF.time)
    " @click="addMilestone(item, 'FULL_DROP_OFF')">
                                {{
    formatDate(item.FULL_DROP_OFF.date).isoFormat
  }}
                                {{ item.FULL_DROP_OFF.time }}
                              </v-chip>
                              <v-btn v-else-if="!item.isDeleted &&
    item.status != 'Ghost' &&
    item.bookingId == booking.id
    " color="grey" icon @click="addMilestone(item, 'FULL_DROP_OFF')"><v-icon small>add_circle_outline</v-icon></v-btn>
                            </template>
                            <template v-slot:[`item.weighBridge`]="{ item }">
                              <v-chip outlined small style="border: none" v-if="item.WEIGH_BRIDGE"
                                @click="addMilestone(item, 'WEIGH_BRIDGE')">
                                {{ item.WEIGH_BRIDGE.description }}
                              </v-chip>
                              <v-btn v-else-if="!item.isDeleted &&
    item.status != 'Ghost' &&
    item.bookingId == booking.id
    " color="grey" icon @click="addMilestone(item, 'WEIGH_BRIDGE')"><v-icon small>add_circle_outline</v-icon></v-btn>
                            </template>
                            <template v-slot:[`item.weighBridgeTime`]="{ item }">
                              <v-chip outlined small style="border: none" v-if="item.WEIGH_BRIDGE &&
    (item.WEIGH_BRIDGE.date ||
      item.WEIGH_BRIDGE.time)
    " @click="addMilestone(item, 'WEIGH_BRIDGE')">
                                {{
    formatDate(item.WEIGH_BRIDGE.date).isoFormat
  }}
                                {{ item.WEIGH_BRIDGE.time }}
                              </v-chip>
                              <v-btn v-else-if="!item.isDeleted &&
    item.status != 'Ghost' &&
    item.bookingId == booking.id
    " color="grey" icon @click="addMilestone(item, 'WEIGH_BRIDGE')"><v-icon small>add_circle_outline</v-icon></v-btn>
                            </template>
                            <template v-slot:[`item.weighBridgeContract`]="{ item }">
                              <!-- <span>Test</span> -->
                              <v-chip outlined small style="border: none" v-if="item.CONTRACT_WEIGH_BRIDGE" @click="
    addContractItem(item.CONTRACT_WEIGH_BRIDGE)
    ">
                                {{ item.CONTRACT_WEIGH_BRIDGE.contractNo }} -
                                {{ item.CONTRACT_WEIGH_BRIDGE.role }}
                              </v-chip>
                              <v-btn v-else-if="!item.isDeleted &&
    item.status != 'Ghost' &&
    item.bookingId == booking.id
    " color="grey" icon @click="
    addContractItem({
      typeCode: 'WEIGH_BRIDGE',
      type: 'Weigh Bridge',
      bookingId: booking.id,
      bookingContainerId: item.id,
      bookingContainer: item,
    })
    "><v-icon small>add_circle_outline</v-icon></v-btn>
                            </template>
                            <template v-slot:[`item.status`]="{ item }">
                              <v-row justify="center">
                                <v-tooltip top :key="statusUpdate">
                                  <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" icon :color="containerStatusColor(item)" small @click="
    item.bookingId != booking.id
      ? routeToBooking(
        item.booking.systemReference
      )
      : item.status == 'Ghost'
        ? viewGhostContainer(item)
        : null
    ">
                                      <v-icon small>{{
    containerStatusIcon(item)
  }}</v-icon>
                                    </v-btn>
                                  </template>
                                  <div v-if="item.bookingId == booking.id">
                                    <span v-if="item.isDeleted">Cancelled</span>
                                    <span v-else>{{
    item.status ?? "Unallocated"
  }}</span>
                                  </div>
                                  <span v-else>
                                    Moved to {{ item.booking.orderNo }}
                                  </span>
                                </v-tooltip>
                                <v-tooltip top>
                                  <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" icon :color="item.palletCount > 0
    ? 'success'
    : 'grey'
    " small>
                                      <v-icon small>verified</v-icon>
                                    </v-btn>
                                  </template>
                                  <span> Pallets: {{ item.palletCount }} </span>
                                </v-tooltip>
                              </v-row>
                            </template>

                            <!-- Import Fields -->
                            <template v-slot:[`item.fullPickup`]="{ item }">
                              <v-chip outlined small style="border: none" v-if="item.FULL_PICKUP"
                                @click="addMilestone(item, 'FULL_PICKUP')">
                                {{ item.FULL_PICKUP.description }}
                              </v-chip>
                              <v-btn v-else-if="!item.isDeleted &&
    item.status != 'Ghost' &&
    item.bookingId == booking.id
    " color="grey" icon @click="addMilestone(item, 'FULL_PICKUP')"><v-icon small>add_circle_outline</v-icon></v-btn>
                            </template>
                            <template v-slot:[`item.fullPickupTime`]="{ item }">
                              <v-chip outlined small style="border: none" v-if="item.FULL_PICKUP &&
    (item.FULL_PICKUP.date ||
      item.FULL_PICKUP.time)
    " @click="addMilestone(item, 'FULL_PICKUP')">
                                {{
    formatDate(item.FULL_PICKUP.date).isoFormat
  }}
                                {{ item.FULL_PICKUP.time }}
                              </v-chip>
                              <v-btn v-else-if="!item.isDeleted &&
    item.status != 'Ghost' &&
    item.bookingId == booking.id
    " color="grey" icon @click="addMilestone(item, 'FULL_PICKUP')"><v-icon small>add_circle_outline</v-icon></v-btn>
                            </template>
                            <template v-slot:[`item.deliveryPoint1`]="{ item }">
                              <v-chip outlined small style="border: none" v-if="item.DELIVERY_POINT_1"
                                @click="addMilestone(item, 'DELIVERY_POINT_1')">
                                {{ item.DELIVERY_POINT_1.description }}
                              </v-chip>
                              <v-btn v-else-if="!item.isDeleted &&
    item.status != 'Ghost' &&
    item.bookingId == booking.id
    " color="grey" icon @click="addMilestone(item, 'DELIVERY_POINT_1')"><v-icon
                                  small>add_circle_outline</v-icon></v-btn>
                            </template>
                            <template v-slot:[`item.deliveryPoint1Time`]="{ item }">
                              <v-chip outlined small style="border: none" v-if="item.DELIVERY_POINT_1 &&
    (item.DELIVERY_POINT_1.date ||
      item.DELIVERY_POINT_1.time)
    " @click="addMilestone(item, 'DELIVERY_POINT_1')">
                                {{
    formatDate(item.DELIVERY_POINT_1.date)
      .isoFormat
  }}
                                {{ item.DELIVERY_POINT_1.time }}
                              </v-chip>
                              <v-btn v-else-if="!item.isDeleted &&
    item.status != 'Ghost' &&
    item.bookingId == booking.id
    " color="grey" icon @click="addMilestone(item, 'DELIVERY_POINT_1')"><v-icon
                                  small>add_circle_outline</v-icon></v-btn>
                            </template>
                            <template v-slot:[`item.deliveryPoint2`]="{ item }">
                              <v-chip outlined small style="border: none" v-if="item.DELIVERY_POINT_2"
                                @click="addMilestone(item, 'DELIVERY_POINT_2')">
                                {{ item.DELIVERY_POINT_2.description }}
                              </v-chip>
                              <v-btn v-else-if="!item.isDeleted &&
    item.status != 'Ghost' &&
    item.bookingId == booking.id
    " color="grey" icon @click="addMilestone(item, 'DELIVERY_POINT_2')"><v-icon
                                  small>add_circle_outline</v-icon></v-btn>
                            </template>
                            <template v-slot:[`item.deliveryPoint2Time`]="{ item }">
                              <v-chip outlined small style="border: none" v-if="item.DELIVERY_POINT_2 &&
    (item.DELIVERY_POINT_2.date ||
      item.DELIVERY_POINT_2.time)
    " @click="addMilestone(item, 'DELIVERY_POINT_2')">
                                {{
    formatDate(item.DELIVERY_POINT_2.date)
      .isoFormat
  }}
                                {{ item.DELIVERY_POINT_2.time }}
                              </v-chip>
                              <v-btn v-else-if="!item.isDeleted &&
    item.status != 'Ghost' &&
    item.bookingId == booking.id
    " color="grey" icon @click="addMilestone(item, 'DELIVERY_POINT_2')"><v-icon
                                  small>add_circle_outline</v-icon></v-btn>
                            </template>
                            <template v-slot:[`item.deliveryPoint3`]="{ item }">
                              <v-chip outlined small style="border: none" v-if="item.DELIVERY_POINT_3"
                                @click="addMilestone(item, 'DELIVERY_POINT_3')">
                                {{ item.DELIVERY_POINT_3.description }}
                              </v-chip>
                              <v-btn v-else-if="!item.isDeleted &&
    item.status != 'Ghost' &&
    item.bookingId == booking.id
    " color="grey" icon @click="addMilestone(item, 'DELIVERY_POINT_3')"><v-icon
                                  small>add_circle_outline</v-icon></v-btn>
                            </template>
                            <template v-slot:[`item.deliveryPoint3Time`]="{ item }">
                              <v-chip outlined small style="border: none" v-if="item.DELIVERY_POINT_3 &&
    (item.DELIVERY_POINT_3.date ||
      item.DELIVERY_POINT_3.time)
    " @click="addMilestone(item, 'DELIVERY_POINT_3')">
                                {{
    formatDate(item.DELIVERY_POINT_3.date)
      .isoFormat
  }}
                                {{ item.DELIVERY_POINT_3.time }}
                              </v-chip>
                              <v-btn v-else-if="!item.isDeleted &&
    item.status != 'Ghost' &&
    item.bookingId == booking.id
    " color="grey" icon @click="addMilestone(item, 'DELIVERY_POINT_3')"><v-icon
                                  small>add_circle_outline</v-icon></v-btn>
                            </template>
                            <template v-slot:[`item.emptyDropOffPoint`]="{ item }">
                              <v-chip outlined small style="border: none" v-if="item.EMPTY_DROP_OFF"
                                @click="addMilestone(item, 'EMPTY_DROP_OFF')">
                                {{ item.EMPTY_DROP_OFF.description }}
                              </v-chip>
                              <v-btn v-else-if="!item.isDeleted &&
    item.status != 'Ghost' &&
    item.bookingId == booking.id
    " color="grey" icon @click="addMilestone(item, 'EMPTY_DROP_OFF')"><v-icon small>add_circle_outline</v-icon></v-btn>
                            </template>
                            <template v-slot:[`item.emptyDropOffPointTime`]="{ item }">
                              <v-chip outlined small style="border: none" v-if="item.EMPTY_DROP_OFF &&
    (item.EMPTY_DROP_OFF.date ||
      item.EMPTY_DROP_OFF.time)
    " @click="addMilestone(item, 'EMPTY_DROP_OFF')">
                                {{
    formatDate(item.EMPTY_DROP_OFF.date).isoFormat
  }}
                                {{ item.EMPTY_DROP_OFF.time }}
                              </v-chip>
                              <v-btn v-else-if="!item.isDeleted &&
    item.status != 'Ghost' &&
    item.bookingId == booking.id
    " color="grey" icon @click="addMilestone(item, 'EMPTY_DROP_OFF')"><v-icon small>add_circle_outline</v-icon></v-btn>
                            </template>
                          </v-data-table>
                        </div>
                        <div v-if="toggleContainerView == 1" style="max-height: 72vh; overflow-y: auto">
                          <v-row class="my-2 mx-1">
                            <v-col cols="12" md="6" v-for="item in displayContainers" :key="item.id">
                              <v-card style="
                                  background: var(
                                    --v-background-base
                                  ) !important;
                                  border-radius: 20px;
                                " class="my-1">
                                <v-row class="mb-1 mt-2 px-2 pl-3">
                                  <v-chip outlined style="border: none" class="mx-1" @click="
    item.bookingId == booking.id &&
      item.status != 'DELETED' &&
      item.status != 'Ghost' &&
      item.status != 'COMPLETED' &&
      item.status != 'CANCELLED' &&
      !item.duplicated &&
      !item.isDeleted
      ? selectOneContainer(item)
      : null
    " :color="item.selected ? 'blue' : ''">
                                    <v-tooltip top :key="statusUpdate">
                                      <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" icon left :color="containerStatusColor(item)" small @click="
    item.bookingId != booking.id
      ? routeToBooking(
        item.booking.systemReference
      )
      : item.status == 'Ghost'
        ? viewGhostContainer(item)
        : null
    ">
                                          <v-icon small>{{
    containerStatusIcon(item)
  }}</v-icon>
                                        </v-btn>
                                      </template>
                                      <div v-if="item.bookingId == booking.id">
                                        <span v-if="item.isDeleted">Cancelled</span>
                                        <span v-else>{{
    item.status ?? "Unallocated"
  }}</span>
                                      </div>
                                      <span v-else>
                                        Moved to {{ item.booking.orderNo }}
                                      </span>
                                    </v-tooltip>
                                    <span class="ml-2"> {{ item.ctoNo }}</span>
                                  </v-chip>
                                  <v-chip small class="mx-1" outlined style="border: none">
                                    <v-avatar left>
                                      <v-icon small>scale</v-icon>
                                    </v-avatar>
                                    {{ item.nettWeight ?? " - " }}KG NETT
                                  </v-chip>
                                  <v-chip small class="mx-1" outlined style="border: none">
                                    <v-avatar left>
                                      <v-icon small>scale</v-icon>
                                    </v-avatar>
                                    <v-avatar class="mr-1" left size="24" v-if="item.loadOutReceived"
                                      color="orange darken-3">
                                      <h4>LI</h4>
                                    </v-avatar>
                                    {{ item.grossWeight ?? " - " }}KG GROSS
                                  </v-chip>
                                  <v-chip small class="mx-1" outlined style="border: none">
                                    <v-avatar left>
                                      <v-img src="../../../public/icons/pallet.png" contain></v-img>
                                    </v-avatar>
                                    {{ item.totalPallets }} Pallets
                                  </v-chip>
                                  <v-chip small class="mx-1" outlined style="border: none">
                                    <v-avatar left>
                                      <v-img src="../../../public/icons/carton.png" contain></v-img>
                                    </v-avatar>
                                    {{ item.totalPackageCount }} Packages
                                  </v-chip>
                                </v-row>
                                <v-card-text class="my-0 py-0">
                                  <v-row>
                                    <v-col cols="4" class="ma-0 pa-0">
                                      <v-list dense class="mx-0 px-0">
                                        <v-list-item style="height: 22px" @click="editContainerNo(item)">
                                          <v-list-item-action class="mx-0 px-0">
                                            <v-icon small color="grey">widgets</v-icon>
                                          </v-list-item-action>
                                          <v-list-item-content>
                                            <v-list-item-title style="font-size: 11px">
                                              <span v-if="item.containerNo">{{
    item.containerNo
  }}</span>
                                              <span v-else>-</span>
                                              <i v-if="item.containerTypeCode" style="color: grey" class="ml-1">({{
    item.containerTypeCode
  }})</i>
                                            </v-list-item-title>
                                            <v-list-item-subtitle style="font-size: 10px">Container
                                              No.</v-list-item-subtitle>
                                          </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item style="height: 22px" @click="editSealNo(item)">
                                          <v-list-item-action class="mx-0 px-0">
                                            <v-icon small color="grey">label</v-icon>
                                          </v-list-item-action>
                                          <v-list-item-content>
                                            <v-list-item-title style="font-size: 11px">
                                              <span v-if="item.sealNo">{{
    item.sealNo
  }}</span>
                                              <span>-</span></v-list-item-title>
                                            <v-list-item-subtitle style="font-size: 10px">Seal
                                              No.</v-list-item-subtitle>
                                          </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item style="height: 22px" @click="editCustomerReference(item)">
                                          <v-list-item-action class="mx-0 px-0">
                                            <v-icon small color="grey">groups</v-icon>
                                          </v-list-item-action>
                                          <v-list-item-content>
                                            <v-list-item-title style="font-size: 11px">
                                              <span v-if="item.customerContainerRef">
                                                {{ item.customerContainerRef }}
                                              </span>
                                              <span v-else>-</span></v-list-item-title>
                                            <v-list-item-subtitle style="font-size: 10px">Customer
                                              Ref</v-list-item-subtitle>
                                          </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item style="height: 22px" @click="editFile(item)">
                                          <v-list-item-action class="mx-0 px-0">
                                            <v-icon small color="grey">article</v-icon>
                                          </v-list-item-action>
                                          <v-list-item-content>
                                            <v-list-item-title style="font-size: 11px">
                                              <span v-if="item.shipmentFile">
                                                {{
    item.shipmentFile.fileNumber
  }}
                                              </span>
                                              <span v-else>-</span></v-list-item-title>
                                            <v-list-item-subtitle style="font-size: 10px">Attached
                                              File</v-list-item-subtitle>
                                          </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item style="height: 22px" @click="
  item.verificationMethod
    ? editVerificationMethod(item)
    : addVerificationMethod(item)
    ">
                                          <v-list-item-action class="mx-0 px-0">
                                            <v-icon small color="grey">scale</v-icon>
                                          </v-list-item-action>
                                          <v-list-item-content>
                                            <v-list-item-title style="font-size: 11px">
                                              <span v-if="item.verificationMethod">
                                                {{ item.verificationMethod }}
                                              </span>
                                              <span v-else>-</span></v-list-item-title>
                                            <v-list-item-subtitle style="font-size: 10px">Method</v-list-item-subtitle>
                                          </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item style="height: 22px" @click="editVents(item)" v-if="booking.serviceType !='BREAKBULK'">
                                          <v-list-item-action class="mx-0 px-0">
                                            <v-icon small color="grey">air</v-icon>
                                          </v-list-item-action>
                                          <v-list-item-content>
                                            <v-list-item-title style="font-size: 11px">
                                              <span v-if="item.vents">
                                                {{ item.vents }}
                                                <span v-if="item.vents != 'Closed' &&
    item.vents != ' CA' &&
    item.vents != ' MA'
    ">
                                                  CBM</span>
                                              </span>
                                              <span v-else>-</span></v-list-item-title>
                                            <v-list-item-subtitle style="font-size: 10px">vents</v-list-item-subtitle>
                                          </v-list-item-content>
                                        </v-list-item>
                                      </v-list>
                                    </v-col>
                                    <v-col cols="3" class="ma-0 pa-0">
                                      <v-list dense subheader class="mx-0 px-0">
                                        <v-subheader class="mb-0 pb-0"><v-icon small class="mr-2"
                                            color="grey">category</v-icon>
                                          Features</v-subheader>
                                        <template>
                                          <v-tooltip top v-if="item.temporaryProducts.some(
    (product) =>
      product.product === 'Genset'
  )
    ">
                                            <template v-slot:activator="{ on }">
                                              <v-btn icon outlined small class="mx-1" v-on="on" color="#D6D46D"><v-icon
                                                  small>bolt</v-icon></v-btn>
                                            </template>
                                            <span>Genset Required</span>
                                          </v-tooltip>

                                          <!-- <v-tooltip
                                top
                                v-if="
                                  item.temporaryProducts.some(
                                    (product) =>
                                      product.product === 'Port Monitoring'
                                  )
                                "
                              >
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    icon
                                    outlined
                                    small
                                    class="mx-1"
                                    v-on="on"
                                    color="#D6CC99"
                                    ><v-icon small>monitor_heart</v-icon></v-btn
                                  >
                                </template>
                                <span>Port Monitoring Required</span>
                              </v-tooltip> -->

                                          <v-tooltip top v-if="item.temporaryProducts.some(
    (product) =>
      product.product ===
      'Fumigation'
  )
    ">
                                            <template v-slot:activator="{ on }">
                                              <v-btn icon outlined small class="mx-1" v-on="on" color="#F1DBBF"><v-icon
                                                  small>cleaning_services</v-icon></v-btn>
                                            </template>
                                            <span>Fumigations Required</span>
                                          </v-tooltip>

                                          <v-tooltip top v-if="item.temporaryProducts.some(
    (product) =>
      product.product ===
      'Void Plugs'
  )
    ">
                                            <template v-slot:activator="{ on }">
                                              <v-btn icon outlined small class="mx-1" v-on="on" color="#e05b0d"><v-icon
                                                  small>power</v-icon></v-btn>
                                            </template>
                                            <span>Void Plugs</span>
                                          </v-tooltip>

                                          <v-tooltip top v-if="item.temporaryProducts.some(
    (product) =>
      product.product ===
      'Blast Freezing'
  )
    ">
                                            <template v-slot:activator="{ on }">
                                              <v-btn icon outlined small class="mx-1" v-on="on" color="#13a7d4"><v-icon
                                                  small>ac_unit</v-icon></v-btn>
                                            </template>
                                            <span>Blast Freezing</span>
                                          </v-tooltip>

                                          <v-tooltip top v-if="item.temporaryProducts.some(
    (product) =>
      product.product ===
      'Monitoring Service' &&
      product.variation
  )
    ">
                                            <template v-slot:activator="{ on }">
                                              <v-btn icon outlined small class="mx-1" v-on="on" color="#99B080"><v-icon
                                                  small>desktop_windows</v-icon></v-btn>
                                            </template>
                                            <span>
                                              {{
    item.temporaryProducts.find(
      (product) =>
        product.product ===
        "Monitoring Service"
    ).variation +
    " " +
    "Service" +
    " " +
    "Required"
  }}
                                            </span>
                                          </v-tooltip>

                                          <v-tooltip top v-if="item.temporaryProducts.some(
      (product) =>
        product.product ===
        'TelemPlus'
    )
    ">
                                            <template v-slot:activator="{ on }">
                                              <v-btn icon outlined small class="mx-1" v-on="on" color="#18e00d"><v-icon
                                                  small>router</v-icon></v-btn>
                                            </template>
                                            <span>
                                              Devices Required:
                                              <template v-if="item.temporaryProducts.some(
    (product) =>
      product.product ===
      'TelemPlus'
  )
    ">
                                                <ul>
                                                  <li v-for="(
                                                      [variation, count], index
                                                    ) in Object.entries(
    item.temporaryProducts
      .filter(
        (product) =>
          product.product ===
          'TelemPlus'
      )
      .reduce(
        (acc, product) => {
          if (
            !acc[
            product
              .variation
            ]
          ) {
            acc[
              product.variation
            ] = 0;
          }
          acc[
            product.variation
          ] += product.count;
          return acc;
        },
        {}
      )
  )" :key="index">
                                                    {{ count }}X{{ variation }}
                                                  </li>
                                                </ul>
                                              </template>
                                            </span>
                                          </v-tooltip>

                                          <v-tooltip top v-if="item.temporaryProducts.some( (product) => product.product === 'Otflow' ) ">
                                            <template v-slot:activator="{ on }">
                                              <v-btn icon outlined small class="mx-1" v-on="on" color="#6A9C89"><v-icon
                                                  small>air</v-icon></v-btn>
                                            </template>
                                            <span>Otflow Required</span>
                                          </v-tooltip>


                                          <v-tooltip top v-if="item.temporaryProducts.some( (product) => product.product === 'Zoono' ) ">
                                            <template v-slot:activator="{ on }">
                                              <v-btn icon outlined small class="mx-1" v-on="on" color="#5b93c7">
                                                <v-icon small>sanitizer</v-icon>
                                              </v-btn>
                                            </template>
                                            <span>Zoono Required</span>
                                          </v-tooltip>

                                          <v-tooltip top v-if="item.temporaryProducts.some( (product) => product.product === 'Dunnage Bags' ) ">
                                            <template v-slot:activator="{ on }">
                                              <v-btn icon outlined small class="mx-1" v-on="on" color="#F1DBBF"><v-icon
                                                  small>shopping_bag</v-icon></v-btn>
                                            </template>
                                            <span>Dunnage Bags Required</span>
                                          </v-tooltip>

                                          <v-tooltip top v-if="item.temporaryProducts.some( (product) => product.product === 'Late Arrival' ) ">
                                            <template v-slot:activator="{ on }">
                                              <v-btn icon outlined small class="mx-1" v-on="on" color="#BB2525"><v-icon
                                                  small>watch_later</v-icon></v-btn>
                                            </template>
                                            <span>Late Arrival Required : {{ item.temporaryProducts.find( (product) => product.product === "Late Arrival" ).variation }}</span>
                                          </v-tooltip>

                              <v-tooltip top v-if="item.temporaryProducts.some( (product) => product.product === 'Early Arrival' ) ">
                                <template v-slot:activator="{ on }">
                                  <v-btn icon outlined small class="mx-1" v-on="on" color="orange"><v-icon
                                      small>watch_later</v-icon></v-btn>
                                </template>
                                <span>Early Arrival Required : {{ item.temporaryProducts.find( (product) => product.product === "Early Arrival" ).variation }}</span>
                              </v-tooltip>

                                          <v-tooltip top v-if="item.temporaryProducts.some(
      (product) =>
        product.product === 'SmartFresh'
    )
    ">
                                            <template v-slot:activator="{ on }">
                                              <v-btn icon outlined small class="mx-1" v-on="on" color="#FFBFFF"><v-icon
                                                  small>smart_screen</v-icon></v-btn>
                                            </template>
                                            <span>Smart Fresh Required</span>
                                          </v-tooltip>

                                          <v-tooltip top v-if="item.temporaryProducts.some(
    (product) =>
      product.product === 'Latches'
  )
    ">
                                            <template v-slot:activator="{ on }">
                                              <v-btn icon outlined small class="mx-1" v-on="on" color="#508D69"><v-icon
                                                  small>pivot_table_chart</v-icon></v-btn>
                                            </template>
                                            <span>Latches Required</span>
                                          </v-tooltip>

                                          <v-tooltip top v-if="item.temporaryProducts.some(
    (product) =>
      product.product === 'Ratches'
  )
    ">
                                            <template v-slot:activator="{ on }">
                                              <v-btn icon outlined small class="mx-1" v-on="on" color="#EA906C"><v-icon
                                                  small>settings_input_component</v-icon></v-btn>
                                            </template>
                                            <span>Ratches Required</span>
                                          </v-tooltip>
                                          <v-tooltip top v-if="item.temporaryProducts.some(
    (product) => product.product === 'Ethylene Filters'
  )
    ">
                                            <template v-slot:activator="{ on }">
                                              <v-btn icon outlined small class="mx-1" v-on="on" color="#EE12CA"><v-icon
                                                  small>filter_alt</v-icon></v-btn>
                                            </template>
                                            <span>Ethylene Filters Required</span>
                                          </v-tooltip>
                                          <v-tooltip top v-if="item.temporaryProducts.some(
    (product) =>
      product.product ===
      'Stabling bars'
  )
    ">
                                            <template v-slot:activator="{ on }">
                                              <v-btn icon outlined small class="mx-1" v-on="on" color="#5F6F52"><v-icon
                                                  small>video_stable</v-icon></v-btn>
                                            </template>
                                            <span>Stabling bars Required</span>
                                          </v-tooltip>

                                          <v-tooltip top v-if="item.temporaryProducts.some(
    (product) =>
      product.product ===
      'Kraft paper'
  )
    ">
                                            <template v-slot:activator="{ on }">
                                              <v-btn icon outlined small class="mx-1" v-on="on" color="#C5E898"><v-icon
                                                  small>note</v-icon></v-btn>
                                            </template>
                                            <span>Kraft paper Required</span>
                                          </v-tooltip>

                                          <v-tooltip top v-if="item.temporaryProducts.some(
    (product) =>
      product.product ===
      'Absorb Bags'
  )
    ">
                                            <template v-slot:activator="{ on }">
                                              <v-btn icon outlined small class="mx-1" v-on="on" color="#DCBFFF"><v-icon
                                                  small>backpack</v-icon></v-btn>
                                            </template>
                                            <span>Absorb Bags Required</span>
                                          </v-tooltip>
                                        </template>
                                        <!-- <v-tooltip
                                          top
                                          v-if="item.gensetRequired"
                                        >
                                          <template v-slot:activator="{ on }">
                                            <v-btn
                                              icon
                                              outlined
                                              small
                                              class="mx-1"
                                              v-on="on"
                                              color="#c4c166"
                                              ><v-icon small
                                                >bolt</v-icon
                                              ></v-btn
                                            >
                                          </template>
                                          <span>Genset Required</span>
                                        </v-tooltip>

                                        <v-tooltip top v-if="item.caProduct">
                                          <template v-slot:activator="{ on }">
                                            <v-btn
                                              icon
                                              outlined
                                              small
                                              class="mx-1"
                                              v-on="on"
                                              color="teal"
                                              ><v-icon small
                                                >thermostat_auto</v-icon
                                              ></v-btn
                                            >
                                          </template>
                                          <span
                                            >Controlled Atmosphere:
                                            {{ item.caProduct }}</span
                                          >
                                        </v-tooltip>

                                        <v-tooltip top v-if="item.sensorDevice">
                                          <template v-slot:activator="{ on }">
                                            <v-btn
                                              icon
                                              outlined
                                              small
                                              class="mx-1"
                                              v-on="on"
                                              color="#c45d1d"
                                              ><v-icon small
                                                >router</v-icon
                                              ></v-btn
                                            >
                                          </template>
                                          <span
                                            >Monitoring Device:
                                            <span v-if="item.sensorDeviceType">
                                              {{ item.sensorDeviceType }}
                                              <span v-if="item.sensorService">
                                                with {{ item.sensorService }}
                                                service
                                              </span>
                                              <span v-else>
                                                without a service
                                              </span>
                                            </span>
                                            <span v-else>None</span>
                                          </span>
                                        </v-tooltip> -->
                                        <v-subheader class="mb-0 pb-0" style="
                                            padding-bottom: 0px;
                                            margin-bottom: 0px;
                                          "><v-icon small class="mr-2" color="grey">category</v-icon>
                                          Products
                                          <v-btn icon color="primary" small @click="addContainerProduct(item)"><v-icon
                                              small>add_circle_outline</v-icon></v-btn></v-subheader>
                                        <v-list-item v-for="product in item.containerProducts"
                                          class="mx-0 px-0 pl-1 mt-0 pt-0" :key="product.id" @click="
    editContainerProduct(item, product)
    ">
                                          <v-list-item-content class="ml-1">
                                            <v-list-item-title style="font-size: 11px">
                                              {{
    $Format.capitalizeFirstLetter(
      product.product.name
    )
  }}
                                              <v-chip outlined style="border: none" small v-if="product.industrial ||
    product.organic ||
    product.hazardous
    ">
                                                <v-icon v-if="product.industrial" class="mx-1" color="blue-grey"
                                                  small>factory</v-icon>
                                                <v-icon class="mx-1" v-if="product.organic" color="green"
                                                  small>spa</v-icon>
                                                <v-icon color="orange" class="mx-1" small
                                                  v-if="product.hazardous">warning</v-icon>
                                              </v-chip>
                                            </v-list-item-title>
                                            <v-list-item-subtitle style="font-size: 10px" v-if="product.nettWeight">
                                              <span>
                                                Nett:
                                                {{ product.nettWeight }}Kg</span></v-list-item-subtitle>
                                            <v-list-item-subtitle style="font-size: 10px" v-if="product.grossWeight">
                                              <span>
                                                Gross:
                                                {{
    product.grossWeight
  }}Kg</span></v-list-item-subtitle>
                                            <v-list-item-subtitle style="font-size: 10px">
                                              <v-chip x-small pill v-if="product.pallets" outlined style="border: none">
                                                <v-avatar left size="24">
                                                  <v-img src="../../../public/icons/pallet.png" style="
                                                      width: 20px;
                                                      height: 20px;
                                                    " contain></v-img>
                                                </v-avatar>
                                                {{ product.pallets }}
                                              </v-chip>
                                              <v-chip x-small v-if="product.quantity" outlined style="border: none">
                                                <v-avatar left size="24">
                                                  <v-img src="../../../public/icons/carton.png" style="
                                                      width: 20px;
                                                      height: 20px;
                                                    " contain></v-img>
                                                </v-avatar>
                                                {{ product.quantity }}
                                              </v-chip>
                                              <!-- <span v-if="product.quantity"> Carton(s):
                                                                                    {{ product.quantity
                                                                                    }}</span> <span v-if="product.pallets">
                                                                                    - {{ product.pallets }}
                                                                                    Pallets</span> -->
                                            </v-list-item-subtitle>
                                          </v-list-item-content>
                                        </v-list-item>
                                      </v-list>
                                    </v-col>
                                    <v-col cols="5" class="ma-0 pa-0">
                                      <v-list dense subheader class="ml-0 pl-0 mt-0 pt-0" :key="milestoneKey">
                                        <v-subheader><v-icon small class="mr-2" color="grey">local_shipping</v-icon>
                                          Milestones
                                          <v-btn @click="addMilestone(item)" icon
                                            color="primary"><v-icon>add_circle_outline</v-icon></v-btn></v-subheader>
                                          <v-list-item style="height: 18px" v-for="( milestone, index ) in availableMilestones.filter( (x) => x.type == booking.movementType && (x.value !== 'GASSING' || (x.value === 'GASSING' && !avoBox && booking.caProduct)) )" :key="index" @click=" addMilestone( containerItem, milestone.value, false ) ">
                                          <v-list-item-action class="mr-0 pr-0">
                                            <v-avatar size="10" v-if="item[milestone.value]" :color="item[milestone.value].isActual ? 'success' : 'secondary' "></v-avatar>
                                            <v-icon v-else color="grey" small>warning</v-icon>
                                          </v-list-item-action>
                                          <v-list-item-content v-if="item[milestone.value]">
                                            <v-list-item-title style="font-size: 11px">{{ item[milestone.value] .description }}</v-list-item-title>
                                            <v-list-item-subtitle style="font-size: 10px">{{ $Format.formatDate(item[milestone.value].date).isoFormat }} {{ item[milestone.value].time }}</v-list-item-subtitle>
                                          </v-list-item-content>
                                          <v-list-item-content v-else>
                                            <v-list-item-subtitle style="font-size: 10px">{{ milestone.name }}</v-list-item-subtitle>
                                          </v-list-item-content>
                                        </v-list-item>
                                      </v-list>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-card>
                            </v-col>
                          </v-row>
                        </div>

                        <v-menu v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y>
                          <v-list dense style="background: var(--v-modal-base) !important">
                            <v-subheader v-if="container" style="font-size: 14px"><v-icon class="mr-2"
                                color="grey">widgets</v-icon>
                              {{ container.ctoNo }}</v-subheader>
                            <v-divider></v-divider>
                            <div v-if="container && !container.isDeleted && container.status != 'Ghost' ">
                              <v-list-item v-for="(item, index) in menuItems.filter( (x) => !x.deleted )" :key="index" @click="clickAction(item.action)">
                                <v-list-item-action class="mr-1 pr-1">
                                  <v-icon :color="item.color">{{ item.icon }}</v-icon>
                                </v-list-item-action>
                                <v-list-item-content class="text-left ml-0 pl-0">
                                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </div>
                            <div v-else>
                              <v-list-item v-for="(item, index) in menuItems.filter( (x) => x.deleted )" :key="index" @click="clickAction(item.action)">
                                <v-list-item-action class="mr-1 pr-1">
                                  <v-icon :color="item.color">{{ item.icon }}</v-icon>
                                </v-list-item-action>
                                <v-list-item-content class="text-left ml-0 pl-0">
                                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </div>
                          </v-list>
                        </v-menu>
                        <!-- ////////////////////////////////////////////////////////////////////////////////////////// END OF TABLE /////////////////////////////////////////////////////////////////////////////////////////////////////// -->
                      </v-card-text>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-expand-transition>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-toolbar dense flat color="transparent" class="pb-2">
        <v-spacer></v-spacer>
        <v-menu v-model="commentMenu" :close-on-content-click="false">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" large color="blue darken-3" style="
                text-transform: none;
                border-top-right-radius: 20px;
                border-top-left-radius: 20px;
              ">Comments <v-icon right small>expand_less</v-icon></v-btn>
          </template>
          <v-card width="25vw" min-width="400px" height="50vh" min-height="600px">
            <v-toolbar flat dense>
              <v-toolbar-title>Comments</v-toolbar-title> <v-spacer></v-spacer><v-btn text
                @click="commentMenu = false">X</v-btn></v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="mx-0 px-0">
              <v-list dense>
                <v-list-item v-if="comments.length == 0">
                  <v-list-item-content class="text-center">
                    <span style="font-size: 12px; color: grey">
                      No comments.
                    </span>
                  </v-list-item-content>
                </v-list-item>
                <v-list dense style="overflow-y: auto; height: 45vh">
                  <v-list-item v-for="item in comments" :key="item.id" class="py-0 my-1">
                    <v-list-item-avatar v-if="item.user && item.userId != $store.state.user.id && item.user.firstname">
                      <v-tooltip top v-if="item.user">
                        <template v-slot:activator="{ on, attrs }">
                          <v-avatar v-bind="attrs" v-on="on" style="cursor: pointer" color="secondary" size="48"
                            class="mt-0 pt-0">
                            <span v-if="!item.user.avatar" class="primaryText--text text-h5">{{
    item.user.firstname.charAt(0) }}</span>
                            <img v-else :src="item.user.avatar" referrerpolicy="no-referrer" />
                          </v-avatar>
                        </template>
                        <span style="font-size: 12px" v-if="item.user">{{ item.user.firstname }}
                          {{ item.user.surname }}</span>
                      </v-tooltip>
                    </v-list-item-avatar>

<v-list-item-content v-if="item.userId == $store.state.user.id && item.isDeleted == false">

<div style="display: flex;flex-direction: row;justify-content: start;">

<v-tooltip top>
<template v-slot:activator="{ on: tooltip }">

<v-btn  v-on="{ ...tooltip }" text @click="openBookingDialog(item)"><v-icon color="blue">edit</v-icon></v-btn>

</template>
<span>Edit Comment</span>
</v-tooltip>

<v-tooltip top>
<template v-slot:activator="{ on: tooltip }">

<v-btn :loading="loadingBookingDeleteComment"   v-on="{ ...tooltip }" text @click="deleteBookingComment(item.id)">
<v-icon color="red">delete</v-icon>

</v-btn>

</template>
<span>Remove Comment</span>
</v-tooltip>
</div>
</v-list-item-content>

<v-list-item-content v-if="item.userId == $store.state.user.id && item.isDeleted == true">
<div></div>

</v-list-item-content>

                    <v-list-item-content :class="item.userId == $store.state.user.id
    ? 'text-right'
    : 'text-left'
    ">
                            <v-list-item-title class="text-wrap" v-if="item.isDeleted == true" style="text-decoration: line-through;color: gray">
                              {{ item.comment }}
                            </v-list-item-title>
                            <v-list-item-title class="text-wrap" v-else >
                                        {{ item.comment}}
                            </v-list-item-title>

                            
                            
                      <v-list-item-subtitle v-if="item.isInternal && item.isDeleted == true" style="text-decoration: line-through;color: gray" >
                        <v-chip x-small color="blue darken-3">Internal</v-chip>
                      </v-list-item-subtitle>

            

                      <v-list-item-subtitle v-else>
                 
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <i style="font-size: 12px; color: grey">{{
    formatDate(item.createdAt).fullDate
  }}</i>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-avatar v-if="item.userId == $store.state.user.id && item.user.firstname">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-avatar v-bind="attrs" v-on="on" style="cursor: pointer" color="secondary" size="48"
                            class="mt-0 pt-0">
                            <span v-if="!item.user.avatar" class="primaryText--text text-h5">{{
    item.user.firstname.charAt(0) }}</span>
                            <img v-else :src="item.user.avatar" referrerpolicy="no-referrer" />
                          </v-avatar>
                        </template>
                        <span style="font-size: 12px" v-if="item.user">{{ item.user.firstName }}
                          {{ item.user.surname }}</span>
                      </v-tooltip>
                    </v-list-item-avatar>
                  </v-list-item>
                </v-list>
                <v-card flat height="8vh" width="100%" style="
                    position: absolute;
                    bottom: 0;
                    background-color: var(--v-greyRaised-base) !important;
                    display: flex;
                    border-bottom-left-radius: 20px;
                    border-bottom-right-radius: 20px;
                    vertical-align: baseline;
                    overflow-y: hidden;
                  ">
                  <v-textarea class="ml-2" no-resize hide-details outlined autofocus height="7vh"
                    style="width: 70%; contain: content" placeholder="Send a message" v-model="comment"></v-textarea>
                  <div class="ml-3 mr-2 text-center">
                    <v-switch v-model="internalMessage" color="blue darken-3" hide-details label="Internal"
                      class="mt-1 pt-1"></v-switch>
                    <v-btn small class="mt-3" :loading="sendingMessage" @click="sendComment()"><v-icon small
                        left>send</v-icon> Send</v-btn>
                  </div>

                  <!-- -->
                </v-card>
              </v-list>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-toolbar>
    </v-card>

    <!------------------------------------------ QUICKLY ADD CONTAINER NUMBERS ------------------------------------------>
    <v-dialog v-model="editButton"  max-width="500px">



<v-card>

 <div style="display: flex;flex-direction: row;justify-content: space-between;align-items: baseline;">
  <v-card-title>Edit Comment</v-card-title>
  <v-card-actions>
    <v-btn :loading="loadingBookingEditComment" text @click="updateBookingComment(newEditedComment.id)"><v-icon color="blue">save</v-icon></v-btn>
    <v-btn  text @click="closeBookingEditDialog()">X</v-btn>

  </v-card-actions>
 </div>
<div style="display: flex;flex-direction: column;justify-content: center;align-items: center">
<v-textarea v-model="newEditedComment.comment" style="width: 80%; contain: content; border-radius: 20px;"  outlined rows="4">
</v-textarea>
</div>

</v-card>
</v-dialog>



    <v-dialog v-model="containerNoDialog" width="20vw" style="overflow-y: hidden">
      <v-card style="
          border-radius: 20px;
          overflow-y: hidden;
          overflow-x: hidden;
          background-color: var(--v-greyRaised-base) !important;
        ">
        <v-toolbar flat color="transparent" dense>
          <v-toolbar-title>Add Container Number</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="secondary" @click="saveContainerNo" :disabled="!containerNoTest(containerNo)"><v-icon>save</v-icon></v-btn>
          <v-btn icon color="redPop" @click="containerNoDialog = false,getContainers()" ><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="pt-5">
          <v-text-field color="greyBase-darken2" label="Container No." :key="containerNoKey" ref="containerNoInput"
            placeholder="ABCU1234567" persistent-placeholder v-model="containerNo" autofocus dense
            :rules="[rules.containerNo]" outlined @keydown.enter="saveContainerNo"
            prepend-inner-icon="tag"></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!------------------------------------------ QUICKLY ADD CUSTOMER REF ------------------------------------------>
    <v-dialog v-model="customerRefDialog" width="20vw" style="overflow-y: hidden">
      <v-card style="
          border-radius: 20px;
          overflow-y: hidden;
          overflow-x: hidden;
          background-color: var(--v-greyRaised-base) !important;
        ">
        <v-toolbar flat color="transparent" dense>
          <v-toolbar-title>Add Customer Ref</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="secondary" @click="saveCustomerRef"><v-icon>save</v-icon></v-btn>
          <v-btn icon color="primary" @click="customerRefDialog = false"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="pt-5">
          <v-text-field color="greyBase-darken2" label="Customer Ref." :key="customerRefKey" ref="customerRefInput"
            placeholder="BOX1234567" persistent-placeholder v-model="customerRef" autofocus dense outlined
            @keydown.enter="saveCustomerRef" prepend-inner-icon="tag"></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!------------------------------------------ QUICKLY ADD CUSTOMER REF ------------------------------------------>
    <v-dialog v-model="containerMethodDialog" width="20vw" style="overflow-y: hidden">
      <v-card v-if="selectedContainer" style="
          border-radius: 20px;
          overflow-y: hidden;
          overflow-x: hidden;
          background-color: var(--v-greyRaised-base) !important;
        ">
        <v-toolbar flat color="transparent" dense>
          <v-toolbar-title>Add Method</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="secondary" @click="saveVerificationMethod"
            :loading="savingVerificationMethod"><v-icon>save</v-icon></v-btn>
          <v-btn icon color="primary" @click="containerMethodDialog = false"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="pt-5">
          <v-select label="Method" placeholder="Select a Method" persistent-placeholder hide-details outlined clearable
            dense class="py-2" :items="['Method 1', 'Method 2']"
            v-model="selectedContainer.verificationMethod"></v-select>
          <!-- <v-select v-if="selectedContainer.verificationMethod == 'Method 1'" label="Weighbridge Contract Owner"
                        placeholder="Select a Party" persistent-placeholder hide-details outlined dense class="py-2"
                        :items="['Shipper', 'Forwarder']" v-model="selectedContainer.verificationMethodOwner"></v-select>
                    <v-autocomplete v-model="selectedContainer.weighBridgeId" prepend-inner-icon="location_on"
                        placeholder="Weighbridge Location" persistent-placeholder :items="weighBridges" dense outlined
                        clearable :loading="loadingWeighBridges" item-text="name" item-value="id">
                    </v-autocomplete> -->
        </v-card-text>
      </v-card>
    </v-dialog>

    <!------------------------------------------ QUICKLY ADD CONTAINER SEAL NUMBERS ------------------------------------------>
    <v-dialog v-model="sealNoDialog" width="20vw" style="overflow-y: hidden">
      <v-card style="
          border-radius: 20px;
          overflow-y: hidden;
          overflow-x: hidden;
          background-color: var(--v-greyRaised-base) !important;
        ">
        <v-toolbar flat color="transparent" dense>
          <v-toolbar-title>Add Seal Number</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="secondary" @click="saveSealNo"><v-icon>save</v-icon></v-btn>
          <v-btn icon color="primary" @click="sealNoDialog = false"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="pt-5">
          <v-text-field color="greyBase-darken2" label="Seal No." persistent-placeholder v-model="sealNo"
            @input="formatSealNo" @keydown.enter="saveSealNo" autofocus dense outlined
            prepend-inner-icon="tag"></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!------------------------------------------ CHANGE / UPDATE PRODUCTS ------------------------------------------>
    <v-dialog v-model="productView" width="450px">
      <v-card>
        <Products @close="productView = false" @chosenProduct="updateContainerProduct" />
      </v-card>
    </v-dialog>

    <!------------------------------------------ BULK UPDATE SELECTED CONTAINERS ------------------------------------------>
    <v-dialog v-model="bulkUpdateCard" width="95vw">
      <v-card style="overflow-y: hidden">
        <v-toolbar flat dense>
          <v-toolbar-title> Bulk Update Containers </v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-badge overlap 
                        :color="Object.keys(bulkEdit).length === 0 ? 'transparent' : 'edit'" 
                        :content="Object.keys(bulkEdit).length">
                        <v-btn @click="viewChangesModal = true" small plain :disabled="Object.keys(bulkEdit).length === 0">View Changes</v-btn>
                    </v-badge> -->
          <v-btn class="ml-4" color="primary" icon
            @click="(bulkUpdateCard = false), (isBulkEdit = false)"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-card height="35vh" tile flat style="overflow-y: auto">
                <v-data-table :items="chosenContainers" :headers="containerHeadersEdit" dense hide-default-footer
                  fixed-header disable-pagination>
                  <template v-slot:[`item.containerNo`]="{ item }">
                    <div v-if="item.containerNo" @click="
    (containerNo = item.containerNo),
    (containerNoDialog = true)
    " style="cursor: pointer">
                      {{ item.containerNo }}
                    </div>
                  </template>
                  <template v-slot:[`item.sealNo`]="{ item }">
                    <div v-if="item.sealNo" @click="(sealNo = item.sealNo), (sealNoDialog = true)"
                      style="cursor: pointer">
                      {{ item.sealNo }}
                    </div>
                  </template>
                  <template v-slot:[`item.nettWeight`]="{ item }">
                    <div v-if="item.nettWeight" style="cursor: pointer">
                      {{ item.nettWeight }} KG
                    </div>
                  </template>

                  <template v-slot:[`item.features`]="{ item }">
                    <v-tooltip top v-if="item.temporaryProducts.some(
    (product) => product.product === 'Genset'
  )
    ">
                      <template v-slot:activator="{ on }">
                        <v-btn icon outlined small class="mx-1" v-on="on" color="#c4c166"><v-icon
                            small>bolt</v-icon></v-btn>
                      </template>
                      <span>Genset Required</span>
                    </v-tooltip>

                    <!-- <v-tooltip
                      top
                      v-if="
                        item.temporaryProducts.some(
                          (product) => product.product === 'Port Monitoring'
                        )
                      "
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          outlined
                          small
                          class="mx-1"
                          v-on="on"
                          color="teal"
                          ><v-icon small>directions_boat</v-icon></v-btn
                        >
                      </template>
                      <span>Port Monitoring Required</span>
                    </v-tooltip> -->

                    <v-tooltip top v-if="item.temporaryProducts.some(
    (product) => product.product === 'Fumigation'
  )
    ">
                      <template v-slot:activator="{ on }">
                        <v-btn icon outlined small class="mx-1" v-on="on" color="teal"><v-icon
                            small>cleaning_services</v-icon></v-btn>
                      </template>
                      <span>Fumigations Required</span>
                    </v-tooltip>

                    <v-tooltip top v-if="item.temporaryProducts.some(
    (product) => product.product === 'Void Plugs'
  )
    ">
                      <template v-slot:activator="{ on }">
                        <v-btn icon outlined small class="mx-1" v-on="on" color="#e05b0d"><v-icon
                            small>power</v-icon></v-btn>
                      </template>
                      <span>Void Plugs</span>
                    </v-tooltip>

                    <v-tooltip top v-if="item.temporaryProducts.some(
    (product) => product.product === 'Blast Freezing'
  )
    ">
                      <template v-slot:activator="{ on }">
                        <v-btn icon outlined small class="mx-1" v-on="on" color="#13a7d4"><v-icon
                            small>ac_unit</v-icon></v-btn>
                      </template>
                      <span>Blast Freezing</span>
                    </v-tooltip>

                    <v-tooltip top v-if="item.temporaryProducts.some(
    (product) => product.product === 'Monitoring Service'
  )
    ">
                      <template v-slot:activator="{ on }">
                        <v-btn icon outlined small class="mx-1" v-on="on" color="#99B080"><v-icon
                            small>desktop_windows</v-icon></v-btn>
                      </template>
                      <span>
                        {{
    item.temporaryProducts.find(
      (product) =>
        product.product === "Monitoring Service"
    ).variation +
    " " +
    "Service" +
    " " +
    "Required"
  }}
                      </span>
                    </v-tooltip>
                    <v-tooltip top v-if="item.temporaryProducts.some(
      (product) => product.product === 'ContainerLiners'
    )
    ">
                      <template v-slot:activator="{ on }">
                        <v-btn icon outlined small class="mx-1" v-on="on" color="#EEF296"><v-icon
                            small>settings_overscan</v-icon></v-btn>
                      </template>
                      <span>
                        {{ "Container Liners" + " " + "Required" }}
                      </span>
                    </v-tooltip>
                    <v-tooltip top v-if="item.temporaryProducts.some(
    (product) => product.product === 'SteriInsurance'
  )
    ">
                      <template v-slot:activator="{ on }">
                        <v-btn icon outlined small class="mx-1" v-on="on" color="#EEF296"><v-icon
                            small>emergency</v-icon></v-btn>
                      </template>
                      <span>
                        {{ "Steri Insurance" + " " + "Required" }}
                      </span>
                    </v-tooltip>
                    <v-tooltip top v-if="item.temporaryProducts.some(
    (product) => product.product === 'SmartFresh'
  )
    ">
                      <template v-slot:activator="{ on }">
                        <v-btn icon outlined small class="mx-1" v-on="on" color="#FFBFFF"><v-icon
                            small>smart_screen</v-icon></v-btn>
                      </template>
                      <span>
                        {{ "Smart Fresh" + " " + "Required" }}
                      </span>
                    </v-tooltip>
                    <v-tooltip top v-if="item.temporaryProducts.some(
    (product) => product.product === 'TelemPlus'
  )
    ">
                      <template v-slot:activator="{ on }">
                        <v-btn icon outlined small class="mx-1" v-on="on" color="#18e00d"><v-icon
                            small>router</v-icon></v-btn>
                      </template>
                      <span>
                        Devices Required:
                        <template v-if="item.temporaryProducts.some(
    (product) => product.product === 'TelemPlus'
  )
    ">
                          <ul>
                            <li v-for="(
                                [variation, count], index
                              ) in Object.entries(
    item.temporaryProducts
      .filter(
        (product) => product.product === 'TelemPlus'
      )
      .reduce((acc, product) => {
        if (!acc[product.variation]) {
          acc[product.variation] = 0;
        }
        acc[product.variation] += product.count;
        return acc;
      }, {})
  )" :key="index">
                              {{ count }}X{{ variation }}
                            </li>
                          </ul>
                        </template>
                      </span>
                    </v-tooltip>

                    <v-tooltip top v-if="item.temporaryProducts.some( (product) => product.product === 'Otflow' ) ">
                      <template v-slot:activator="{ on }">
                        <v-btn icon outlined small class="mx-1" v-on="on" color="#6A9C89"><v-icon
                            small>air</v-icon></v-btn>
                      </template>
                      <span>Otflow Required</span>
                    </v-tooltip>

                    <v-tooltip top v-if="item.temporaryProducts.some( (product) => product.product === 'Zoono' ) ">
                      <template v-slot:activator="{ on }">
                        <v-btn icon outlined small class="mx-1" v-on="on" color="#5b93c7">
                          <v-icon small>sanitizer</v-icon>
                        </v-btn>
                      </template>
                      <span>Zoono Required</span>
                    </v-tooltip>

                    <v-tooltip top v-if="item.temporaryProducts.some(
    (product) => product.product === 'Dunnage Bags'
  )
    ">
                      <template v-slot:activator="{ on }">
                        <v-btn icon outlined small class="mx-1" v-on="on" color="#F1DBBF"><v-icon
                            small>shopping_bag</v-icon></v-btn>
                      </template>
                      <span>Dunnage Bags Required</span>
                    </v-tooltip>

                    <v-tooltip top v-if="item.temporaryProducts.some(
    (product) => product.product === 'Late Arrival'
  )
    ">
                      <template v-slot:activator="{ on }">
                        <v-btn icon outlined small class="mx-1" v-on="on" color="#de041a"><v-icon
                            small>watch_later</v-icon></v-btn>
                      </template>
                      <span>Late Arrival Required :
                        {{
    item.temporaryProducts.find(
      (product) => product.product === "Late Arrival"
    ).variation
  }}</span>
                    </v-tooltip>

                    <v-tooltip top v-if="item.temporaryProducts.some(
    (product) =>
      product.product === 'Early Arrival'
  )
    ">
                                <template v-slot:activator="{ on }">
                                  <v-btn icon outlined small class="mx-1" v-on="on" color="orange"><v-icon
                                      small>watch_later</v-icon></v-btn>
                                </template>
                                <span>Early Arrival Required :
                                  {{
    item.temporaryProducts.find(
      (product) =>
        product.product === "Early Arrival"
    ).variation
  }}</span>
                              </v-tooltip>
                    <v-tooltip top v-if="item.temporaryProducts.some(
      (product) => product.product === 'Latches'
    )
    ">
                      <template v-slot:activator="{ on }">
                        <v-btn icon outlined small class="mx-1" v-on="on" color="#508D69"><v-icon
                            small>pivot_table_chart</v-icon></v-btn>
                      </template>
                      <span>Latches Required</span>
                    </v-tooltip>

                    <v-tooltip top v-if="item.temporaryProducts.some(
    (product) => product.product === 'Ratches'
  )
    ">
                      <template v-slot:activator="{ on }">
                        <v-btn icon outlined small class="mx-1" v-on="on" color="#EA906C"><v-icon
                            small>settings_input_component</v-icon></v-btn>
                      </template>
                      <span>Ratches Required</span>
                    </v-tooltip>

                    <v-tooltip top v-if="item.temporaryProducts.some(
    (product) => product.product === 'Ethylene Filters'
  )
    ">
                      <template v-slot:activator="{ on }">
                        <v-btn icon outlined small class="mx-1" v-on="on" color="#EE12CA"><v-icon
                            small>filter_alt</v-icon></v-btn>
                      </template>
                      <span>Ethylene Filters Required</span>
                    </v-tooltip>

                    <v-tooltip top v-if="item.temporaryProducts.some(
    (product) => product.product === 'Stabling bars'
  )
    ">
                      <template v-slot:activator="{ on }">
                        <v-btn icon outlined small class="mx-1" v-on="on" color="#5F6F52"><v-icon
                            small>video_stable</v-icon></v-btn>
                      </template>
                      <span>Stabling bars Required</span>
                    </v-tooltip>

                    <v-tooltip top v-if="item.temporaryProducts.some(
    (product) => product.product === 'Kraft paper'
  )
    ">
                      <template v-slot:activator="{ on }">
                        <v-btn icon outlined small class="mx-1" v-on="on" color="#C5E898"><v-icon
                            small>note</v-icon></v-btn>
                      </template>
                      <span>Kraft paper Required</span>
                    </v-tooltip>

                    <v-tooltip top v-if="item.temporaryProducts.some(
    (product) => product.product === 'Absorb Bags'
  )
    ">
                      <template v-slot:activator="{ on }">
                        <v-btn icon outlined small class="mx-1" v-on="on" color="#DCBFFF"><v-icon
                            small>backpack</v-icon></v-btn>
                      </template>
                      <span>Absorb Bags Required</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:[`item.regimeCode`]="{ item }">
                    <div v-if="item">{{ booking.regimeCode }}</div>
                  </template>
                  <template v-slot:[`item.products`]="{ item }">
                    <v-row>
                      <v-col></v-col>
                      <v-chip outlined small style="border: none" class="ma-0" v-for="product in item.containerProducts"
                        :key="product.id">
                        {{
    $Format.capitalizeFirstLetter(product.product.name)
  }}</v-chip>
                    </v-row>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" class="pl-0">
              <v-card style="
                  background-color: var(--v-greyRaised-lighten2) !important;
                " flat height="45vh">
                <v-card-text style="height: 100%">
                  <v-row style="height: 100%" no-gutters>
                    <!-- LOADING POINTS -->
                    <v-col cols="12" sm="5" class="pb-0 pr-2">
                      <v-card class="pt-1" flat style="
                          background-color: var(
                            --v-toolbar-lighten2
                          ) !important;
                          contain: content;
                          height: 100%;
                        ">
                        <v-list dense class="pa-0">
                          <v-subheader style="font-size: 16px">
                            <v-icon class="mr-2" color="secondary">location_on</v-icon>
                            Loading Points
                          </v-subheader>
                          <v-divider></v-divider>
                          <v-list-item v-for="(
                              milestone, index
                            ) in availableMilestones.filter(
      (x) =>
        x.type == booking.movementType &&
        (x.value !== 'GASSING' ||
          (x.value === 'GASSING' &&
            !avoBox &&
            booking.caProduct))
    )" :key="index" @click="
    addMilestone(
      containerItem,
      milestone.value,
      false
    )
    ">
                            <v-list-item-action class="mr-0 pr-0">
                              <v-avatar size="10" v-if="bulkEdit[milestone.value]" :color="bulkEdit[milestone.value].isActual
    ? 'success'
    : 'secondary'
    "></v-avatar>
                              <v-icon v-else color="grey" small>warning</v-icon>
                            </v-list-item-action>
                            <v-list-item-content v-if="bulkEdit[milestone.value]">
                              <v-list-item-title style="font-size: 14px">{{
    bulkEdit[milestone.value].description
  }}</v-list-item-title>
                              <v-list-item-subtitle style="font-size: 12px">
                                {{ $Format.formatDate(bulkEdit[milestone.value].date).isoFormat }}
                                {{bulkEdit[milestone.value].time}}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-content v-else>
                              <v-list-item-subtitle style="font-size: 14px">{{
      milestone.name
    }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-card>
                    </v-col>
                    <v-col cols="12" sm="3" class="pb-0 px-2">
                      <!-- PRODUCTS -->
                      <v-list class="mt-2" dense subheader>
                        <span class="pl-3" style="font-size: 16px"><b>Products </b>
                        </span>
                        <v-btn x-small class="mb-1" color="primary" @click="productView = true"
                          icon><v-icon>add_circle_outline</v-icon></v-btn>
                        <v-list-item v-if="!bulkEdit.containerProducts">
                          <v-list-item-content class="text-center">
                            <span style="color: grey">No products listed</span>
                          </v-list-item-content>
                        </v-list-item>
                        <div v-if="bulkEdit.containerProducts" :key="productListKey">
                          <v-list-item v-for="cargo in bulkEdit.containerProducts" :key="cargo.index">
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ cargo.product.name }}
                              </v-list-item-title>
                              <v-list-item-subtitle class="text-wrap">
                                {{ cargo.hsCode }}
                              </v-list-item-subtitle>
                              <v-list-item-subtitle v-if="cargo.nettWeight" class="text-wrap">
                                NETT: {{ cargo.nettWeight }} Kg
                              </v-list-item-subtitle>
                              <v-list-item-subtitle v-if="cargo.grossWeight" class="text-wrap">
                                GROSS: {{ cargo.grossWeight }} Kg
                              </v-list-item-subtitle>
                              <v-list-item-subtitle v-if="cargo.volume" class="text-wrap">{{ cargo.volume }} CBM
                              </v-list-item-subtitle>
                              <v-list-item-subtitle v-if="cargo.quantity || cargo.pallets" class="text-wrap">
                                <span v-if="cargo.pallets">
                                  - {{ cargo.pallets }} Pallets
                                </span>
                                <span v-if="cargo.quantity">
                                  {{ cargo.quantity }} {{ cargo.quantityType }}
                                </span>
                              </v-list-item-subtitle>
                              <v-list-item-subtitle v-if="cargo.industrial || cargo.organic" class="text-wrap">
                                <v-chip small color="blue-grey" class="mx-1" v-if="cargo.industrial"><v-icon small
                                    left>factory</v-icon>
                                  Industrial Use</v-chip>
                                <v-chip small color="green" class="mx-1" v-if="cargo.industrial"><v-icon small
                                    left>spa</v-icon>
                                  Organic</v-chip>
                                <v-chip small color="black" class="white--text mx-1" v-if="cargo.hazardous"><v-icon
                                    color="orange" left>warning</v-icon>
                                  Hazardous</v-chip>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-row justify="center">
                                <v-btn icon small @click="deleteCargoItem(cargo)" color="red"><v-icon
                                    small>close</v-icon></v-btn>
                              </v-row>
                            </v-list-item-action>
                          </v-list-item>
                          <v-list-item v-if="bulkEdit.containerProducts.length == 0">
                            <v-list-item-content class="text-center">
                              <span style="font-size: 12px; color: grey">No products added</span>
                            </v-list-item-content>
                          </v-list-item>
                        </div>
                        <v-textarea v-model="bulkEdit.comment" label="Container Notes" outlined hide-details
                          rows="3"></v-textarea>
                      </v-list>
                      <v-btn style="
                          position: absolute;
                          left: 55%;
                          right: auto;
                          transform: translate(-55%, 0);
                          bottom: 1vh;
                        " :loading="savingBulkUpdate" color="tertiary" @click="bulkUpdateContainers">UPDATE</v-btn>
                    </v-col>
                    <v-col cols="12" sm="4" class="pb-0 pl-2">
                      <!-- STATUS CHANGE -->
                      <v-list style="max-height: 40vh; overflow-y: auto">
                        <v-list-item dense>
                          <v-list-item-action>
                            <v-icon>new_releases</v-icon>
                          </v-list-item-action>
                          <v-list-item-content class="text-wrap">
                            <v-list-item-title> Status </v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action class="my-1">
                            <v-menu offset-y style="min-width: 8vw" rounded :key="statusMenuKey">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" rounded small :color="containerStatusColor(bulkEdit)">
                                  {{ bulkEdit.status ?? "Status"
                                  }}<v-icon small>expand_more</v-icon>
                                </v-btn>
                              </template>
                              <v-list dense color="greyBase" rounded>
                                <v-list-item v-for="(status, index) in containerStatus" :key="index"
                                  @click="setBulkStatus(status.name)">
                                  <v-list-item-action>
                                    <v-avatar size="20" :color="status.color">
                                    </v-avatar>
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title>{{
    status.name
  }}</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-list-item-action>
                        </v-list-item>
                        <!-- Manage temporary products-->
                        <!-- <v-list-item dense>
                          <v-list-item-action>
                            <v-icon>category</v-icon>
                          </v-list-item-action>
                          <v-list-item-content class="text-wrap">
                            <v-list-item-title> Products & Services </v-list-item-title>
                            <v-list-item-subtitle> Per container</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>

                            <v-btn @click="getTemporaryProducts('Single')" rounded outlined small class="mx-1"
                              color="blue"><v-icon left>edit</v-icon> Edit</v-btn>
                          </v-list-item-action>
                        </v-list-item> -->

                        <v-list-item dense>
                          <v-list-item-action>
                            <v-icon>category</v-icon>
                          </v-list-item-action>
                          <v-list-item-content class="text-wrap">
                            <v-list-item-title>
                              Products & Services
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              Bulk Update</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn @click="getTemporaryProducts('Bulk')" rounded outlined small class="mx-1"
                              color="teal"><v-icon left>edit</v-icon> Edit</v-btn>
                          </v-list-item-action>
                        </v-list-item>
                        <!--LAR commented out -->
                        <!-- <v-list-item dense>
                          <v-list-item-action>
                            <v-icon>description</v-icon>
                          </v-list-item-action>
                          <v-list-item-content class="text-wrap">
                            <v-list-item-title>
                              LAR
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              Bulk generate LAR
                            </v-list-item-subtitle>
                          </v-list-item-content>

                            <v-list-item-action>
                            <v-btn @click="bulkGenerateLAR()" rounded outlined small class="mx-1"
                              color="teal"> Generate</v-btn>
                          </v-list-item-action>
                        </v-list-item>   -->

                        <!-- GENSET REQUIRED -->
                        <!-- <v-list-item dense>
                          <v-list-item-action>
                            <v-icon>bolt</v-icon>
                          </v-list-item-action>
                          <v-list-item-content class="text-wrap">
                            <v-list-item-title>
                              Genset Required
                            </v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn-toggle :key="gensetToggleKey">
                              <v-btn x-small @click="toggleButton('NO', 'gensetRequired')" :color="bulkEdit.gensetRequired === false
                                ? 'primary'
                                : 'grey'
                                ">
                                NO
                              </v-btn>
                              <v-btn x-small @click="toggleButton('YES', 'gensetRequired')" :color="bulkEdit.gensetRequired === true
                                ? 'tertiary'
                                : 'grey'
                                ">
                                YES
                              </v-btn>
                            </v-btn-toggle>
                          </v-list-item-action>
                        </v-list-item> -->
                        <!-- CONTROLLED ATMOSPHERE -->

                        <!-- <v-list-item dense>
                          <v-list-item-action>
                            <v-icon>router</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title class="text-wrap">
                              Temperature Device
                            </v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn-toggle :key="sensorDeviceKey">
                              <v-btn x-small @click="toggleButton('NO', 'sensorDevice')" :color="bulkEdit.sensorDevice === false
                                ? 'primary'
                                : 'grey'
                                ">
                                NO
                              </v-btn>
                              <v-btn x-small @click="toggleButton('YES', 'sensorDevice')" :color="bulkEdit.sensorDevice === true
                                ? 'tertiary'
                                : 'grey'
                                ">
                                YES
                              </v-btn>
                            </v-btn-toggle>
                          </v-list-item-action>
                        </v-list-item> -->
                        <v-row no-gutters align="center">
                          <!-- MONITORING AND METHOD -->
                          <v-col cols="12" sm="6" class="pr-1">
                            <v-select v-if="bulkEdit.sensorDevice" label="Monitoring Service"
                              placeholder="Select a Monitoring Service" persistent-placeholder hide-details outlined
                              dense class="py-2" :items="[
    'TempCheck',
    'TempCheckPLUS',
    'Fumigation',
  ]" v-model="bulkEdit.sensorService"></v-select>
                          </v-col>
                          <!-- TEMP DEVICE AND REGIME CODE -->
                          <v-col cols="12" sm="6" class="pl-1">
                            <v-select v-if="bulkEdit.sensorDevice" label="Temperature Device Type"
                              placeholder="Select a Temperature Device" persistent-placeholder hide-details outlined
                              dense class="py-2" :items="[
    'RF TEMPTALE',
    'T4 TEMPTALE',
    'GEO EAGLE TEMPTALE',
    'MOST TEMPTALE',
    '2G GEO TEMPTALE',
    '3G GEO TEMPTALE',
    '4G GEO TEMPTALE',
    '2G TIVE',
    '5G TIVE',
  ]" v-model="bulkEdit.sensorDeviceType"></v-select>
                            <!-- <v-autocomplete label="Regime Code" placeholder="Select a Regime Code"
                                                        persistent-placeholder outlined dense class="pb-1 pt-2"
                                                        :disabled="temporaryDisable" v-model="bulkEdit.regimeCode"
                                                        :hint="temporaryDisable ? 'Cannot Change Regime on Intra Booking' : bulkEdit.regimeCode ? bulkEdit.regimeCode.recipeDescription : ''"
                                                        persistent-hint return-object :items="regimeCodes" item-text="name"
                                                        item-value="code">
                                                        <template v-slot:selection="data">
                                                            <span v-if="data.item">
                                                                {{ data.item.code }}
                                                            </span>
                                                        </template>
                                                        <template v-slot:item="data">
                                                            <v-list-item-content>
                                                                <v-list-item-title>{{ data.item.code }}</v-list-item-title>
                                                                <v-list-item-subtitle class="text-wrap">{{
                                                                    data.item.recipeDescription
                                                                }}
                                                                </v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </template>
                                                    </v-autocomplete> -->
                          </v-col>
                          <v-col cols="12" sm="6" class="pr-1">
                            <v-select label="Method" placeholder="Select a Method" persistent-placeholder hide-details
                              outlined clearable dense class="py-2" :items="['Method 1', 'Method 2']"
                              v-model="bulkEdit.verificationMethod"></v-select>
                          </v-col>
                          <v-col cols="12" sm="6" class="pr-1">
                            <v-text-field label="Customer Ref" class="py-2" v-model="bulkEdit.customerContainerRef"
                              outlined dense hide-details clearable></v-text-field>
                          </v-col>
                          <!-- <v-col v-if="bulkEdit.verificationMethod == 'Method 1'" cols="12"
                                                        sm="6">
                                                        <v-select label="Weighbridge Contract Owner"
                                                            placeholder="Select a Party" persistent-placeholder hide-details
                                                            outlined dense class="py-2" :items="['Shipper', 'Forwarder']"
                                                            v-model="bulkEdit.verificationMethodOwner"></v-select>
                                                    </v-col>
                                                    <v-col v-if="bulkEdit.verificationMethod == 'Method 1'" cols="12"
                                                        sm="6">
                                                        <v-autocomplete v-model="bulkEdit.weighBridgeId"
                                                            prepend-inner-icon="location_on"
                                                            placeholder="Weighbridge Location" persistent-placeholder
                                                            hide-details :items="weighBridges" dense outlined clearable
                                                            :loading="loadingWeighBridges" item-text="name" item-value="id">
                                                        </v-autocomplete>
                                                    </v-col> -->
                        </v-row>
                        <!-- COMMENTS -->
                      </v-list>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!------------------------------------------ Products Modal ------------------------------------------>
    <v-dialog v-model="productsModal" fullscreen>
      <TemporaryProductsModal @close="productsModal = false" :products="temporaryProducts"
        :deviceOptions="deviceOptions" />
    </v-dialog>
    <!------------------------------------------ POI MODAL DENSE ------------------------------------------>
    <v-dialog v-model="poiModalDense" width="480px">
      <v-card :loading="loadingFunctions">
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            <span v-if="loadingPointItem.type == 'emptyLoadingPoint'">Add Empty Loading Point</span>
            <span v-else-if="loadingPointItem.type == 'firstLoadingPoint'">Add 1st Loading Point</span>
            <span v-else-if="loadingPointItem.type == 'secondLoadingPoint'">Add 2nd Loading Point</span>
            <span v-else-if="loadingPointItem.type == 'dropOffPoint'">Add Drop Off Point</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu transition="slide-y-transition" offset-y style="min-width: 7vw" rounded="xl"
            :close-on-content-click="false">
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip top>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn icon v-bind="attrs" v-on="{ ...menu }">
                    <v-icon v-on="{ ...tooltip }">filter_alt</v-icon>
                  </v-btn>
                </template>
                <span>Filter Functions</span>
              </v-tooltip>
            </template>
            <v-toolbar flat dense color="greyBase">Available Functions</v-toolbar>
            <v-list dense color="greyBase" rounded class="px-0 pt-0">
              <v-list-item v-for="(item, index) in poiFunctions" :key="index">
                <v-chip @click="
    (item.selected = !item.selected), searchPois(poiSearch)
    " dense :outlined="!item.selected" :color="item.selected ? 'tertiary' : ''" :filled="item.selected">{{ item.name
                  }}</v-chip>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn icon @click="poiModalDense = false" color="red">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-chip-group column>
            <v-chip @click="(item.selected = !item.selected), searchPois(poiSearch)" v-for="(item, index) in poiFunctions.filter(
    (func) => func.selected
  )" :key="index" filled dense small color="tertiary">{{ item.name }}</v-chip>
          </v-chip-group>
          <v-text-field hide-details outlined dense clearable v-model="poiSearch" placeholder="Search"
            prepend-inner-icon="search"></v-text-field>
          <v-list dense style="max-height: 45vh; overflow-y: auto">
            <v-progress-linear v-if="poiSearchLoading" color="primary" indeterminate></v-progress-linear>
            <v-list-item v-for="point in pointsOfInterest" :key="point.id" @click="setLoadingPoint(point)">
              <v-list-item-content>
                <v-list-item-title>{{ point.name }}</v-list-item-title>
                <v-list-item-subtitle style="font-size: 12px" class="text-wrap">
                  {{ point.address }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="point.poiFunctions && point.poiFunctions.length > 0">
                  <v-chip class="mx-1" x-small v-for="functionality in point.poiFunctions" :key="functionality.id">{{
    functionality.functionality }}</v-chip>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!------------------------------------------ VIEW CONTAINER CONTENTS AND DETAILS ------------------------------------------>
    <v-dialog v-model="viewContainerModal" fullscreen>
      <ViewContainer @close="(viewContainerModal = false), (selectedContainer = null)"
        @refreshContainers="getContainers()" :booking="booking" :container="selectedContainer"
        :containerTypes="containerTypes" :modalOpen="viewContainerModal" />
    </v-dialog>

    <v-dialog v-model="fileCreateDialog" persistent width="400px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-card-text class="text-center mt-3 pt-5">
          <p style="font-size: 14px">{{ message }}</p>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn color="red" class="mx-2" outlined small @click="statusConfirmationDialog = false">No</v-btn>
            <v-btn color="success" class="mx-2" outlined small @click="processStatusChange()">Yes</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Party search modal -->
    <v-dialog v-model="partyModal" width="400px" :fullscreen="$vuetify.breakpoint.mobile" persistent>
      <v-card :loading="loadingLinkedOrganisations || savingParty">
        <v-toolbar flat color="transparent">

          <div style="width:100%;display:flex;flex-direction:row;justify-content: space-between;align-items: baseline;">
            <v-toolbar-title> Manage {{ partyType }} </v-toolbar-title>
            <div>
              <v-tooltip top>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn text @click="removeBuyer({ partyType })" v-on="{ ...tooltip }">
                    <v-icon color="red">delete</v-icon>
                  </v-btn>

                </template>
                <span>Remove {{ partyType }}</span>
              </v-tooltip>
              <v-btn text @click="(partyModal = false), (searchOrganisation = null)">X</v-btn>
            </div>
          </div>

        </v-toolbar>
        <v-card-text>
          <v-text-field outlined dense placeholder="Search" prepend-inner-icon="search" v-model="searchOrganisation"
            clearable></v-text-field>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item v-for="organisation in filterCustomerOrganisations" :key="organisation.id"
              @click="setParty(organisation.relatedOrganisation)">
              <v-list-item-action>
                <v-avatar v-if="organisation.relatedOrganisation.logo" color="white">
                  <v-img :src="organisation.relatedOrganisation.logo" contain></v-img>
                </v-avatar>
                <v-avatar v-else-if="organisation.relatedOrganisation.name" color="secondary">
                  <h3>{{ organisation.relatedOrganisation.name.charAt(0) }}</h3>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ organisation.relatedOrganisation.name }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="organisation.relatedOrganisation.alias">
                  {{ organisation.relatedOrganisation.alias }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <transition name="scroll-y-reverse-transition">
      <v-card v-if="changesMade" style="
          position: fixed;
          bottom: 2vh;
          height: 8vh;
          width: 22vw;
          left: 50%;
          transform: translate(-50%, 0);
          background-color: var(--v-greyBase-lighten1) !important;
          display: grid;
          justify-content: center;
          align-items: end;
          border-radius: 16px;
        ">
        <div style="font-size: 12px; display: flex; place-content: center">
          {{
    deletedCount > 1
      ? `Delete ${deletedCount} Containers`
      : deletedCount > 0
        ? `Delete ${deletedCount}
          Container`
        : ""
  }}
          {{ deletedCount > 0 && addedCount > 0 ? " , " : "" }}
          {{
    addedCount > 1
      ? `Add ${addedCount} Containers`
      : addedCount > 0
        ? `Add ${addedCount} Container`
        : ""
  }}
          {{
      (deletedCount > 0 || addedCount > 0) && reinstatedCount > 0
        ? " , "
        : ""
    }}
          {{
      reinstatedCount > 1
        ? `Reinstate ${reinstatedCount} Containers`
        : reinstatedCount > 0
          ? `Reinstate
          ${reinstatedCount} Container`
          : ""
    }}
        </div>
        <v-btn small rounded :loading="loadingChanges" @click="saveContainerChanges" class="mb-2" color="tertiary">SAVE
          CHANGES</v-btn>
      </v-card>
    </transition>

    <!-- Create file Dialog -->
    <v-dialog v-model="createFileDialog" :width="fileTab == 0 ? '400px' : '95vw'" persistent
      :fullscreen="$vuetify.breakpoint.mobile">
      <v-card v-if="booking &&
    booking.bookingContainers &&
    booking.bookingContainers.length > 0
    ">
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Create File</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text color="blue" v-if="fileTab == 2" :disabled="((this.booking.type == 'LOGISTICS' || this.booking.type == 'DOCUMENTATION') &&
      !this.file.incoTermId) || (this.booking.contractPartyId !== this.file.contractPartyId)
    " @click="(confirmFileCreate = true), (createFileDialog = false)"><v-icon left small>save</v-icon> Create
            File</v-btn>
          <v-btn text @click="createFileDialog = false">X</v-btn>
        </v-toolbar>
        <v-card-text v-if="fileTab == 0">
          <!-- <span>Select containers for file:</span> -->
          <v-row align="center" justify="center">
            <v-col cols="6" class="text-center">
              <v-btn small outlined color="green" @click="selectAllFileContainers(filterContainers)">Select All</v-btn>
            </v-col>
            <v-col cols="6" class="text-center">
              <v-btn small outlined color="red" @click="deselectAllContainers()">Deselect All</v-btn>
            </v-col>
          </v-row>
          <v-text-field class="mt-2" placeholder="Search" outlined dense clearable prepend-inner-icon="search"
            v-model="searchContainer" hide-details></v-text-field>
          <v-list dense :key="containerKey" style="max-height: 50vh; overflow-y: auto">
            <v-list-item v-for="container in filterContainers" :key="container.id" @click="addToFile(container.id)">
              <v-list-item-content>
                <v-list-item-title>{{ container.ctoNo }}</v-list-item-title>
                <v-list-item-subtitle v-if="container.containerNo">{{
    container.containerNo
  }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="container.sealNo">Seal No: {{ container.sealNo }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="container.buyer"><v-icon small color="grey"
                    class="mr-2">shopping_cart</v-icon>
                  <span v-if="container.buyer.alias">{{
    container.buyer.alias
  }}</span><span v-else>{{
      container.buyer.name
    }}</span></v-list-item-subtitle>
                <v-list-item-subtitle v-if="container.consignee"><v-icon small color="grey"
                    class="mr-2">call_received</v-icon>
                  <span v-if="container.consignee.alias">{{
    container.consignee.alias
  }}</span><span v-else>{{
      container.consignee.name
    }}</span></v-list-item-subtitle>
                <v-list-item-subtitle v-if="container.shipmentFile">
                  <v-icon small class="pb-1 mr-1" color="grey">description</v-icon>
                  {{ container.shipmentFile.fileNumber }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="container.customerContainerRef">
                  <v-icon small class="pb-1 mr-1" color="grey">qr_code_2</v-icon>
                  {{ container.customerContainerRef }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon v-if="selectedContainers.includes(container.id)" color="success">check_circle_outline</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-list-item v-if="filterContainers.length == 0">
              <v-list-item-content class="text-center">
                <span style="font-size: 14px; color: grey">No containers found</span>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-row justify="center" class="my-2" v-if="selectedContainers.length > 0">
            <v-btn text color="primary" @click="containerSelectionComplete()">Next</v-btn>
          </v-row>
        </v-card-text>
        <v-card-text v-else-if="fileTab == 1">
          <v-row justify="end">
            <v-col cols="12" sm="4">
              <v-text-field placeholder="Search" autofocus prepend-inner-icon="search" v-model="searchProfiles" outlined
                dense clearable hide-details></v-text-field>
            </v-col>
          </v-row>
          <v-data-table id="profile-info" style="cursor: pointer; font-size: 12px" :items="filteredProfiles" dense
            :loading="loadingShipmentProfiles" :headers="shipmentProfileHeaders" fixed-header :search="searchProfiles"
            height="70vh" @click:row="setFileProfile">
            <template v-slot:[`item.systemReference`]="{ item }">
              <b>{{ item.systemReference }}</b>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip v-if="item.status == 'Draft'" color="blue" class="white--text" small>Draft</v-chip>
              <v-chip v-else-if="item.status == 'Approved'" color="success" class="white--text" small>Approved</v-chip>
            </template>
            <template v-slot:[`item.shipper.name`]="{ item }">
              <span v-if="item.shipper">{{ item.shipper.name }}</span>
              <span>
                <span v-if="item.onBehalfShipper">
                  <br />
                  On behalf of {{ item.onBehalfShipper.name }}</span>
              </span>
            </template>
            <template v-slot:[`item.forwarder.name`]="{ item }">
              <span v-if="item.forwarder">{{ item.forwarder.name }}</span>
              <span>
                <span v-if="item.onBehalfForwarder">
                  <br />
                  On behalf of {{ item.onBehalfForwarder.name }}</span>
              </span>
            </template>
            <template v-slot:[`item.consignee.name`]="{ item }">
              <span v-if="item.consignee">{{ item.consignee.name }}</span>
              <span>
                <span v-if="item.onBehalfConsignee">
                  <br />
                  On behalf of {{ item.onBehalfConsignee.name }}</span>
              </span>
            </template>
            <template v-slot:[`item.buyer.name`]="{ item }">
              <span v-if="item.buyer">{{ item.buyer.name }}</span>
              <span>
                <span v-if="item.onBehalfBuyer">
                  <br />
                  On behalf of {{ item.onBehalfBuyer.name }}</span>
              </span>
            </template>
            <template v-slot:[`item.loadingPorts`]="{ item }">
              <v-chip color="success" pill outlined small v-if="item.allOriginPorts">
                <v-icon small left>check</v-icon> All Ports</v-chip>
              <div v-else>
                <span v-for="(port, i) in item.consigneeProfilePorts.filter(
    (x) => x.type == 'loading'
  )" :key="port.id">
                  <span v-if="i > 0">, </span>{{ port.name }}</span>
              </div>
            </template>
            <template v-slot:[`item.dischargePorts`]="{ item }">
              <v-chip color="success" pill outlined small v-if="item.allDestinationPorts">
                <v-icon small left>check</v-icon> All Ports</v-chip>
              <div v-else>
                <span v-for="(port, i) in item.consigneeProfilePorts.filter(
    (x) => x.type == 'discharge'
  )" :key="port.id">
                  <span v-if="i > 0">, </span>{{ port.name }}</span>
              </div>
            </template>
            <template v-slot:[`item.originCountry.name`]="{ item }">
              <v-tooltip top v-if="item.originCountry">
                <template v-slot:activator="{ on }">
                  <v-avatar size="32" v-on="on">
                    <v-img contain v-if="item.originCountry && item.originCountry.iso2"
                      :src="`https://cdn.loglive.io/flags/4x3/${item.originCountry.iso2.toLowerCase()}.svg`"></v-img>
                  </v-avatar>
                </template>
                <span style="font-size: 12px">{{
    item.originCountry.name
  }}</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.documentationPOD`]="{ item }">
              <template v-if="item.consigneeProfilePorts.length > 0">
                {{ item.consigneeProfilePorts[0].name }}
              </template>
              <template v-else>
                {{ "-" }}
              </template>
            </template>

            <template v-slot:[`item.destinationCountry.name`]="{ item }">
              <v-tooltip top v-if="booking.destinationCountry">
                <template v-slot:activator="{ on }">
                  <v-avatar size="32" v-on="on">
                    <v-img contain v-if="item.destinationCountry && item.destinationCountry.iso2
    " :src="`https://cdn.loglive.io/flags/4x3/${item.destinationCountry.iso2.toLowerCase()}.svg`"></v-img>
                  </v-avatar>
                </template>
                <span style="font-size: 12px">{{
    item.destinationCountry.name
  }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.incoTerm`]="{ item }">
              <v-chip small outlined class="mt-1" v-for="term in item.consigneeProfileIncoTerms" :key="term.id">{{
    term.incoTerm }}</v-chip>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-text v-else>
          <v-row>
            <v-col cols="12" sm="4" v-if="fileTab == 2">
              <v-list dense>
                <v-subheader style="font-size: 16px"><v-icon color="secondary" class="mr-2">widgets</v-icon>
                  Containers</v-subheader>
                <v-divider></v-divider>
                <v-list-item v-for="container in selectedContainers" :key="container.id">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ container.ctoNo }}
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="container.containerNo">
                      {{ container.containerNo }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="container.sealNo">
                      {{ container.sealNo }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <!-- <v-list-item-action>
                                        <v-btn icon color="red" v-if="!container.removed" @click="changeContainer">
                                            <v-icon>cancel</v-icon> </v-btn>
                                        <v-btn icon color="blue" v-else @click="container.removed = false">
                                            <v-icon>add_circle_outline</v-icon> </v-btn>

                                    </v-list-item-action> -->
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="4" v-if="file.consigneeProfile">
              <v-list dense>
                <v-subheader style="font-size: 16px"><v-icon color="secondary" class="mr-2">groups</v-icon>
                  Shipment Profile</v-subheader>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="secondary">handshake</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ file.consigneeProfile.systemReference }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-chip @click="fileTab = 1" small>
                      Change Profile <v-icon right small>refresh</v-icon>
                    </v-chip>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item v-if="file.consigneeProfile.shipper">
                  <v-list-item-action>
                    <v-icon color="secondary">directions_boat</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ file.consigneeProfile.shipper.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Shipper</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="file.consigneeProfile.consignee">
                  <v-list-item-action>
                    <v-icon color="secondary">call_received</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ file.consigneeProfile.consignee.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Consignee</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="file.consigneeProfile.originCountry">
                  <v-list-item-action>
                    <v-avatar size="32">
                      <v-img contain v-if="file.consigneeProfile.originCountry &&
    file.consigneeProfile.originCountry.iso2
    " :src="`https://cdn.loglive.io/flags/4x3/${file.consigneeProfile.originCountry.iso2.toLowerCase()}.svg`"></v-img>
                    </v-avatar>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ file.consigneeProfile.originCountry.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Origin Country</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="file.consigneeProfile.destinationCountry">
                  <v-list-item-action>
                    <v-avatar size="32">
                      <v-img contain v-if="file.consigneeProfile.destinationCountry &&
    file.consigneeProfile.destinationCountry.iso2
    " :src="`https://cdn.loglive.io/flags/4x3/${file.consigneeProfile.destinationCountry.iso2.toLowerCase()}.svg`"></v-img>
                    </v-avatar>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ file.consigneeProfile.destinationCountry.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Destination Country</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="4" v-if="file && file.consigneeProfile">
              <v-list dense>
                <v-subheader style="font-size: 16px"><v-icon color="secondary" class="mr-2">groups</v-icon> Deal
                  term <v-spacer></v-spacer>
                  <v-chip v-if="booking.incoTerm" small>Current Setting: {{ booking.incoTerm }}</v-chip>
                </v-subheader>
                <v-divider></v-divider>
                <v-list-item v-for="term in file.consigneeProfile.consigneeProfileIncoTerms
  " :disabled="term.contractPartyId !== booking.contractPartyId" :key="term.id" @click="setProfileIncoterm(term)">
                  <v-list-item-action>
                    <v-chip>{{ term.incoTerm }}</v-chip>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-subtitle style="text-transform: capitalize">
                      Freight Charge: {{ term.freightCharge }} -
                      {{ term.paymentTerm }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle style="text-transform: capitalize">
                      Origin Charge: {{ term.originCharge }} -
                      {{ term.originTerm }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle style="text-transform: capitalize">
                      Destination Charge: {{ term.destinationCharge }} -
                      {{ term.destinationTerm }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle style="text-transform: capitalize">
                      Contract Owner: {{ term.contractOwner }} </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action :key="incoTermKey">
                    <v-icon v-if="file.incoTermId && term.id === file.incoTermId && term.contractPartyId === booking.contractPartyId"
                      color="success">check_circle_outline</v-icon>
                      <v-tooltip top  v-else-if="term.contractPartyId !== booking.contractPartyId">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on"
                      color="amber">warning</v-icon>
                      </template>
                      <span>Contract Party does not match</span>
                    </v-tooltip>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Create file Dialog -->
    <v-dialog v-model="confirmFileCreate" width="400px" persistent :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar dense flat color="transparent">
          <v-spacer></v-spacer>
          <v-btn text @click="confirmFileCreate = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <p style="font-size: 16px">
            Are you sure you would like to create a file with the selected
            configuration?
          </p>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn color="red" class="mx-2" text small
              @click="(confirmFileCreate = false), (createFileDialog = true)">No</v-btn>
            <v-btn color="success" class="mx-2" text small @click="createBookingFile()"
              :loading="loadingCreateFile">Yes</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Create file Dialog -->
    <v-dialog v-model="confirmCancel" width="400px" persistent :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar dense flat color="transparent">
          <v-spacer></v-spacer>
          <v-btn text @click="confirmCancel = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <p style="font-size: 16px">
            Are you sure you would like to cancel this booking?
          </p>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn color="red" class="mx-2" text small @click="confirmCancel = false">No</v-btn>
            <v-btn color="success" class="mx-2" text small @click="cancelBooking()" :loading="loadingCancel">Yes</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Create file Dialog -->
    <v-dialog v-model="confirmContainerCancel" width="400px"  persistent :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar dense flat color="transparent">
          <v-spacer></v-spacer>
          <v-btn text @click="confirmContainerCancel = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <p style="font-size: 16px">
            Are you sure you would like to cancel
            <span v-if="containerRemovalType == 'single'">
              this container?</span>
            <span v-else> these containers?</span>
          </p>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn color="red" class="mx-2" text small @click="confirmContainerCancel = false">No</v-btn>
            <v-btn v-if="containerRemovalType == 'single'" color="success" class="mx-2" text small
              @click="deleteContainer(container)" :loading="loadingCancel">Yes</v-btn>
            <v-btn v-else-if="containerRemovalType == 'multiple'" color="success" class="mx-2" text small
              @click="deleteMultipleContainers()" :loading="loadingCancel">Yes</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="fileModal" width="500px" persistent :fullscreen="$vuetify.breakpoint.mobile">
      <v-card :loading="loadingFiles" v-if="fileItem">
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Link to File </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="fileModal = false">X</v-btn>
        </v-toolbar>
        <v-card-subtitle v-if="fileItem.ctoNo">
          <v-icon class="mr-1 pb-1" color="grey">widgets</v-icon> CTO No:
          {{ fileItem.ctoNo }}
        </v-card-subtitle>
        <v-card-subtitle v-if="fileItem.shipmentFile">
          <v-icon class="mr-1 pb-1" color="grey">description</v-icon> Current
          File: {{ fileItem.shipmentFile.fileNumber }}
        </v-card-subtitle>
        <v-card-text>
          <v-text-field placeholder="Search" v-model="searchFile" outlined dense clearable hide-details></v-text-field>
          <v-list style="max-height: 50vh; overflow-y: auto">
            <v-list-item v-if="filterAvailableFiles.length == 0">
              <v-list-item-content class="text-center">
                <span style="color: grey; font-size: 12px">No files found</span>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-for="file in filterAvailableFiles" :key="file.id" @click="
    fileItem.type == 'single'
      ? setContainerFile(file)
      : setContainerMultipleFile(file)
    " style="border-bottom: 0.5px solid grey">
              <v-list-item-content>
                <v-list-item-title>{{ file.fileNumber }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="createFileError" width="400px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-card-text class="text-left mt-3 pt-3">
          <p style="font-size: 16px">
            Please complete the following before continuing:
          </p>
          <ul>
            <!-- <li v-if="!booking.shipper">Select a Shipper</li>
                        <li v-if="!booking.consignee">Select a Consignee</li> -->
            <li v-if="!booking.regimeCode">Set Regime Code</li>
            <li v-if="!booking.originCountry">Specify an Origin Country</li>
            <li v-if="!booking.destinationCountry">
              Specify a Destination Country
            </li>
            <li v-if="!booking.portOfLoadValue">Port of Load (POL)</li>
            <li v-if="!booking.portOfDischargeValue">
              Port of Discharge (POD)
            </li>
            <li v-if="!booking.finalDestinationValue">Set Final Destination</li>
            <li v-if="!booking.vessel">Select a Vessel</li>
            <li v-if="!booking.mainCarriageConveyanceNumber">Set Voyage No.</li>
            <li v-if="!booking.contractNumber">Specify a Contract No.</li>
            <li v-if="!booking.etd">Specify a Departure Date</li>
            <li v-if="!booking.eta">Specify an Arrival Date</li>
            <li v-if="!booking.carrierReferenceNumber">
              Specify a Carrier Reference Number
            </li>

            <!-- <li v-if="!booking.incoTerm">Specify an Inco Term</li> -->
          </ul>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn color="blue" class="mx-2" text small @click="createFileError = false">Okay</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="moveContainerModal" width="600px" :fullscreen="$vuetify.breakpoint.mobile" persistent>
      <v-card :loading="loadingCustomerBooking">
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            <v-btn class="mr-2" icon @click="
    (moveContainerModal = false), (bookingSplitTypeModal = true)
    ">
              <v-icon>arrow_back</v-icon>
            </v-btn>
            Move Container
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="moveContainerModal = false">X</v-btn>
        </v-toolbar>
        <v-card-subtitle v-if="container"><v-icon class="mr-1 pb-1" color="grey"> widgets</v-icon>
          {{ container.ctoNo }}</v-card-subtitle>
        <v-card-text>
          <v-text-field placeholder="Search" v-model="searchBooking" outlined dense clearable
            hide-details></v-text-field>
          <v-list style="max-height: 50vh; overflow-y: auto" dense>
            <v-list-item v-if="filterAvailableBookings.length == 0">
              <v-list-item-content class="text-center">
                <span style="color: grey; font-size: 12px">No bookings found</span>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-for="item in filterAvailableBookings" :key="item.id" @click="moveContainerConfirm(item)">
              <v-list-item-content>
                <v-list-item-title>{{ item.orderNo }}</v-list-item-title>
                <v-list-item-subtitle v-if="item.shipper">
                  <v-icon class="mr-1 pb-1" color="grey" small>groups</v-icon>
                  {{ item.shipper.name }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="item.consignee">
                  <v-icon class="mr-1 pb-1" color="grey" small>call_received</v-icon>
                  {{ item.consignee.name }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="item.vessel">
                  <v-icon class="mr-1 pb-1" color="grey" small>directions_boat</v-icon>
                  {{ item.vessel.name }}
                  {{ item.mainCarriageConveyanceNumber }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="item.portOfLoadValue && item.finalDestinationValue">
                  <v-chip pill small v-if="item.portOfLoadValue">
                    <v-avatar size="14" left>
                      <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${item.portOfLoadValue
    .substring(0, 2)
    .toLowerCase()}.svg`"></v-img>
                    </v-avatar>
                    {{ item.portOfLoadValue }}
                  </v-chip>
                  <v-chip pill small v-if="item.finalDestinationValue" class="mx-1">
                    <v-avatar size="14" left>
                      <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${item.finalDestinationValue
    .substring(0, 2)
    .toLowerCase()}.svg`"></v-img>
                    </v-avatar>
                    {{ item.finalDestinationValue }}
                  </v-chip>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-chip>{{ item.numContainers }}</v-chip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="bookingMoveError" width="400px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-card-text class="text-left mt-3 pt-3">
          <p style="font-size: 16px">
            Please select make sure to complete the following before continuing:
          </p>
          <ul>
            <li v-if="!booking.shipper">Select a Customer</li>
          </ul>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn color="blue" class="mx-2" text small @click="bookingMoveError = false">Okay</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Move container Dialog -->
    <v-dialog v-model="bookingMoveConfirm" width="600px" persistent :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <!-- <v-toolbar dense flat color="transparent">
                    <v-spacer></v-spacer>
                    <v-btn text @click="bookingMoveConfirm = false">X</v-btn>
                </v-toolbar> -->
        <v-card-text>
          <v-list-item v-if="moveItem">
            <v-list-item-content>
              <v-list-item-title>{{ moveItem.orderNo }}</v-list-item-title>
              <v-list-item-subtitle v-if="moveItem.shipper">
                <v-icon class="mr-1 pb-1" color="grey" small>groups</v-icon>
                {{ moveItem.shipper.name }}</v-list-item-subtitle>
              <v-list-item-subtitle v-if="moveItem.consignee">
                <v-icon class="mr-1 pb-1" color="grey" small>call_received</v-icon>
                {{ moveItem.consignee.name }}</v-list-item-subtitle>
              <v-list-item-subtitle v-if="item.vessel">
                <v-icon class="mr-1 pb-1" color="grey" small>directions_boat</v-icon>
                {{ moveItem.vessel.name }}
                {{
    moveItem.mainCarriageConveyanceNumber
  }}</v-list-item-subtitle>
              <v-list-item-subtitle v-if="moveItem.portOfLoadValue && moveItem.finalDestinationValue
    ">
                <v-chip pill small v-if="moveItem.portOfLoadValue">
                  <v-avatar size="14" left>
                    <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${moveItem.portOfLoadValue
    .substring(0, 2)
    .toLowerCase()}.svg`"></v-img>
                  </v-avatar>
                  {{ moveItem.portOfLoadValue }}
                </v-chip>
                <v-chip pill small v-if="moveItem.finalDestinationValue" class="mx-1">
                  <v-avatar size="14" left>
                    <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${moveItem.finalDestinationValue
    .substring(0, 2)
    .toLowerCase()}.svg`"></v-img>
                  </v-avatar>
                  {{ moveItem.finalDestinationValue }}
                </v-chip>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-chip>{{ moveItem.numContainers }}</v-chip>
            </v-list-item-action>
          </v-list-item>
          <p style="font-size: 16px">
            Are you sure you would like to move
            <span v-if="moveContainerType == 'single'"> this container?</span>
            <span v-else> the selected containers?</span>
          </p>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn color="red" class="mx-2" text small @click="bookingMoveConfirm = false">No</v-btn>
            <v-btn v-if="moveContainerType == 'single'" color="success" class="mx-2" text small
              @click="moveSingleContainer(moveItem)" :loading="loadingCustomerBooking">Yes</v-btn>
            <v-btn v-else-if="moveContainerType == 'multiple'" color="success" class="mx-2" text small
              @click="moveMultipleContainer(moveItem)" :loading="loadingCustomerBooking">Yes</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Move container Dialog -->
    <v-dialog v-model="bookingCreateConfirm" width="600px" persistent :fullscreen="$vuetify.breakpoint.mobile">
      <v-card :loading="creatingNewBooking">
        <v-toolbar flat color="transparent">
          <v-btn icon @click="
    (bookingCreateConfirm = false), (bookingSplitTypeModal = true)
    ">
            <v-icon>arrow_back</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <p style="font-size: 16px">
            Are you sure you would like to create a new booking for
            <span v-if="moveContainerType == 'single'"> this container?</span>
            <span v-else> the selected containers?</span>
          </p>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn color="red" class="mx-2" text small @click="bookingCreateConfirm = false">No</v-btn>
            <v-btn color="success" class="mx-2" text small @click="createBookingMultipleContainer()"
              :loading="creatingNewBooking">Yes</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="ghostModal" width="400px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Ghost Reason</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="ghostModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text v-if="ghostItem" class="mb-0 pb-0">
          <v-select v-model="ghostItem.ghostReason" label="Category" outlined dense :items="ghostReasonList"></v-select>
          <v-select v-model="ghostItem.ghostParty" label="Responsible Party" outlined dense
            :items="availableParties"></v-select>
          <v-textarea outlined placeholder="Notes" v-model="ghostItem.ghostNote"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-row class="pb-3 mb-3 mt-0 pt-0" justify="center">
            <v-btn v-if="ghostType == 'single'" @click="markGhostContainer(container)" :loading="ghostLoading"
              color="blue" outlined>
              Submit
            </v-btn>
            <v-btn v-else-if="ghostType == 'multiple'" @click="markBulkAsGhost()" :loading="ghostLoading" color="blue"
              outlined>
              Submit
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="ghostContainerModal" width="400px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card v-if="ghostContainer">
        <v-toolbar flat color="transparent">
          <v-toolbar-title><v-icon class="mr-2" color="grey">widgets</v-icon>{{ ghostContainer.ctoNo
            }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="ghostContainerModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-list dense>
            <v-list-item>
              <v-list-item-action>
                <v-icon color="secondary">category</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ ghostContainer.ghostReason }}
                </v-list-item-title>
                <v-list-item-subtitle> Category </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-icon color="secondary">groups</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ ghostContainer.ghostParty }}
                </v-list-item-title>
                <v-list-item-subtitle> Responsible Party </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="ghostContainer.ghostNote">
              <v-list-item-action>
                <v-icon color="secondary">description</v-icon>
              </v-list-item-action>
              <v-list-item-content class="text-wrap">
                <p>{{ ghostContainer.ghostNote }}</p>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Edit container item details -->
    <v-dialog v-model="containerItemModal" width="95vw" :fullscreen="$vuetify.breakpoint.mobile" persistent>
      <v-card>
        <v-toolbar flat color="transaprent">
          <v-toolbar-title> Manage Container Item </v-toolbar-title>
          <v-spacer></v-spacer>
          <template>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <!-- <v-btn v-if="!validDetails" text @click="saveBooking()" :disabled="!booking.customerId" :loading="savingBooking" color="blue"><v-icon
                        left>save</v-icon> Save as Draft</v-btn> -->
                  <v-btn color="secondary" @click="saveContainerItem" :loading="savingContainerItem" :disabled="((!containerItem.isCarrierHaulage ) && (!containerItem.transportCoordinator || !containerItem.transportCoordinator.name))" icon><v-icon>save</v-icon></v-btn>
                </div>
              </template>
              <span>
                <div>
                  <span style="font-size: 12px" class="text-decoration-underline">
                    Reasons why button is disabled:
                  </span>
                </div>

                <div v-if="(!containerItem.isCarrierHaulage ) && (!containerItem.transportCoordinator || !containerItem.transportCoordinator.name)">
                  <span style="font-size: 12px; color: #ff6666">Container(s) missing transporter coordinator</span>
                </div>
              </span>
            </v-tooltip>
          </template>

          <v-btn text @click="(containerItemModal = false), (containerItem = {})">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row class="my-2">
            <v-col cols="12" sm="12" md="4" class="pt-1">
              <!-- <v-col cols="12" sm="12" md="4" class="pt-1"> -->
              <v-row>
                <v-col cols="6">
                  <v-text-field label="Customer Ref" v-model="containerItem.customerContainerRef" outlined dense
                    hide-details clearable></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-select outlined :items="['Method 1', 'Method 2']" dense hide-details clearable
                    v-model="containerItem.verificationMethod"></v-select>
                </v-col>
                <!-- <v-col v-if="containerItem.verificationMethod == 'Method 1'" cols="12" sm="6">
                                    <v-select label="Weighbridge Contract Owner" placeholder="Select a Party"
                                        persistent-placeholder hide-details outlined dense class="py-2"
                                        :items="['Shipper', 'Forwarder']"
                                        v-model="containerItem.verificationMethodOwner"></v-select>
                                </v-col>
                                <v-col v-if="containerItem.verificationMethod == 'Method 1'" cols="12" sm="6">
                                    <v-autocomplete v-model="containerItem.weighBridgeId" prepend-inner-icon="location_on"
                                        placeholder="Weighbridge Location" persistent-placeholder hide-details
                                        :items="weighBridges" dense outlined clearable :loading="loadingWeighBridges"
                                        item-text="name" item-value="id">
                                    </v-autocomplete>
                                </v-col> -->
              </v-row>
              <v-list dense>
                <v-list-item dense>
                  <v-list-item-action>
                    <el-input-number class="my-0 py-0" :min="1" :value="containerItem.quantity" size="mini"
                      v-model="containerItem.quantity"></el-input-number>
                  </v-list-item-action>
                  <v-autocomplete v-if="booking.serviceType === 'BREAKBULK'" style="display:none">
                  </v-autocomplete>
                  <v-autocomplete v-else class="my-0 py-0" hide-details outlined dense clearable label="Container Type"
                    v-model="containerItem.containerTypeCode" @change="containerTypeKey++" :items="containerTypes"
                    item-text="shortDescription" item-value="typeCode">
                  </v-autocomplete>
                </v-list-item>
              </v-list>

              <v-list dense subheader v-if="containerItem.bookingCargoItems">
                <v-divider></v-divider>
                <v-subheader style="font-size: 16px">Cargo/Products
                  <v-btn icon color="primary" @click="addContainerCargoDetails">
                    <v-icon>add_circle_outline</v-icon></v-btn></v-subheader>
                <v-divider></v-divider>

                <v-list-item v-if="containerItem.bookingCargoItems.length == 0">
                  <v-list-item-content class="text-center">
                    <span style="color: grey">No products listed</span>
                  </v-list-item-content></v-list-item>
                <v-list-item v-for="cargo in containerItem.bookingCargoItems" :key="cargo.index">
                  <v-list-item-content>
                    <v-list-item-title v-if="cargo.product">
                      {{ cargo.product.name }} - {{ cargo.hsCode }}
                      <span v-if="cargo.quantity">
                        - {{ cargo.quantity }} {{ cargo.quantityType }}</span>
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="cargo.nettWeight || cargo.grossWeight" class="text-wrap">
                      NETT {{ cargo.nettWeight }} KK - GROSS
                      {{ cargo.grossWeight }}KG
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="cargo.volume" class="text-wrap">
                      {{ cargo.volume }} CBM
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="cargo.industrial || cargo.organic || cargo.hazardous
    " class="text-wrap">
                      <v-chip small color="blue-grey" class="mx-1" v-if="cargo.industrial"><v-icon small
                          left>factory</v-icon> Industrial
                        Use</v-chip>
                      <v-chip small color="green" class="mx-1" v-if="cargo.industrial"><v-icon small left>spa</v-icon>
                        Organic</v-chip>
                      <v-chip small color="green" class="mx-1" v-if="cargo.hazardous"><v-icon color="orange"
                          left>warning</v-icon>
                        Hazardous</v-chip>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-row justify="center">
                      <v-btn icon small @click="editCargoItem(cargo)"><v-icon small>edit</v-icon></v-btn>
                      <v-btn icon small @click="deleteCargoItem(cargo)" color="red"><v-icon
                          small>delete</v-icon></v-btn>
                    </v-row>
                  </v-list-item-action>
                </v-list-item>
                <!-- <v-list-item @click="getRegimeCodes(true)"
                                    :disabled="!containerItem.bookingCargoItems || (containerItem.bookingCargoItems && containerItem.bookingCargoItems.length == 0)">
                                    <v-list-item-action>
                                        <v-icon :color="!booking.regimeCode ? 'secondary' : 'success'">tag</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title v-if="booking.regimeCode">
                                            {{ booking.regimeCode }}
                                        </v-list-item-title>
                                        <v-list-item-title v-else>-</v-list-item-title>
                                        <v-list-item-subtitle v-if="booking.regime">
                                            <v-icon class="mr-2" color="grey" small>thermostat</v-icon>Set Point: {{
                                                booking.regime.setPointTemp }}°C
                                            <v-icon class="mx-2" color="red" small>thermostat</v-icon>Max: {{
                                                booking.regime.maxProductTemp }}°C
                                            <v-icon class="mx-2" color="blue" small>thermostat</v-icon>Min {{
                                                booking.regime.minProductTemp }}°C
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle>
                                            Regime Code
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item> -->
              </v-list>
              <v-textarea v-model="containerItem.comment" label="Comments" outlined hide-details rows="3"></v-textarea>
            </v-col>

            <v-col cols="12" sm="12" md="4" :key="containerTypeKey">
              <v-list dense>
                <v-divider></v-divider>
                <v-subheader style="font-size: 16px">
                  Additional Products & Services<v-spacer></v-spacer>
                  <v-btn icon color="primary" @click="getTemporaryProducts('Add')">
                    <v-icon>add_circle_outline</v-icon></v-btn></v-subheader>
                <v-divider></v-divider>
                <v-list dense class="mt-0 pt-0">
                  <v-list-item v-if="!hasProductsCount &&
    !hasDevicesWithCount &&
    !bulkUpdateItem.monitoringService
    ">
                    <v-list-item-content class="text-center">
                      <span style="color: grey">No products & services listed</span>
                    </v-list-item-content>
                  </v-list-item>

                  <template v-if="hasProductsCount">
                    <v-list-item v-for="(item, index) in hasProducts" :key="index">
                      <v-list-item-content>
                        <v-row no-gutters>
                          <v-col cols="10" class="py-4">
                            {{ item.product }}
                          </v-col>
                          <v-col cols="2">
                            <v-list-item-action class="py-0">
                              <v-btn-toggle :key="gensetToggleKey">
                                <v-btn x-small @click="toggleProducts('NO', item.product)" :color="item.selected === false ? 'primary' : 'grey'
    ">
                                  NO
                                </v-btn>
                                <v-btn x-small @click="toggleProducts('YES', item.product)" :color="item.selected === true ? 'tertiary' : 'grey'
    ">
                                  YES
                                </v-btn>
                              </v-btn-toggle>
                            </v-list-item-action>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                      <v-list-item-action></v-list-item-action>
                    </v-list-item>
                  </template>

                  <template v-if="hasDevicesWithCount">
                    <v-list-item v-for="(item, index) in hasDevices" :key="index">
                      <v-list-item-content>
                        <v-row no-gutters>
                          <v-col cols="9" class="py-4">
                            {{ item.product }}
                            {{
    item.variation !== "Standard"
      ? "(" + item.variation + ")"
      : ""
  }}
                          </v-col>
                          <v-col cols="3">
                            <v-list-item-action class="py-0">
                              <el-input-number :min="0" :value="item.count" size="mini" :max="20"
                                v-model="item.count"></el-input-number>
                            </v-list-item-action>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-if="bulkUpdateItem.monitoringService">
                    <v-list-item>
                      <v-list-item-content>
                        <v-row no-gutters>
                          <v-col cols="9" class="py-4">
                            {{
    `Monitoring Service - ` +
    bulkUpdateItem.monitoringService
  }}
                          </v-col>
                          <v-col cols="3">
                            <!-- <v-list-item-action class="py-0">
                              <el-input-number
                                :min="0"
                                :value="bulkUpdateItem.monitoringServiceCount"
                                size="mini"
                                :max="20"
                                v-model="bulkUpdateItem.monitoringServiceCount"
                              ></el-input-number>
                            </v-list-item-action> -->
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list>
              </v-list>
              <!-- <v-list dense class="mt-0 pt-0">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>bolt</v-icon>
                  </v-list-item-action>
                  <v-list-item-content class="text-wrap">
                    <v-list-item-title> Genset Required </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-switch v-model="containerItem.gensetRequired" color="success"></v-switch>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item style="height: 35px">
                  <v-list-item-action>
                    <v-icon>thermostat</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      Add Temperature Device
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-switch v-model="containerItem.sensorDevice" color="success"></v-switch>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item style="height: 35px" v-if="containerItem.sensorDevice">
                  <v-select class="mr-1" label="Monitoring Service" persistent-placeholder clearable flat dense outlined
                    hide-details :items="['TempCheck', 'TempCheckPLUS', 'Fumigation']"
                    v-model="containerItem.sensorService"></v-select>
                  <v-select class="ml-1" label="Temperature Device Type" persistent-placeholder clearable flat
                    hide-details dense outlined :items="[
                      'RF TEMPTALE',
                      'USB TEMPTALE',
                      'GEO EAGLE TEMPTALE',
                      'MOST TEMPTALE',
                      '2G GEO TEMPTALE',
                      '3G GEO TEMPTALE',
                      '4G GEO TEMPTALE',
                      '2G TIVE',
                      '5G TIVE',
                    ]" v-model="containerItem.sensorDeviceType"></v-select>
                </v-list-item>

              </v-list> -->
              <v-list v-if="displayReeferSettings">
                <v-divider></v-divider>
                <v-subheader style="font-size: 16px">
                  <v-icon class="mr-2">ac_unit</v-icon> Reefer
                  Settings</v-subheader>
                <v-divider></v-divider>
                <!-- <v-autocomplete dense class="mt-3 py-0" outlined clearable label="Regime Code"
                                    v-model="booking.regimeCode"
                                    :hint="booking.regimeCode ? booking.regimeCode.recipeDescription : ''" persistent-hint
                                    return-object :items="regimeCodes" item-text="name" item-value="code">
                                    <template v-slot:selection="data">
                                        <span v-if="data.item">
                                            {{ data.item.code }}
                                        </span>
                                    </template>
                                    <template v-slot:item="data">
                                        <v-list-item-content>
                                            <v-list-item-title>{{ data.item.code }}</v-list-item-title>
                                            <v-list-item-subtitle class="text-wrap">{{
                                                data.item.recipeDescription
                                            }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </v-autocomplete> -->
                <v-row class="mt-2">
                  <v-col cols="12" sm="4" class="my-0 py-0">
                    <v-select v-if="booking.serviceType !='BREAKBULK'" label="Vents" outlined dense hide-details class="pb-1" v-model="containerItem.vents"
                      :items="ventSettings"></v-select>
                  </v-col>
                  <v-col cols="12" sm="4" class="my-0 py-0">
                    <v-text-field prepend-inner-icon="thermostat" label="Set Temp" hide-details
                      v-model="containerItem.setPointTemp" outlined dense class="pb-1" suffix="°C"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" class="my-0 py-0">
                    <v-text-field label="Humidity" v-model="containerItem.humidity" outlined hide-details dense
                      type="number" suffix="%"></v-text-field>
                  </v-col>
                  <!-- <v-col cols="12" sm="4">
                    <v-text-field
                      label="Oxygen"
                      v-model="containerItem.oxygen"
                      outlined
                      dense
                      v-if="booking.caProduct"
                      hide-details
                      class="pb-1"
                      type="number"
                      suffix="%"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      label="Carbon Dioxide"
                      v-model="containerItem.carbonDioxide"
                      v-if="booking.caProduct"
                      hide-details
                      class="pb-1"
                      outlined
                      type="number"
                      dense
                      suffix="%"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4"> -->
                  <!-- <v-text-field
                      label="Nitrogen"
                      v-model="containerItem.nitrogen"
                      outlined
                      v-if="booking.caProduct"
                      hide-details
                      dense
                      type="number"
                      suffix="%"
                    ></v-text-field> -->
                  <!-- </v-col> -->
                </v-row>
              </v-list>
            </v-col>
            <v-col cols="12" sm="12" md="4" class="pt-1">
              <v-list dense>
                <v-list-item @click="(partyType = 'Buyer'), (partyModal = true)">
                  <v-list-item-action>
                    <v-avatar :color="containerItem.buyer && containerItem.buyer.logo
    ? 'white'
    : 'secondary'
    " size="32" v-if="containerItem.buyer && containerItem.buyer.name">
                      <v-img v-if="containerItem.buyer.logo" :src="containerItem.buyer.logo" contain></v-img>
                      <h3 v-else style="color: white">
                        {{ containerItem.buyer.name.charAt(0) }}
                      </h3>
                    </v-avatar>
                    <v-icon v-else color="secondary">shopping_cart</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title v-if="containerItem.buyer">
                      {{ containerItem.buyer.name }}
                    </v-list-item-title>
                    <v-list-item-title v-else>-</v-list-item-title>
                    <v-list-item-subtitle>Preliminary Buyer</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="(partyType = 'Consignee'), (partyModal = true)">
                  <v-list-item-action>
                    <v-avatar :color="containerItem.consignee && containerItem.consignee.logo
    ? 'white'
    : 'secondary'
    " size="32" v-if="containerItem.consignee && containerItem.consignee.name">
                      <v-img v-if="containerItem.consignee.logo" :src="containerItem.consignee.logo" contain></v-img>
                      <h3 v-else style="color: white">
                        {{ containerItem.consignee.name.charAt(0) }}
                      </h3>
                    </v-avatar>
                    <v-icon v-else color="secondary">call_received</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title v-if="containerItem.consignee">
                      {{ containerItem.consignee.name }}
                    </v-list-item-title>
                    <v-list-item-title v-else>-</v-list-item-title>
                    <v-list-item-subtitle>Preliminary Consignee</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="setSelectableParties(), (haulierModal = true)">
                  <v-list-item-action>
                    <v-avatar color="white" size="32" v-if="containerItem.transportCoordinator">
                      <v-img v-if="containerItem.transportCoordinator.logo"
                        :src="containerItem.transportCoordinator.logo" contain></v-img>
                      <v-icon small v-else color="black">{{
    containerItem.transportCoordinator.logo
  }}</v-icon>
                    </v-avatar>
                    <v-icon v-else color="secondary">local_shipping</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title v-if="containerItem.transportCoordinator">
                      {{ containerItem.transportCoordinator.name }}
                    </v-list-item-title>
                    <v-list-item-title v-else-if="containerItem.isCarrierHaulage">Carrier Haulage</v-list-item-title>
                    <v-list-item-title v-else-if="containerItem.isRailage">Rail</v-list-item-title>
                    <v-list-item-title v-else>-</v-list-item-title>
                    <v-list-item-subtitle v-if="containerItem.preAdviseRequired"><v-chip color="blue" x-small>Preadvise
                        Required</v-chip></v-list-item-subtitle>
                    <v-list-item-subtitle>Transport Coordinator</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-subheader style="font-size: 16px">Pre-Carriage Legs / Loading Points
                </v-subheader>
                <v-divider></v-divider>
                <v-list-item v-for="(milestone, index) in availableMilestones.filter(
      (x) =>
        x.type == booking.movementType &&
        (x.value !== 'GASSING' ||
          (x.value === 'GASSING' && !avoBox && booking.caProduct))
    )" :key="index" @click="addMilestone(containerItem, milestone.value, false)">
                  <v-list-item-action class="mr-0 pr-0">
                    <v-avatar size="10" v-if="containerItem[milestone.value]" :color="containerItem[milestone.value].isActual
    ? 'success'
    : 'secondary'
    "></v-avatar>
                    <v-icon v-else color="grey" small>warning</v-icon>
                  </v-list-item-action>
                  <v-list-item-content v-if="containerItem[milestone.value]">
                    <v-list-item-title style="font-size: 14px">{{
    containerItem[milestone.value].description
  }}</v-list-item-title>
  <v-list-item-subtitle style="font-size: 12px">
                                {{ $Format.formatDate(containerItem[milestone.value].date).isoFormat }}
                                {{containerItem[milestone.value].time}}</v-list-item-subtitle>
                    <!-- <v-list-item-subtitle style="font-size: 12px">{{
      formatDateTime(containerItem[milestone.value].date)
    }}</v-list-item-subtitle> -->
                  </v-list-item-content>
                  <v-list-item-content v-else>
                    <v-list-item-subtitle style="font-size: 14px">{{
      milestone.name
    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Edit Cargo -->
    <v-dialog v-model="cargoModal" persistent width="400px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Cargo Details</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text color="secondary" :disabled="!cargoItem.product || !cargoItem.hsCode || !cargoItem.description
    " @click="saveCargoItem"><v-icon>save</v-icon></v-btn>
          <v-btn text @click="(cargoItem = {}), (cargoModal = false)">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-select hide-details class="my-2" label="Type" :items="['Product Group', 'Product', 'Variety']"
                v-model="filterProductType" outlined dense></v-select>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-autocomplete class="my-2" hide-details ref="countrySelect" autofocus hide-no-data hide-selected
                label="Product" dense outlined :items="filterProducts" clearable item-text="name" item-value="id"
                :menu-props="{ closeOnContentClick: true }" @change="setCargoItemDetails" v-model="cargoItem.product"
                return-object :loading="loadingProduct">
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ data.item.type }}</v-list-item-subtitle>
                    <v-list-item-subtitle v-if="data.item.parentProduct" style="font-size: 12px">
                      Parent: {{ data.item.parentProduct.name }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" class="my-0 py-0">
              <v-textarea hide-details class="my-2" rows="3" label="Description" v-model="cargoItem.description"
                outlined></v-textarea>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-text-field hide-details class="my-2" label="HS Code" v-model="cargoItem.hsCode" outlined
                dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-text-field hide-details class="my-2" label="No Pallets" type="number" v-model="cargoItem.pallets"
                outlined dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-text-field hide-details class="my-2" label="Nett Weight" suffix="Kg" v-model="cargoItem.nettWeight"
                outlined dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-text-field hide-details class="my-2" label="Gross Weight" suffix="Kg" v-model="cargoItem.grossWeight"
                outlined dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-text-field hide-details class="my-2" label="Cubic measure" suffix="CBM" v-model="cargoItem.volume"
                outlined dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-text-field hide-details class="my-2" label="Batch Number" v-model="cargoItem.batchNumber" outlined
                dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-select hide-details class="my-2" label="Quantity Type" clearable v-model="selectedQuantityType"
                outlined dense :items="productQuantityTypes" @change="selectQuantityType()" return-object></v-select>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-text-field hide-details class="my-2" :label="`Quantity`" v-model="cargoItem.quantity" outlined
                dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-switch label="Industrial Use" dense v-model="cargoItem.industrial" hide-details></v-switch>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-switch label="Organic" dense v-model="cargoItem.organic" hide-details></v-switch>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-switch label="Hazardous" dense v-model="cargoItem.hazardous"></v-switch>
            </v-col>
          </v-row>
          <v-list dense subheader v-if="cargoItem.hazardous">
            <v-divider></v-divider>
            <v-subheader style="font-size: 16px">Hazardous Details</v-subheader>
            <v-divider></v-divider>
            <v-row class="my-2">
              <v-col cols="12" sm="6" class="my-0 py-0">
                <v-text-field hide-details class="my-2" label="UN Code" v-model="cargoItem.unCode" outlined
                  dense></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="my-0 py-0">
                <v-text-field hide-details class="my-2" label="UN Packing Group" v-model="cargoItem.unCodePackingGroup"
                  outlined dense></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="my-0 py-0">
                <v-text-field hide-details class="my-2" :label="`Hazard Class`" v-model="cargoItem.class" outlined
                  dense></v-text-field>
              </v-col>
            </v-row>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="containerProductModal" width="400px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card v-if="container && container.selectedProduct">
        <Products @close="(containerProductModal = false), (container = null)" :item="container.selectedProduct"
          @deleted="deleteSelectedContainerProduct" @chosenProduct="updateSelectedContainerProduct" />
      </v-card>
    </v-dialog>

    <!-- <v-dialog v-model="containerPOIModal" width="600px" :fullscreen="$vuetify.breakpoint.mobile">
            <v-card v-if="poiItem">
                <LoadingPointModal :type="poiItem.friendlyType" :item="poiItem"
                    @close="containerPOIModal = false, poiItem = null" :poiFunctions="poiFunctions"
                    @setPoint="setPointOfLoadingContainer" />
            </v-card>
        </v-dialog> -->

    <!-- <v-dialog v-model="containerSlotModal" width="400px" :fullscreen="$vuetify.breakpoint.mobile">
            <v-card v-if="poiItem">
                <LoadPointSlot :type="poiItem.friendlyType" :date="poiItem.date"
                    @close="containerSlotModal = false, poiItem = null" @save="saveSlot" />
            </v-card>
        </v-dialog> -->

    <v-dialog v-model="ventDialog" width="400px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card v-if="selectedContainer">
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Set Vents</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="secondary" :loading="savingVent" @click="setVent()"><v-icon>save</v-icon></v-btn>
          <v-btn text @click="ventDialog = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-select v-model="selectedContainer.vents" :items="[
    'Closed',
    '15',
    '20',
    '25',
    '28',
    '30',
    '35',
    '40',
    '50',
    'Fully Open',
    'CA',
    'MA',
    'Auto',
  ]" outlined dense placeholder="Vent Setting" autofocus clearable></v-select>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="commentDialog" width="400px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card v-if="selectedContainer">
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Container Note</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="secondary" :loading="savingContainerComment"
            @click="setComment()"><v-icon>save</v-icon></v-btn>
          <v-btn text @click="commentDialog = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-textarea outlined dense v-model="selectedContainer.containerComments"
            placeholder="Type notes here"></v-textarea>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="bulkUpdateModal" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>{{
    bulkType == "Add" ? "Add Products" : "Bulk Update"
  }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <template>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-btn icon @click="bulkType == 'Add' ? addProducts() : saveBulkUpdate()" :loading="saveBulkLoader"
                    :disabled="(bulkUpdateItem.monitoringService === 'TempCheck' || bulkUpdateItem.monitoringService === 'TempCheckPLUS') && !devices.some(device => device.count > 0)"
                    color="secondary">
                    <v-icon>save</v-icon>
                  </v-btn>

                </div>
              </template>
              <span>
                <div>
                  <span style="font-size: 12px" class="text-decoration-underline"> Reasons why button is disabled:
                  </span>
                </div>
                <!-- Condition 2: Monitoring service selected but no devices -->
                <div
                  v-if="(bulkUpdateItem.monitoringService === 'TempCheck' || bulkUpdateItem.monitoringService === 'TempCheckPLUS') && !devices.some(device => device.count > 0)">
                  <span style="font-size: 12px; color:#ff6666">{{ bulkUpdateItem.monitoringService }} service was
                    selected,
                    but no devices is selected</span>
                </div>

              </span>
            </v-tooltip>
          </template>


          <v-btn icon @click="closeBulkUpdate()" color="primary">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-row>
            <!-- Left Side with List Items -->
            <v-col cols="4">
              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>bolt</v-icon>
                </v-list-item-action>
                <v-list-item-content class="text-wrap">
                  <v-list-item-title> Genset Required </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle :key="gensetToggleKey">
                    <v-btn x-small @click="toggleProducts('NO', 'Genset')" :color="bulkUpdateItem.Genset === false ? 'primary' : 'grey'
    ">
                      NO
                    </v-btn>
                    <v-btn x-small @click="toggleProducts(null, 'Genset')" :color="bulkUpdateItem.Genset === null
    ? 'blue darken-3'
    : 'grey'
    ">N/A</v-btn>
                    <v-btn x-small @click="toggleProducts('YES', 'Genset')" :color="bulkUpdateItem.Genset === true ? 'tertiary' : 'grey'
    ">
                      YES
                    </v-btn>
                  </v-btn-toggle>
                  <!-- <v-switch v-model="bulkEdit.gensetRequired" color="success"></v-switch> -->
                </v-list-item-action>
              </v-list-item>

              <!-- New List Items -->

              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>air</v-icon>
                </v-list-item-action>
                <v-list-item-content class="text-wrap">
                  <v-list-item-title> Otflow Required </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle :key="otflowKey">
                    <v-btn x-small @click="toggleProducts('NO', 'Otflow')" :color="bulkUpdateItem.Otflow === false ? 'primary' : 'grey' ">
                      NO
                    </v-btn>
                    <v-btn x-small @click="toggleProducts(null, 'Otflow')" :color="bulkUpdateItem.Otflow === null ? 'blue darken-3' : 'grey' ">N/A</v-btn>
                    <v-btn x-small @click="toggleProducts('YES', 'Otflow')" :color="bulkUpdateItem.Otflow === true ? 'tertiary' : 'grey' ">
                      YES
                    </v-btn>
                  </v-btn-toggle>
                </v-list-item-action>
              </v-list-item>

              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>sanitizer</v-icon>
                </v-list-item-action>
                <v-list-item-content class="text-wrap">
                  <v-list-item-title> Zoono Required </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle :key="zoonoKey">
                    <v-btn x-small @click="toggleProducts('NO', 'Zoono')" :color="bulkUpdateItem.Zoono === false ? 'primary' : 'grey' ">
                      NO
                    </v-btn>
                    <v-btn x-small @click="toggleProducts(null, 'Zoono')" :color="bulkUpdateItem.Zoono === null ? 'blue darken-3' : 'grey' ">N/A</v-btn>
                    <v-btn x-small @click="toggleProducts('YES', 'Zoono')" :color="bulkUpdateItem.Zoono === true ? 'tertiary' : 'grey' ">
                      YES
                    </v-btn>
                  </v-btn-toggle>
                </v-list-item-action>
              </v-list-item>

              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>power</v-icon>
                </v-list-item-action>
                <v-list-item-content class="text-wrap">
                  <v-list-item-title> Void Plugs Required </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle :key="voidPlugKey">
                    <v-btn x-small @click="toggleProducts('NO', 'Void_Plugs')" :color="bulkUpdateItem.Void_Plugs === false
    ? 'primary'
    : 'grey'
    ">
                      NO
                    </v-btn>
                    <v-btn x-small @click="toggleProducts(null, 'Void_Plugs')" :color="bulkUpdateItem.Void_Plugs === null
    ? 'blue darken-3'
    : 'grey'
    ">N/A</v-btn>
                    <v-btn x-small @click="toggleProducts('YES', 'Void_Plugs')" :color="bulkUpdateItem.Void_Plugs === true
    ? 'tertiary'
    : 'grey'
    ">
                      YES
                    </v-btn>
                  </v-btn-toggle>
                  <!-- <v-switch v-model="bulkEdit.gensetRequired" color="success"></v-switch> -->
                </v-list-item-action>
              </v-list-item>
              


             <v-list-item dense> 
                <v-list-item-action>
                  <v-icon>watch_later</v-icon>
                </v-list-item-action>
                <v-list-item-content class="text-wrap">
                  <v-list-item-title>Early Arrival Required</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle :key="earlyArrivalKey">
                    <v-btn x-small @click="toggleProducts('NO', 'Early_Arrival')" :color="bulkUpdateItem.Early_Arrival === false
    ? 'primary'
    : 'grey'
    ">
                      NO
                    </v-btn>
                    <v-btn x-small @click="toggleProducts(null, 'Early_Arrival')" :color="bulkUpdateItem.Early_Arrival === null
    ? 'blue darken-3'
    : 'grey'
    ">N/A</v-btn>
                    <v-btn x-small @click="toggleProducts('YES', 'Early_Arrival')" :color="bulkUpdateItem.Early_Arrival === true
    ? 'tertiary'
    : 'grey'
    ">
                      YES
                    </v-btn>
                  </v-btn-toggle>
         
                </v-list-item-action>
              </v-list-item>



              <v-list-item dense v-if="bulkUpdateItem.Early_Arrival === true">
                <v-select label="LAR description(Early Arrival)" placeholder="Select a Early Arrival description" persistent-placeholder
                  hide-details outlined dense class="py-1 mt-2 mb-2" :items="[
    'Pending',
    'Approved',
    'Approved (No Charge)',
    'Rejected',
    'Approved (Not Used)',
    'Not Applicable',
  ]" v-model="earlyDescription"></v-select>
              </v-list-item>
            

          
              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>watch_later</v-icon>
                </v-list-item-action>
                <v-list-item-content class="text-wrap">
                  <v-list-item-title> Late Arrival Required </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle :key="lateArrivalKey">
                    <v-btn x-small @click="toggleProducts('NO', 'Late_Arrival')" :color="bulkUpdateItem.Late_Arrival === false
    ? 'primary'
    : 'grey'
    ">
                      NO
                    </v-btn>
                    <v-btn x-small @click="toggleProducts(null, 'Late_Arrival')" :color="bulkUpdateItem.Late_Arrival === null
    ? 'blue darken-3'
    : 'grey'
    ">N/A</v-btn>
                    <v-btn x-small @click="toggleProducts('YES', 'Late_Arrival')" :color="bulkUpdateItem.Late_Arrival === true
    ? 'tertiary'
    : 'grey'
    ">
                      YES
                    </v-btn>
                  </v-btn-toggle>
                  <!-- <v-switch v-model="bulkEdit.gensetRequired" color="success"></v-switch> -->
                </v-list-item-action>
              </v-list-item>

              <v-list-item dense v-if="bulkUpdateItem.Late_Arrival === true">
                <v-select label="LAR description(Late Arrival)" placeholder="Select a LAR description" persistent-placeholder
                  hide-details outlined dense class="py-1 mt-2 mb-2" :items="[
    'Pending',
    'Approved',
    'Approved (No Charge)',
    'Rejected',
    'Approved (Not Used)',
    'Not Applicable',
  ]" v-model="lateDescription"></v-select>
              </v-list-item>
              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>cleaning_services</v-icon>
                </v-list-item-action>
                <v-list-item-content class="text-wrap">
                  <v-list-item-title> Fumigation Required </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle :key="portMonitoringKey">
                    <v-btn x-small @click="toggleProducts('NO', 'Fumigation')" :color="bulkUpdateItem.Fumigation === false ? 'primary' : 'grey'
    ">
                      NO
                    </v-btn>
                    <v-btn x-small @click="toggleProducts(null, 'Fumigation')" :color="bulkUpdateItem.Fumigation === null
    ? 'blue darken-3'
    : 'grey'
    ">N/A</v-btn>
                    <v-btn x-small @click="toggleProducts('YES', 'Fumigation')" :color="bulkUpdateItem.Fumigation === true ? 'tertiary' : 'grey'
    ">
                      YES
                    </v-btn>
                  </v-btn-toggle>
                  <!-- <v-switch v-model="bulkEdit.gensetRequired" color="success"></v-switch> -->
                </v-list-item-action>
              </v-list-item>

              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>settings_overscan</v-icon>
                </v-list-item-action>
                <v-list-item-content class="text-wrap">
                  <v-list-item-title>
                    Container Liners Required
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle :key="portMonitoringKey">
                    <v-btn x-small @click="toggleProducts('NO', 'ContainerLiners')" :color="bulkUpdateItem.ContainerLiners === false
    ? 'primary'
    : 'grey'
    ">
                      NO
                    </v-btn>
                    <v-btn x-small @click="toggleProducts(null, 'ContainerLiners')" :color="bulkUpdateItem.ContainerLiners === null
    ? 'blue darken-3'
    : 'grey'
    ">N/A</v-btn>
                    <v-btn x-small @click="toggleProducts('YES', 'ContainerLiners')" :color="bulkUpdateItem.ContainerLiners === true
    ? 'tertiary'
    : 'grey'
    ">
                      YES
                    </v-btn>
                  </v-btn-toggle>
                  <!-- <v-switch v-model="bulkEdit.gensetRequired" color="success"></v-switch> -->
                </v-list-item-action>
              </v-list-item>

              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>emergency</v-icon>
                </v-list-item-action>
                <v-list-item-content class="text-wrap">
                  <v-list-item-title>
                    Steri Insurance Required
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle :key="portMonitoringKey">
                    <v-btn x-small @click="toggleProducts('NO', 'SteriInsurance')" :color="bulkUpdateItem.SteriInsurance === false
    ? 'primary'
    : 'grey'
    ">
                      NO
                    </v-btn>
                    <v-btn x-small @click="toggleProducts(null, 'SteriInsurance')" :color="bulkUpdateItem.SteriInsurance === null
    ? 'blue darken-3'
    : 'grey'
    ">N/A</v-btn>
                    <v-btn x-small @click="toggleProducts('YES', 'SteriInsurance')" :color="bulkUpdateItem.SteriInsurance === true
    ? 'tertiary'
    : 'grey'
    ">
                      YES
                    </v-btn>
                  </v-btn-toggle>
                  <!-- <v-switch v-model="bulkEdit.gensetRequired" color="success"></v-switch> -->
                </v-list-item-action>
              </v-list-item>


              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>smart_screen</v-icon>
                </v-list-item-action>
                <v-list-item-content class="text-wrap">
                  <v-list-item-title>
                    Smart Fresh Required
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle :key="portMonitoringKey">
                    <v-btn x-small @click="toggleProducts('NO', 'SmartFresh')" :color="bulkUpdateItem.SmartFresh === false
    ? 'primary'
    : 'grey'
    ">
                      NO
                    </v-btn>
                    <v-btn x-small @click="toggleProducts(null, 'SmartFresh')" :color="bulkUpdateItem.SmartFresh === null
    ? 'blue darken-3'
    : 'grey'
    ">N/A</v-btn>
                    <v-btn x-small @click="toggleProducts('YES', 'SmartFresh')" :color="bulkUpdateItem.SmartFresh === true
    ? 'tertiary'
    : 'grey'
    ">
                      YES
                    </v-btn>
                  </v-btn-toggle>
                </v-list-item-action>
              </v-list-item>

              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>scale</v-icon>
                </v-list-item-action>
                <v-list-item-content class="text-wrap">
                  <v-list-item-title>
                    Heavy Load
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle :key="portMonitoringKey">
                    <v-btn x-small @click="toggleProducts('NO', 'Heavyload')" :color="bulkUpdateItem.Heavyload === false
    ? 'primary'
    : 'grey'
    ">
                      NO
                    </v-btn>
                    <v-btn x-small @click="toggleProducts(null, 'Heavyload')" :color="bulkUpdateItem.Heavyload === null
    ? 'blue darken-3'
    : 'grey'
    ">N/A</v-btn>
                    <v-btn x-small @click="toggleProducts('YES', 'Heavyload')" :color="bulkUpdateItem.Heavyload === true
    ? 'tertiary'
    : 'grey'
    ">
                      YES
                    </v-btn>
                  </v-btn-toggle>
                </v-list-item-action>
              </v-list-item>
            </v-col>

            <!-- Right Side with Text Fields -->
            <!-- <v-col cols="4" v-if="bulkUpdateItem.monitoringService">
              <v-card outlined flat v-if="uniqueProducts.includes('TelemPlus')">
                <v-card-title>
                  TelemPlus
                  <v-spacer></v-spacer>
                  <v-btn
                    icon
                    color="primary"
                    class="ml-3"
                    @click="addServices()"
                    ><v-icon>add_circle_outline</v-icon></v-btn
                  >
                </v-card-title>
                <v-card-text>
                  <v-list dense class="ma-0 pa-0">
                    <v-list-item
                      v-for="(product, index) in devices.filter(
                        (option) =>
                          option.count > 0 && option.product == 'TelemPlus'
                      )"
                      :key="index"
                    >
                      <v-text-field
                        type="number"
                        v-model="product.count"
                        :min="0"
                        class="small-text-field"
                        style="text-align: center"
                        :label="product.variation + ' ' + 'Template'"
                        @input="updateProductCount(product)"
                      />
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col> -->

            <v-col cols="4">
              <v-card outlined flat>
                <v-row no-gutters>
                  <v-col cols="11">
                    <!-- Adjust "cols" as needed -->
                    <v-list-item dense>
                      <v-select label="Monitoring Service" placeholder="Select a Monitoring Service"
                        persistent-placeholder hide-details outlined dense item-text="text" item-value="value"
                        class="py-1 mt-2 mb-2" :items="[
    {
      text: 'iCheck',
      value: 'iCheck'
    },

    {
      text: 'TempCheck',
      value: 'TempCheck',
    },
    {
      text: 'TempCheckPLUS',
      value: 'TempCheckPLUS',
    },
    // {
    //   text: 'TempCheckLite',
    //   value: 'TempCheckLite',
    // },
    {
      text: 'Port Monitoring',
      value: 'Port Monitoring',
    },
    {
      text: 'N/A',
      value: 'N/A',
    },
  ]" v-model="bulkUpdateItem.monitoringService"></v-select>
                    </v-list-item>
                  </v-col>
                  <v-col cols="1">
                    <!-- Adjust "cols" as needed -->
                    <v-btn icon color="primary" @click="addServices()" class="mt-3">
                      <v-icon>add_circle_outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <!-- <v-card-title>
                  TelemPlus
                  <v-spacer></v-spacer>
                  <v-btn icon color="primary" class="ml-3"
                                                            @click="addServices()"><v-icon>add_circle_outline</v-icon></v-btn>
                </v-card-title> -->
                <v-card-text v-if="uniqueProducts.includes('TelemPlus')">
                  <v-list dense class="ma-0 pa-0">
                    <v-list-item v-for="(product, index) in devices.filter(
    (option) =>
      option.count > 0 && option.product == 'TelemPlus'
  )" :key="index">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ product.variation }} Template
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <el-input-number v-model="product.count" :min="0" size="mini"
                          @change="updateProductCount(product)"></el-input-number>
                      </v-list-item-action>
                      <!-- <v-text-field
                          type="number"
                          v-model="product.count"
                          :min="0"
                          class="small-text-field"
                          style="text-align: center"
                          :label="product.variation + ' ' + 'Template'"
                          @input="updateProductCount(product)"
                        /> -->
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-card flat outlined>
                <v-card-title>
                  Additional products <v-spacer></v-spacer>
                  <v-btn icon color="primary" @click="addAdditionalProduct = true" class="mt-3">
                    <v-icon>add_circle_outline</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <v-list dense class="ma-0 pa-0">
                    <v-list-item v-for="(product, index) in devices.filter(
    (option) =>
      option.count > 0 && option.variation == 'Standard'
  )" :key="index">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ product.product }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <el-input-number v-model="product.count" :min="0" size="mini"
                          @change="updateProductCount(product)"></el-input-number>
                      </v-list-item-action>
                      <!-- <v-text-field
                          type="number"
                          v-model="product.count"
                          :min="0"
                          class="small-text-field"
                          style="text-align: center"
                          :label="product.variation + ' ' + 'Template'"
                          @input="updateProductCount(product)"
                        /> -->
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
              <!-- <v-card
                outlined
                flat
                v-for="uniqueProduct in uniqueProducts.filter((p) =>
                  ['Void Plugs', 'Blast Freezing','Kraft paper','Absorb Bags','Stabling bars','Latches','Ratches'].includes(p))"
                :key="uniqueProduct"
              >
                <v-card-title>
                  {{ uniqueProduct }}
                  <v-spacer></v-spacer>
                  <v-list dense class="ma-0 pa-0">
                    <v-list-item
                      v-for="(product, index) in devices.filter(
                        (d) => d.product === uniqueProduct
                      )"
                      :key="index"
                    >
                      <el-input-number
                        v-model="product.count"
                        :min="0"
                        controls-position="right"
                        size="mini"
                        @change="updateProductCount(product)"
                      ></el-input-number>

                    </v-list-item>
                  </v-list>
                </v-card-title>
                <v-card-text> </v-card-text>
              </v-card> -->
            </v-col>
            <!-- <v-col cols="4">
              <v-card
                outlined
                flat
                v-for="uniqueProduct in uniqueProducts.filter((p) =>
                  ['Void Plugs', 'Blast Freezing'].includes(p)
                )"
                :key="uniqueProduct"
              >
                <v-card-title>
                  {{ uniqueProduct }}
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text>
                  <v-list dense class="ma-0 pa-0">
                    <v-list-item
                      v-for="(product, index) in devices.filter(
                        (d) => d.product === uniqueProduct
                      )"
                      :key="index"
                    >
                    <el-input-number
                        v-model="product.count"
                        :min="0"
                        controls-position="right"
                        size="mini"
                        @change="updateProductCount(product)"
                      ></el-input-number>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col> -->
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addMilestoneModal" width="500px" :fullscreen="$vuetify.breakpoint.mobile">
      <ManageContainerMilestone :item="milestoneItem" :bookingType="booking.movementType" :key="milestoneKey"
        @close="addMilestoneModal = false" @save="milestoneUpdate" @unsavedItem="setMilestonePoint" />
    </v-dialog>

    <v-dialog v-model="bookingSplitTypeModal" width="400px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Split Booking</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="bookingSplitTypeModal = false"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="12">
              <v-card style="border-radius: 20px; background: #2196f3 !important" @click="
    (bookingCreateConfirm = true), (bookingSplitTypeModal = false)
    ">
                <v-card-text>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon>add_circle_outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Create New Booking
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card style="border-radius: 20px; background: #607d8b !important" @click="
    moveContainer('multiple'), (bookingSplitTypeModal = false)
    ">
                <v-card-text>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon>input</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Move to existing booking
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addServiceModal" max-width="400px" persistent>
      <v-card>
        <v-card-title>TelemPlus Products <v-spacer></v-spacer>
          <!-- <v-btn icon @click="addProducts" color="primary"><v-icon>add_circle_outline</v-icon></v-btn> -->
          <v-btn icon @click="addServiceModal = false" color="secondary"><v-icon>close</v-icon></v-btn></v-card-title>
        <v-list dense>
          <v-list-item v-for="(product, index) in devices.filter(
    (x) => x.product == 'TelemPlus'
  )" :key="index">
            <v-checkbox :label="product.variation" v-model="product.checked" @change="toggleCheckbox(product)" />
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addAdditionalProduct" max-width="400px" persistent>
      <v-card>
        <v-card-title>Additional Products <v-spacer></v-spacer>
          <!-- <v-btn icon @click="addProducts" color="primary"><v-icon>add_circle_outline</v-icon></v-btn> -->
          <v-btn icon @click="addAdditionalProduct = false"
            color="secondary"><v-icon>close</v-icon></v-btn></v-card-title>
        <v-list dense>
          <v-list-item v-for="(product, index) in devices.filter(
    (x) => x.variation == 'Standard'
  )" :key="index">
            <v-checkbox :label="product.product" v-model="product.checked" @change="toggleCheckbox(product)" />
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>

    <v-dialog v-model="weightDialog" width="400px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card v-if="selectedContainer">
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Set Weights</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="secondary" icon :loading="savingWeight" @click="saveContainerWeights()">
            <v-icon>save</v-icon>
          </v-btn>
          <v-btn icon @click="weightDialog = false"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field label="Nett Weight" suffix="KG" outlined dense type="number" :step="0.01" hide-details
            v-model="selectedContainer.nettWeight"></v-text-field>
          <v-text-field label="Gross Weight" suffix="KG" class="mt-1" type="number" :step="0.01" outlined dense
            hide-details v-model="selectedContainer.grossWeight"></v-text-field>
          <v-switch label="Load Out Weight" v-model="selectedContainer.loadOutReceived"></v-switch>
          <v-text-field label="VGM Weight" suffix="KG" class="mt-1" type="number" :step="0.01" outlined disabled dense
            hide-details v-model="selectedContainer.vgmWeight"></v-text-field>
          <v-text-field label="Tare Weight" suffix="KG" class="mt-1" type="number" :step="0.01" outlined disabled dense
            hide-details v-model="selectedContainer.tareWeight"></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="bookingSubmitWarning" width="400px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-card-text class="text-left mt-3 pt-3">
          <p style="font-size: 16px">
            Please select make sure to complete the following before continuing:
          </p>
          <ul>
            <li v-if="!booking.forwarder">Select a freight Forwarder</li>
            <li v-if="!booking.shipper">Select a Shipper</li>
            <li v-if="!booking.contractNumber">
              Provide a shipping line contract number
            </li>
            <li v-if="!booking.finalDestinationValue">
              Select a final destination
            </li>
            <li v-if="!booking.portOfLoadValue">Select a port of load</li>
            <li v-if="!booking.vessel">Select a vessel</li>
            <li v-if="!booking.mainCarriageConveyanceNumber">
              Select a voyage
            </li>
            <li v-if="!booking.regime && reeferShipment">
              Select a regime code
            </li>
            <li v-if="checkContainerCargo">
              No containers/cargo listed
            </li>
            <li v-if="checkContainerProducts">
              No products listed
            </li>
          </ul>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn color="blue" class="mx-2" text small @click="bookingSubmitWarning = false">Okay</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="bookingCommentModal" width="500px">
      <v-card>
        <v-toolbar flat><v-toolbar-title>Add Notes</v-toolbar-title><v-spacer></v-spacer>
          <v-btn text @click="submitInttraBooking()" :loading="submittingBooking" color="blue">Submit</v-btn>
          <v-btn text @click="bookingCommentModal = false">X</v-btn></v-toolbar>
        <v-card-text>
          <v-textarea v-model="booking.notes" rows="6" outlined label="Add additional notes"></v-textarea>
          <v-textarea v-model="systemNotes" rows="6" outlined label="System notes"></v-textarea>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="inttraUserModal" width="500px" persistent>
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Select Notification Users </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="blue" text @click="(inttraUserModal = false), (bookingCommentModal = true)">Next</v-btn>
          <v-btn text @click="inttraUserModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field v-model="inttraUserSearch" outlined placeholder="Search" prepend-inner-icon="search"
            clearable></v-text-field>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item v-if="filteredUsers.length == 0">
              <v-list-item-content class="text-center">
                <span style="color: grey">No users found</span>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-for="user in filteredUsers" :key="user.id" @click="addInttraUser(user.user.emailAddress)">
              <v-list-item-action>
                <v-avatar :color="user.user.avatar ? 'white' : 'secondary'" size="36">
                  <v-img v-if="user.user.avatar" referrerpolicy="no-referrer" :src="user.user.avatar" contain></v-img>
                  <h2 v-else-if="user.user.firstname" style="color: white; font-weight: normal">
                    {{ user.user.firstname.charAt(0) }}
                  </h2>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ user.user.firstname }} {{ user.user.surname }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon v-if="inttraUsers.includes(user.user.emailAddress)" color="green">check_circle_outline</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="loadoutModal" width="1200px" :fullscreen="$vuetify.breakpoint.mobile" persistent>
      <v-card>
        <v-card-title>
          {{ this.emailData.title }}
          <v-spacer></v-spacer>

          <v-btn text @click="emailLoadout()" :loading="mailingLoadoutInstr" :disabled='disabledLoadoutMail'><v-icon
              color="secondary">email</v-icon></v-btn>
          <v-btn text @click="loadoutModal = false, chosenContainers=[]">X</v-btn>
        </v-card-title>
        <v-card-text>

          <v-row>

            <v-col cols="12" sm="12" v-for="(stock, index) in this.emailData.stockData" :key="index">
              <v-expansion-panels style="background-color: var(--v-background-base) !important">
                <v-expansion-panel style="background-color: var(--v-background-base) !important">
                  <v-expansion-panel-header style="background-color: var(--v-background-base) !important">
                    <v-btn icon>
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                    <v-container>
                      <v-row>
                        {{ stock.poiName }}

                      </v-row>
                    </v-container>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card flat>
                      <v-card-title>
                        <v-container>
                          <v-row>
                            <v-text-field :label="` ${stock.poiName}: Email Adresses` " class="ml-4" outlined
                              hint="Please seperate email addresses with a comma (,)"
                              v-model="stock.emailAdresses"></v-text-field>
                          </v-row>
                          <v-row>
                            <v-text-field label="CC Email Adresses" class="ml-4" outlined
                              hint="Please seperate email addresses with a comma (,)"
                              v-model="stock.ccAdresses"></v-text-field>
                          </v-row>
                          <v-row>
                            <v-text-field label="Reply Adresses" class="ml-4" outlined
                              hint="Please seperate email addresses with a comma (,)"
                              v-model="stock.replyAdresses"></v-text-field>
                          </v-row>
                          <v-row>
                            <v-text class="ml-3 hint-text" style="color: red;font-size:16px">
                              {{ replyAdrr(stock) }}
                            </v-text>
                          </v-row>
                        </v-container>
                      </v-card-title>
                      <v-card-text v-for="(container, indexes) in stock.containers" :key="indexes">
                        <v-container class="details-container">
                          <v-row :class="getTextClass(container.totalPallets)">
                            <strong style="text-decoration: underline;">
                              {{ container.ctoNo }}
                            </strong>
                          </v-row>
                          <v-row :class="getTextClass(container.totalPallets)">
                            Vessel: {{ emailData.vessel }}
                          </v-row>
                          <v-row :class="getTextClass(container.totalPallets)">
                            POL: {{ emailData.pol }}
                          </v-row>
                          <v-row :class="getTextClass(container.totalPallets)">
                            Final Dest: {{ emailData.finalDest }}
                          </v-row>
                          <v-row :class="getTextClass(container.totalPallets)">
                            Receiver: {{ container.recieverName }}
                          </v-row>
                          <v-row :class="getTextClass(container.totalPallets)">
                            Temperature: {{ emailData.temp }}
                          </v-row>
                          <v-row :class="getTextClass(container.totalPallets)">
                            Customer Ref: {{ container.customerRef }}
                          </v-row>
                          <v-row :class="getTextClass(container.totalPallets)">
                            No Pallets: {{ container.totalPallets }}
                          </v-row>
                          <v-row :class="getTextClass(container.totalPallets)">
                            Gross Weight: {{ container.totalGrossWeight }} kg
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>

          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Haulier Modal -->
    <v-dialog v-model="haulierModal" width="900px" :fullscreen="$vuetify.breakpoint.mobile" persistent>
      <v-card>
        <v-card-title>
          Manage Haulier
          <v-spacer></v-spacer>
          <v-btn text @click="saveHaulier()" :loading="savingHaulier"><v-icon color="secondary">save</v-icon></v-btn>
          <v-btn text @click="haulierModal = false">X</v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6">
              <v-list style="max-height: 50vh; overflow-y: auto" dense>
                <v-subheader style="font-size: 16px">Transport Type</v-subheader>
                <v-divider></v-divider>
                <v-list-item v-for="haulier in hauliers" :key="haulier.index" @click="setHaulier(haulier)">
                  <v-list-item-action>
                    <v-avatar color="white" size="36">
                      <v-img v-if="haulier.logo" :src="haulier.logo" contain></v-img>
                      <v-icon v-else color="black">{{ haulier.icon }}</v-icon>
                    </v-avatar>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ haulier.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      haulier.subtitle
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action :key="haulierKey">
                    <v-icon color="success" v-if="containerItem[haulier.flag]">check_circle_outline</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="6">
              <v-list style="max-height: 50vh; overflow-y: auto" dense :key="haulierKey">
                <v-subheader style="font-size: 16px">Transport Coordinator</v-subheader>
                <v-divider></v-divider>
                <v-select :items="selectableParties" :disabled="containerItem.isCarrierHaulage" :placeholder="containerItem.isCarrierHaulage
    ? 'Select Transporter'
    : 'Not Applicable'
    " item-text="name" item-value="id" rounded style="background-color: #2f2f2f; margin: 1vh 1vw 0.5vh 1vw"
                  hide-details outlined return-object v-model="containerItem.transportCoordinator"
                  @change="updateTransporter()" clearable dense>
                  <template v-slot:selection="data">
                    <v-row align="center" v-if="containerItem.isPrivateHaulage || containerItem.isRailage">
                      <v-avatar size="42" class="mr-2" v-if="data.item">
                        <v-img v-if="data.item.logo" contain :src="data.item.logo"></v-img>
                      </v-avatar>
                      <span v-if="data.item">
                        {{ data.item.name }}
                      </span>
                    </v-row>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-avatar>
                      <v-img contain v-if="data.item.logo" :src="data.item.logo"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        data.item.name
                        }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
                <v-list-item dense v-if="
    booking.movementType == 'EXPORT'
    " class="mt-2">
                  <v-list-item-action>
                    <v-icon color="secondary">article</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> Pre-Advise </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-switch color="success" v-model="containerItem.preAdviseRequired"></v-switch>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item dense v-if="    booking.movementType == 'EXPORT'
    " class="mt-2">
                  <v-list-item-action>
                    <v-icon color="secondary">electrical_services</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> Plugged-In </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action :key="pluggedInKey">
                    <v-switch color="success" v-model="containerItem.pluggedInRequired" :disabled="containerItem.transportCoordinatorId ===
    $store.state.currentOrg.id
    "></v-switch>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="onHoldWarningModal" width="400px" persistent>
      <v-card v-if="booking.customer">
        <v-card-text>
          <v-row class="mt-3 pt-5">
            <v-col cols="12" class="text-center">
              <v-icon x-large color="orange">warning </v-icon>
            </v-col>
            <v-col cols="12" class="text-center">
              <p>
                The selected customer account ({{ booking.customer.name }}) is
                currently on hold.
              </p>
            </v-col>
            <v-col cols="12" class="text-center">
              <v-row justify="center" class="mt-2 mb-3">
                <v-btn color="success" class="mx-2" text small @click="onHoldWarningModal = false">Accept</v-btn>
                <v-btn color="red" class="mx-2" text small @click="$router.go(-1)">Reject</v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="stockPalletModal" width="95vw">
      <v-card :loading="stockPalletLoading" v-if="selectedContainer && selectedContainer.ctoNo">
        <v-toolbar flat>
          Stock Allocation: {{ selectedContainer.ctoNo }}
          <v-chip class="ml-2" style="max-width: 125px" small
            :color="orderItemStatusColor(selectedContainer.stockStatus)">
            <v-icon x-small left color="white">{{
              orderItemStatusIcon(selectedContainer.stockStatus)
              }}</v-icon>
            <span style="text-transform: none; color: white">
              {{ selectedContainer.stockStatus }}
            </span>
          </v-chip>
          <v-spacer></v-spacer>
          <v-btn text @click="stockPalletModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <table :width="'100%'" class="table-fixed">
            <thead style="
                padding-top: 0px;
                margin-top: 0px;
                font-size: 12px;
                z-index: 999999;
                top: 0px;
                bottom: 25px;
              ">
              <th class="text-center" style="position: sticky" v-for="(header, index) in palletHeaders" :key="index">
                <b style="font-size: 11px">{{ header.text }}</b>
              </th>
            </thead>
            <tbody style="
                margin-top: 25px;
                padding-top: 50px;
                font-size: 11px;
                max-height: 35vh;
                min-height: 35vh;
                overflow-y: auto;
              ">
              <tr v-for="pallet in stockPallets" :key="pallet.id" :style="{
    'border-top': '0.4px solid rgb(95, 95, 95)',
    width: '100%',
    background: '',
  }" class="text-center">
                <td v-for="(header, index) in palletHeaders" :key="index" :style="{
    'border-top': '0.4px solid rgb(95, 95, 95)',
  }">
                  <div v-if="header.value == 'status'">
                    <v-chip x-small outlined :color="pallet.status == 'In Stock'
    ? 'orange'
    : pallet.status == 'Allocated'
      ? 'blue'
      : pallet.status == 'Stuffed'
        ? 'green'
        : pallet.status == 'Instruction Sent' ? 'indigo' : 'red'
    ">
                      {{ pallet.status }}
                    </v-chip>
                  </div>
                  <div v-else>
                    <v-chip v-if="pallet[header.value]" small class="primaryText--text" outlined
                      style="border: none; font-size: 11px; z-index: 1">
                      {{ pallet[header.value] }}
                    </v-chip>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="qxDispatchModal" width="70vw" persistent>
      <v-card>
        <!-- {{qxDispatchData}} -->
        <v-card-title>
          Dispatch Data
          <v-spacer></v-spacer>
          <v-btn icon @click="createQXDispatch()" color='secondary'><v-icon>send</v-icon></v-btn>
          <v-btn text @click="qxDispatchModal = false, qxDispatchData=[],chosenContainers=[]">X</v-btn>
        </v-card-title>
        <v-card-text>
          <template>
            <v-container>
              <v-expansion-panels>
                <v-expansion-panel v-for="(item, index) in qxDispatchData" :key="index" :disabled="item.passed">
                  <v-expansion-panel-header style="background-color: var(--v-background-base) !important">
                    <div class="header-content">
                      <v-chip class="mr-2" :color="item.passed ? 'green' : 'red'" > 
                        <v-icon left>
                          {{ item.passed ? 'check_circle' : 'highlight_off' }}
                        </v-icon>
                        {{item.ctoNo}}</v-chip>
                      <v-chip class="mr-2">SSCC Count: {{item.uniqueSSCCCount}}</v-chip>
                      <v-chip class="mr-2">Pallet Quantity: {{item.palletQuantitySum}}</v-chip>
                      <v-chip class="mr-2">GW: {{item.totalPalletGrossWeight}}</v-chip>
                      <v-chip class="mr-2">NW: {{item.totalPalletNettWeight}}</v-chip>
                      <!-- <span class='mx-2'>{{ item.ctoNo + ' (GW: '+ item.totalPalletGrossWeight + ' NW: ' + item.totalPalletNettWeight + ")"  }}</span> -->
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content v-if="!item.passed" style="background-color: var(--v-background-base) !important">
                    <v-row>
                      <v-col cols="3">
                        <strong>SSCC</strong>
                      </v-col>
                      <v-col cols="3">
                        <strong>Sequence</strong>
                      </v-col>
                      <v-col cols="3">
                        <strong>Value</strong>
                      </v-col>
                      <v-col cols="3">
                        <strong>StockFile</strong>
                      </v-col>

                    </v-row>
                    <v-divider></v-divider>
                    <v-row v-for="(data, sscc) in item.failedData" :key="sscc">
                      <v-col cols="3">
                        {{ data.sscc }}
                      </v-col>
                      <v-col cols="3">
                        {{ data.sequenceNumber }}
                      </v-col>
                      <v-col cols="3">
                        {{ data.palletQauntity }}
                      </v-col>
                      <v-col cols="3">
                        {{ data.stockFiles }}
                      </v-col>

                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-container>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="saveChangeSnackbar" color="blue" :timeout="3000">Please save changes before creating a
      file.</v-snackbar>
    <v-snackbar v-model="inttraErrorModal" color="red" :multi-line="true" :timeout="-1">
      <span style="font-size: 16px">There was an error submitting the booking to INTTRA:</span>
      <ul>
        <li v-for="(error, i) in inttraErrors" :key="i">
          {{ error.code }}: {{ error.message }}
        </li>
      </ul>
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="inttraErrorModal = false">
          X
        </v-btn>
      </template>
    </v-snackbar>

    <ContainerTrackingDialog ref="containerTrackingDialog"
      :intergrationCode="CONTAINER_TRACKING_INTEGRATION_CODES.HL" :regimeCode="booking.regimeCode"/>
  </div>
  <div v-else style="height: 90vh; padding-top: 0; margin-top: 0" class="text-center">
    <valhalla-loading />
  </div>
</template>
<script>
import AssignManagedStock from "../Stock/AssignManagedStock.vue";
import BookingContainerTracking from "./BookingContainerTracking.vue";
import BookingHeader from "./BookingHeader.vue";
import BookingOverview from "./BookingOverview.vue";
import BookingRelationship from "./BookingRelationship.vue";
import BookingTracking from "./BookingTracking.vue";
import dateFormat from "dateformat";
import ManageContainerMilestone from "./Dialogs/ManageContainerMilestone.vue";
import Products from "../Bookings/Dialogs/ProductsDialog.vue";
import ViewContainer from "./Dialogs/ViewContainer.vue";
import TemporaryProductsModal from "../SystemComponents/TemporaryProductsModal.vue";
import ContainerTrackingDialog from '@/components/Integrations/ContainerTracking/ContainerTrackingDialog.vue';

import { CONTAINER_TRACKING_INTEGRATION_CODES, HL_SHIPMENTLINE_ID } from "@/utils/constants";

export default {
  components: {
    AssignManagedStock,
    BookingContainerTracking,
    BookingHeader,
    BookingOverview,
    BookingRelationship,
    BookingTracking,
    ManageContainerMilestone,
    Products,
    ViewContainer,
    TemporaryProductsModal,
    ContainerTrackingDialog
  },
  data: () => ({
    qxDispatchData: [],
    qxDispatchModal: false,
    pluggedInKey: 120,
    activeUsers: [],
    addServiceModal: false,
    activeContainers: [],
    addedCount: 0,
    addMilestoneModal: false,
    addPoi: false,
    allContainers: [],
    availableBookings: [],
    availableFiles: [],
    availableMilestones: [
      { value: "EMPTY_PICKUP", type: "EXPORT", name: "Empty Pickup" },
      { value: "LOADING_POINT_1", type: "EXPORT", name: "Loading Point 1" },
      { value: "LOADING_POINT_2", type: "EXPORT", name: "Loading Point 2" },
      { value: "GASSING", type: "EXPORT", name: "Gassing" },
      { value: "WEIGH_BRIDGE", type: "EXPORT", name: "Weigh Bridge" },
      { value: "FULL_DROP_OFF", type: "EXPORT", name: "Port Holding" },


      { value: "FULL_PICKUP", type: "LOCAL", name: "Full Pickup" },
      { value: "DELIVERY_POINT_1", type: "LOCAL", name: "Delivery Point 1" },
      { value: "DELIVERY_POINT_2", type: "LOCAL", name: "Delivery Point 2" },
      { value: "DELIVERY_POINT_3", type: "LOCAL", name: "Delivery Point 3" },
      { value: "EMPTY_DROP_OFF", type: "LOCAL", name: "Empty Drop Off" },

      { value: "FULL_PICKUP", type: "IMPORT", name: "Full Pickup" },
      { value: "DELIVERY_POINT_1", type: "IMPORT", name: "Delivery Point 1" },
      { value: "DELIVERY_POINT_2", type: "IMPORT", name: "Delivery Point 2" },
      { value: "DELIVERY_POINT_3", type: "IMPORT", name: "Delivery Point 3" },
      { value: "EMPTY_DROP_OFF", type: "IMPORT", name: "Empty Drop Off" },
    ],
    booking: null,
    bookingCommentModal: false,
    bookingChange: false,
    bookingIntegrationChange: false,
    bookingMoveError: false,
    bookingMoveConfirm: false,
    bookingCreateConfirm: false,
    bookingTimeout: null,
    bookingSearch: null,
    bookingQuery: undefined,

    bookingStatus: [
      { name: "Draft", color: "orange darken-2" },
      { name: "Pending", color: "teal" },
      { name: "Requested", color: "blue" },
      { name: "Confirmed", color: "success" },
      { name: "Rejected", color: "red" },
      { name: "Cancelled", color: "red" },
      { name: "Futile", color: "red" },
    ],
    bookingSubmitWarning: false,
    bookingTypes: [
      { value: "LOGISTICS", icon: "mode_of_travel", color: "blue" },
      // { value: "TRANSPORT", icon: "local_shipping", color: "blue" },
      { value: "DOCUMENTATION", icon: "article", color: "secondary" },
      { value: "HAULAGE", icon: "local_shipping", color: "blue-grey" },
      { value: "MONITORING", icon: "thermostat", color: "teal" },
    ],
    serviceTypes: [
      { value: "FCL" },
      { value: "LCL" },
    ],
    bulkUpdateCard: false,
    bulkEdit: {},
    cargoKey: 1100,
    cargoModal: false,
    cargoItem: {},
    selectedBookingContainerIds: [],
    mailingLoadoutInstr: false,
    colors: [
      { hex: "#04a8f3", name: "Light Blue" },
      { hex: "#0230e7", name: "Blue" },
      { hex: "#7b4cfe", name: "Purple" },
      { hex: "#2dcc6e", name: "Light Green" },
      { hex: "#145930", name: "Green" },
      { hex: "#e55000", name: "Orange" },
      { hex: "#ccb50a", name: "Yellow" },
      { hex: "#e40000", name: "Red" },
      { hex: "#7f0000", name: "Maroon" },
      { hex: "#f800e9", name: "Pink" },
      { hex: "#b4bbc1", name: "Gray" },
      { hex: "#657583", name: "Dark Gray" },
    ],
    commentMenu: false,
    comment: null,
    comments: [],
    commentDialog: false,
    haulierKey: 450,
    internalMessage: true,
    temporaryProducts: [],
    bulkType: "",
    chosenGhostContainers: [],
    confirmCancel: false,
    confirmContainerCancel: false,
    confirmFileCreate: false,
    containerError: null,
    containerPOIModal: false,
    containerProductModal: false,
    containerRemovalType: null,
    containerSlotModal: false,
    saveBulkLoader: false,
    selectedQuantityType: { value: "", text: "" },
    productQuantityTypes: [
      { value: "BG", text: "Bag" },
      { value: "TT", text: "Bag, tote" },
      { value: "BA", text: "Barrel" },
      { value: "BL", text: "Bale" },
      { value: "BI", text: "Bin" },
      { value: "BX", text: "Box" },
      { value: "VL", text: "Bulk, liquid" },
      { value: "VR", text: "Bulk, solid" },
      { value: "BH", text: "Bunch" },
      { value: "BE", text: "Bundle" },
      { value: "CT", text: "Carton" },
      { value: "CS", text: "Case" },
      { value: "CN", text: "Container" },
      { value: "CR", text: "Crate" },
      { value: "DR", text: "Drum" },
      { value: "FB", text: "Flexibag" },
      { value: "JT", text: "Jutebag" },
      { value: "LU", text: "Lug" },
      { value: "PK", text: "Package" },
      { value: "PA", text: "Packet" },
      { value: "PX", text: "Pallet" },
      { value: "PT", text: "Pot" },
      { value: "PO", text: "Pouch" },
      { value: "RO", text: "Roll" },
      { value: "SH", text: "Sachet" },
      { value: "SA", text: "Sack" },
      { value: "SI", text: "Skid" },
      { value: "PU", text: "Tray" },
      { value: "VI", text: "Vial" },
    ],
    containerHeaders: [
      // {
      //     value: 'actions',
      //     text: '',
      //     sortable: false,
      //     width: '100px',
      //     align: 'center',
      //     show: true,
      // },
      {
        value: "status",
        text: "Status",
        align: "center",
        width: "50px",
        show: true,
      },
      {
        value: "ctoNo",
        text: "CTO No.",
        align: "center",
        show: true,
      },
      {
        value: "type",
        text: "Booking Type",
        align: "center",
        show: true,
      },
      {
        value: "shipmentFile.fileNumber",
        text: "File",
        align: "center",
        show: true,
      },
      {
        value: "allocatedPallets",
        text: "Stock",
        align: "center",
        show: true,
      },
      {
        value: "buyer.name",
        text: "Buyer",
        align: "center",
        show: true,
      },
      {
        value: "consignee.name",
        text: "Consignee",
        align: "center",
        show: true,
      },
      {
        value: "customerContainerRef",
        text: "Customer Ref.",
        align: "center",
        show: true,
      },
      {
        value: "containerNo",
        text: "Container No.",
        width: "180px",
        align: "center",
        show: true,
      },
      {
        value: "sealNo",
        text: "Seal No.",
        align: "center",
        width: "180px",
        show: true,
      },
      {
        value: "transportCoordinator.name",
        text: "Transport Coordinator",
        align: "center",
        show: true,
      },
      {
        value: "transporter.name",
        text: "Transporter",
        align: "center",
        show: true,
      },

      {
        value: "exportNotificationStatus",
        text: "Export Notification",
        align: "center",
        show: true,
      },
      {
        value: "nettWeight",
        text: "Nett Weight",
        align: "center",
        show: true,
      },
      {
        value: "grossWeight",
        text: "Gross Weight",
        align: "center",
        show: true,
      },
      {
        value: "totalPallets",
        text: "Pallets",
        align: "center",
        show: true,
      },
      {
        value: "totalPackageCount",
        text: "Package Count",
        align: "center",
        show: true,
      },
      {
        value: "containerTypeCode",
        text: "Type",
        align: "center",
        show: true,
      },
      {
        value: "verificationMethod",
        text: "Method",
        align: "center",
        show: true,
      },
      // {
      //   value: "vents",
      //   text: "Vents",
      //   align: "center",
      //   show: true,
      // },
      {
        value: "features",
        text: "Features",
        align: "center",
        width: "150px",
        show: true,
        sortable: false,
      },
      // {
      //     value: 'regimeCode',
      //     text: 'Regime',
      //     align: 'center',
      //     show: true
      // },
      {
        value: "products",
        text: "Products",
        align: "center",
        // width: '180px',
        show: true,
        sortable: false,
      },
      {
        value: "emptyPickup",
        text: "Empty",
        align: "center",
        show: true,
      },
      {
        value: "emptyPickupTime",
        text: "Empty Pickup Slot",
        align: "center",
        show: true,
      },
      {
        value: "firstLoadingPoint",
        text: "LP-1",
        align: "center",
        show: true,
      },
      {
        value: "firstLoadingPointTime",
        text: "LP-1 Slot",
        align: "center",
        show: true,
      },
      {
        value: "secondLoadingPoint",
        text: "LP-2",
        align: "center",
        show: true,
      },
      {
        value: "secondLoadingPointTime",
        text: "LP-2 Slot",
        align: "center",
        show: true,
      },
      {
        value: "dropOffPoint",
        text: "Port Holding",
        align: "center",
        show: true,
      },
      {
        value: "dropOffPointTime",
        text: "Port Holding Slot",
        align: "center",
        show: true,
      },
      {
        value: "weighBridge",
        text: "Weigh Bridge",
        align: "center",
        show: true,
      },
      {
        value: "weighBridgeTime",
        text: "Weigh Bridge Slot",
        align: "center",
        show: true,
      },
      {
        value: "weighBridgeContract",
        text: "Weigh Bridge Contract",
        align: "center",
        show: true,
      },

      {
        value: 'packingListDocumentId',
        text: 'Packing List',
        align: 'center',
        show: true,

      },
      {
        value: 'loadoutStatus',
        text: 'Loadout Instr.',
        align: 'center',
        show: true,

      },
      {
        value: "dispatchStatus",
        text: "Dispatch",
        align: "center",
        show: true,
      }
      // {
      //   value: "containerComments",
      //   text: "Comments",
      //   align: "center",
      //   show: true,
      // },
    ],
    emailData: {},
    importContainerHeaders: [
      {
        value: "status",
        text: "Status",
        align: "center",
        width: "50px",
        show: true,
      },

      {
        value: "ctoNo",
        text: "CTO No.",
        align: "center",
        show: true,
      },
      {
        value: "shipmentFile.fileNumber",
        text: "File",
        align: "center",
        show: true,
      },
      {
        value: "customerContainerRef",
        text: "Customer Ref.",
        align: "center",
        show: true,
      },
      {
        value: "containerNo",
        text: "Container No.",
        align: "center",
        show: true,
      },
      {
        value: "sealNo",
        text: "Seal No.",
        align: "center",
        show: true,
      },
      {
        value: "nettWeight",
        text: "Nett Weight",
        align: "center",
        show: true,
      },
      {
        value: "grossWeight",
        text: "Gross Weight",
        align: "center",
        show: true,
      },
      {
        value: "transportCoordinator.name",
        text: "Transport Coordinator",
        align: "center",
        show: true,
      },
      {
        value: "transporter.name",
        text: "Transporter",
        align: "center",
        show: true,
      },
      {
        value: "totalPallets",
        text: "Pallets",
        align: "center",
        show: true,
      },
      {
        value: "totalPackageCount",
        text: "Package Count",
        align: "center",
        show: true,
      },
      {
        value: "containerTypeCode",
        text: "Type",
        align: "center",
        show: true,
      },
      // {
      //   value: "vents",
      //   text: "Vents",
      //   align: "center",
      //   show: true,
      // },
      {
        value: "features",
        text: "Features",
        align: "center",
        width: "150px",
        show: true,
        sortable: false,
      },
      {
        value: "products",
        text: "Products",
        align: "center",
        // width: '180px',
        show: true,
        sortable: false,
      },
      {
        value: "fullPickup",
        text: "Pickup",
        align: "center",
        show: true,
      },
      {
        value: "fullPickupTime",
        text: "Pickup Slot",
        align: "center",
        show: true,
      },
      {
        value: "deliveryPoint1",
        text: "Delivery Point 1",
        align: "center",
        show: true,
      },
      {
        value: "deliveryPoint1Time",
        text: "DP-1 Slot",
        align: "center",
        show: true,
      },
      {
        value: "deliveryPoint2",
        text: "Delivery Point 2",
        align: "center",
        show: true,
      },
      {
        value: "deliveryPoint2Time",
        text: "DP-2 Slot",
        align: "center",
        show: true,
      },
      {
        value: "deliveryPoint3",
        text: "Delivery Point 3",
        align: "center",
        show: true,
      },
      {
        value: "deliveryPoint3Time",
        text: "DP-3 Slot",
        align: "center",
        show: true,
      },
      {
        value: "emptyDropOffPoint",
        text: "Drop-Off",
        align: "center",
        show: true,
      },
      {
        value: "emptyDropOffPointTime",
        text: "Drop-Off Slot",
        align: "center",
        show: true,
      },
      // {
      //   value: "containerComments",
      //   text: "Notes",
      //   align: "center",
      //   show: true,
      // },
    ],
    containerHeadersEdit: [
      {
        value: "ctoNo",
        text: "CTO No.",
        align: "center",
        show: true,
      },
      {
        value: "containerTypeCode",
        text: "Type",
        align: "center",
        show: true,
      },
      {
        value: "verificationMethod",
        text: "Method",
        align: "center",
        show: true,
      },
      {
        value: "features",
        text: "Features",
        align: "center",
        width: "150px",
        show: true,
        sortable: false,
      },
      {
        value: "regimeCode",
        text: "Regime",
        align: "center",
        show: true,
      },
      {
        value: "products",
        text: "Products",
        align: "center",
        show: true,
        sortable: false,
      },
      {
        value: "emptyPickup",
        text: "Empty",
        align: "center",
        show: true,
      },
      {
        value: "firstLoadingPoint",
        text: "LP-1",
        align: "center",
        show: true,
      },
      {
        value: "secondLoadingPoint",
        text: "LP-2",
        align: "center",
        show: true,
      },
      {
        value: "dropOffPoint",
        text: "Drop-Off",
        align: "center",
        show: true,
      },
      {
        value: "status",
        text: "Status",
        align: "center",
        show: true,
      },
    ],
    portMonitoringKey: 0,
    lateArrivalKey: 0,
    earlyArrivalKey:0,
    blastFreezingKey: 0,
    voidPlugKey: 0,
    otflowKey: 0,
    zoonoKey: 0,
    industrialKey: 0,
    bulkUpdateItem: {
      Genset: null,
      Otflow: null,
      Zoono: null,
      // Dunnage_Bags: null,
      Void_Plugs: null,
      Late_Arrival: null,
      Early_Arrival: null,
      Fumigation: null,
      monitoringService: null,
      Port_Monitoring: null,
      ContainerLiners: null,
      SteriInsurance: null,
      SmartFresh: null,
      Heavyload: null,
    },
    caProducts: [
      {
        shippingLine: "MSK",
        name: "STARCOOL",
        description: "STARCOOL",
        oxygen: 4,
        carbonDioxide: 6,
      },
      {
        shippingLine: "MSK",
        name: "LIVENTUS",
        description: "LIVENTUS",
        oxygen: 8,
        carbonDioxide: 15,
      },
      {
        shippingLine: "ONE",
        name: "MAXTEND",
        description: "MAXTEND",
        oxygen: 5,
        carbonDioxide: 10,
      },
      {
        shippingLine: "MSC",
        name: "XTENDFR A",
        description: "XTENDFRESH AVOCADOS",
        oxygen: 4,
        carbonDioxide: 6,
      },
      {
        shippingLine: "HPL",
        name: "EXTRAFR B",
        description: "EXTRAFR BERRIES",
        oxygen: 4,
        carbonDioxide: 12,
      },
    ],
    containerItemModal: false,
    containerKey: 2000,
    containerTableKey: 2100,
    containerMessageType: null,
    containerMethodDialog: false,
    containerNoDialog: false,
    containerSave: false,
    containerStatus: [
      { name: "Unallocated", icon: "warning", color: "orange darken-2" },
      { name: "Ghost", icon: "category", color: "blue-grey" },
      { name: "Moved", icon: "exit_to_app", color: "blue" },
      { name: "Allocated", icon: "task_alt", color: "success" },
      { name: "Short shipped", icon: "error", color: "red" },
      { name: "Cancelled", icon: "cancel", color: "red" },
      { name: "Claim", icon: "attach_money", color: "indigo" },
    ],
    containerTypes: [],
    containerTypeKey: 1200,
    contractAddModal: false,
    contractItem: {},
    changesMade: true,
    chosenContainers: [],
    container: null,
    containerStatusKey: 0,
    containerStatusMenuKey: 0,
    countryModal: false,
    countryType: null,
    countries: [],
    creatingNewBooking: false,
    createFileDialog: false,
    createFileError: false,
    customerRefDialog: false,
    customerRef: null,
    customerRefKey: 1000,
    customerRefSave: false,
    customerSearchModal: false,
    dealTermDialog: false,
    deletedCount: 0,
    deletingContract: false,
    etdMenu: false,
    reinstatedCount: 0,
    fileCreateDialog: false,
    files: [],
    file: {},
    fileItem: {},
    fileModal: false,
    fileQuery: null,
    fileTab: 0,
    fileTimeout: null,
    fileSearch: null,
    filterProductType: "Product",
    fullscreen: false,
    haulierModal: false,
    headerKey: 1100,
    imageLoading: false,

    incoTermModal: false,
    incoTerms: [],
    loadingChanges: false,
    loadingContainers: false,
    loadingCountry: false,
    loadingForms: false,
    loadingFunctions: false,
    loadingProfileChange: false,
    loadingProduct: false,

    loadProfiles: false,
    loadingRelatedOrganisations: false,
    loadingShipmentProfiles: false,
    milestoneContainer: {},
    milestoneItem: {},
    milestoneKey: 550,
    moveContainerModal: false,
    moveContainerType: null,
    partyModal: false,
    partyType: null,
    previewShipmentFile: false,
    previewModal: false,
    previewItem: {},
    savingContract: false,
    sealNoDialog: false,
    selectedContainer: null,
    temporaryDisable: true,
    loadingTimeModal: false,
    searchFile: null,
    statusMenuKey: 0,
    selectedShipmentProfile: null,
    tableSearch: null,

    poiFunctions: [],
    poiModal: false,
    poiSearchLoading: false,
    POItoAdd: null,

    menuKey: 0,
    gensetToggleKey: 0,
    resetTimeKey: 0,
    controlledAtmosphereKey: 0,
    sensorDeviceKey: 0,

    productListKey: 0,
    sealNoSave: false,
    quickSearchResults: [],
    regimeCodes: [],
    pointsOfInterest: [],
    quickSearch: null,
    loadingPointItem: {},
    dateSlot: null,
    timeSlot: null,
    loadoutModal: false,
    passCodeSlot: null,
    poiModalDense: false,
    productsModal: false,
    poiSearch: null,
    searchingBookings: false,
    dealTermModal: false,
    selectedBooking: null,
    loadingSaveCustom: false,
    quickSearchTimeout: null,
    poiSearchTimeout: null,
    containerNo: null,
    selectAll: false,
    expanded: [],
    loading: false,
    shipmentProfile: false,
    loadingEdit: false,
    editContainers: false,
    productView: false,
    containerNoKey: 0,
    sealNo: null,
    profiles: [],
    item: {},
    containerItem: {},
    chosenProfile: null,
    emptyPoiContainer: null,
    generatingQ67: false,
    ghostContainer: null,
    ghostContainerModal: false,
    ghostLoading: false,
    ghostModal: false,
    ghostType: null,
    ghostItem: null,
    ghostSplitTypeModal: false,
    ghostReasonList: ["Vessel full", "Order Change"],
    inttraUserModal: false,
    inttraUserSearch: null,
    inttraUsers: [],
    isBulkEdit: false,
    linkedFiles: [],
    linkedOrganisations: [],
    loadingCancel: false,
    loadingCreateFile: false,
    loadingCustomerBooking: false,
    loadingFiles: false,
    loadingLinkedOrganisations: false,
    loadingSchedules: false,
    loadingTransporter: false,
    loadingWeighBridges: false,
    onHoldWarningModal: false,
    deviceOptions: [
      {
        product: "TelemPlus",
        variation: "2G GEO",
        count: 0,
        // monitoringService: "",
      },
      {
        product: "TelemPlus",
        variation: "3G GEO",
        count: 0,
        // monitoringService: "",
      },
      {
        product: "TelemPlus",
        variation: "T4",
        count: 0,
        // monitoringService: "",
      },
      {
        product: "TelemPlus",
        variation: "RF",
        count: 0,
        // monitoringService: "",
      },
      {
        product: "TelemPlus",
        variation: "4G GEO",
        count: 0,
        // monitoringService: "",
      },
      {
        product: "TelemPlus",
        variation: "GEO EAGLE",
        count: 0,
        // monitoringService: "",
      },
      {
        product: "TelemPlus",
        variation: "MOST",
        count: 0,
        // monitoringService: "",
      },
      {
        product: "TelemPlus",
        variation: "2G TIVE",
        count: 0,
        // monitoringService: "",
      },
      {
        product: "TelemPlus",
        variation: "5G TIVE",
        count: 0,
        // monitoringService: "",
      },
      {
        product: "Dunnage Bags",
        variation: "Standard",
        count: 0,
        // monitoringService: "",
      },
      {
        product: "Blast Freezing",
        variation: "Standard",
        count: 0,
        // monitoringService: "",
      },
      {
        product: "Kraft paper",
        variation: "Standard",
        count: 0,
        // monitoringService: "",
      },
      {
        product: "Absorb Bags",
        variation: "Standard",
        count: 0,
        // monitoringService: "",
      },
      {
        product: "Stabling bars",
        variation: "Standard",
        count: 0,
        // monitoringService: "",
      },
      {
        product: "Latches",
        variation: "Standard",
        count: 0,
        // monitoringService: "",
      },
      {
        product: "Ratches",
        variation: "Standard",
        count: 0,
        // monitoringService: "",
      },
      {
        product: "Ethylene Filters",
        variation: "Standard",
        count: 0,
        checked: false

      }
    ],
    addAdditionalProduct: false,
    devices: [
      {
        product: "TelemPlus",
        variation: "2G GEO",
        count: 0,
        // monitoringService: "",
        checked: false,
      },
      {
            product: "TelemPlus",
            variation: "4G PROBE",
            count: 0,
            // monitoringService: "",
            checked: false,
          },
          {
            product: "TelemPlus",
            variation: "STD PROBE",
            count: 0,
            // monitoringService: "",
            checked: false,
          },
      // {
      //   product: "TelemPlus",
      //   variation: "3G GEO",
      //   count: 0,
      //   // monitoringService: "",
      //   checked: false,
      // },
      {
        product: "TelemPlus",
        variation: "T4",
        count: 0,
        // monitoringService: "",
        checked: false,
      },
      {
        product: "TelemPlus",
        variation: "RF",
        count: 0,
        // monitoringService: "",
        checked: false,
      },
      {
        product: "TelemPlus",
        variation: "4G GEO",
        count: 0,
        // monitoringService: "",
        checked: false,
      },
      // {
      //   product: "TelemPlus",
      //   variation: "GEO EAGLE",
      //   count: 0,
      //   // monitoringService: "",
      //   checked: false,
      // },
      {
        product: "TelemPlus",
        variation: "MOST",
        count: 0,
        // monitoringService: "",
        checked: false,
      },
      {
        product: "TelemPlus",
        variation: "2G TIVE",
        count: 0,
        // monitoringService: "",
        checked: false,
      },

      {
        product: "TelemPlus",
        variation: "5G TIVE",
        count: 0,
        // monitoringService: "",
        checked: false,
      },
      {
        product: "TelemPlus",
        variation: "Escavox",
        count: 0,
        monitoringService: "",
        checked: false,
      },
      {
        product: "Dunnage Bags",
        variation: "Standard",
        count: 0,
        // monitoringService: "",
        checked: false,
      },
      {
        product: "Blast Freezing",
        variation: "Standard",
        count: 0,
        // monitoringService: "",
        checked: false,
      },
      {
        product: "Kraft paper",
        variation: "Standard",
        count: 0,
        // monitoringService: "",
        checked: false,
      },
      {
        product: "Absorb Bags",
        variation: "Standard",
        count: 0,
        // monitoringService: "",
        checked: false,
      },
      {
        product: "Stabling bars",
        variation: "Standard",
        count: 0,
        // monitoringService: "",
        checked: false,
      },
      {
        product: "Latches",
        variation: "Standard",
        count: 0,
        // monitoringService: "",
        checked: false,
      },
      {
        product: "Ratches",
        variation: "Standard",
        count: 0,
        // monitoringService: "",
        checked: false,
      },
      {
        product: "Ethylene Filters",
        variation: "Standard",
        count: 0,
        checked: false

      }
    ],
    lateDescription: null,
    earlyDescription: null,
    message: null,
    method: null,
    moveItem: null,
    onlyShowActiveContainers: true,
    poiRequest: undefined,
    poiParams: {
      limit: 50,
      offset: 0,
      search: null,
    },
    params: {
      search: null,
      limit: 15,
      offset: 0,
      filter: {},
    },

    poiItem: null,

    previewKey: 6500,
    profileKey: 550,
    products: {
      data: [],
    },
    productSummary: [],
    headers: [
      {
        text: "System Ref",
        value: "systemReference",
        align: "left",
      },

      {
        text: "Reference",
        value: "reference",
        align: "center",
      },
      {
        text: "Consignee",
        value: "consignee.name",
        align: "center",
      },
      {
        text: "Shipper",
        value: "shipper.name",
        align: "center",
      },

      {
        text: "Forwarder",
        value: "forwarder.name",
        align: "center",
      },
      {
        text: "Origin",
        value: "originCountry.name",
        sortable: false,
        align: "center",
      },
      {
        text: "Loading Ports",
        value: "loadingPorts",
        sortable: false,
        align: "center",
      },
      {
        text: "Destination",
        value: "destinationCountry.name",
        sortable: false,
        align: "center",
      },

      {
        text: "Discharge Ports",
        value: "dischargePorts",
        sortable: false,
        align: "center",
      },
      {
        text: "Inco Term(s)",
        value: "incoTerm",
        sortable: false,
        align: "center",
      },
    ],
    disalbedDispatch: true,
    orderItemStatus: [
      { name: "In Progress", icon: "pending", color: "blue" },
      { name: "Allocated", icon: "fact_check", color: "cyan" },
      { name: "Confirmed", icon: "verified", color: "teal" },
      { name: "Instruction Sent", icon: "email", color: "indigo" },
      { name: "Instruction Cancelled", icon: "warning", color: "orange" },
      { name: "Stuffed", icon: "check_circle_outline", color: "lime" },
      { name: "Stuff Discrepancy", icon: "error", color: "red" },
      { name: "Complete", icon: "check_circle_outline", color: "#4CAF50" },
      { name: "Cancelled", icon: "cancel", color: "red" },

    ],
    palletHeaders: [
      {
        text: "Status",
        value: "status",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Location",
        value: "locationCode",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Identifier",
        value: "sscc",
        allowSort: true,
        allowFilter: false,
      },
      {
        text: "Seq.",
        value: "sequenceNo",
        allowSort: true,
        allowFilter: false,
      },
      {
        text: "Age",
        value: "age",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "PUC",
        value: "puc",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Orch.",
        value: "orchard",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "PHC",
        value: "phc",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Comm.",
        value: "commodityCode",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Var.",
        value: "varietyCode",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Mark",
        value: "mark",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Class",
        value: "grade",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Count/Size",
        value: "countSize",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Cartons",
        value: "cartonQuantity",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Target Region",
        value: "targetRegion",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Target Country",
        value: "targetCountry",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Target Market",
        value: "targetMarket",
        allowSort: true,
        allowFilter: true,
      },
    ],
    createXMLLoading: false,
    incoTermKey: 3500,
    inttraErrors: [],
    inttraErrorModal: false,
    regimeCodeModal: false,
    regimeCodeLoading: false,
    regimeCodeSearch: null,
    related: {
      data: [],
    },
    rules: {
      containerNo(value) {
        if (/\b[A-Z]{4}\d{7}\b/.test(value)) {
          return true;
        }
        return "Container number needs to be in the format: ABCD1234567";
      },
    },
    savingContainerItem: false,
    sailingScheduleModal: false,
    sailingScheduleError: false,
    savingBooking: false,
    savingBulkUpdate: false,
    savingContainerComment: false,
    editButton:false,
    loadingBookingEditComment:false,
    loadingBookingDeleteComment:false,
    newEditedComment: "",
    savingHaulier: false,
    savingParty: false,
    savingWeight: false,
    savingVent: false,
    savingVerificationMethod: false,
    schedules: [],
    searchBooking: null,
    saveChangeSnackbar: false,
    savingCAProduct: false,
    searchContainer: null,
    searchCustomer: null,
    searchCountries: null,

    searchOrganisation: null,
    searchProfiles: null,
    searchShippingLine: null,
    searchTowns: null,
    searchVessel: null,
    selectedCarriers: [],
    selectedContainers: [],
    selectedFile: {},
    selectableParties: [],
    sendingMessage: false,
    gettingLoadoutData: false,
    shipmentParams: {
      carriers: [],
      destination: null,
      destinationPort: null,
      includeNearbyDestinationPorts: false,
      includeNearbyOriginPorts: false,
      origin: null,
      originPort: null,
      searchDate: new Date().toISOString().substr(0, 10),
      searchDateType: "ByDepartureDate",
      services: "",
      scacs: "ANRM, ANNU, CMDU, SUDU, HLCU, MSCU, MAEU, DAL, ONEY, PABV",
      weeksOut: 2,
    },
    shipmentProfileConfig: false,
    shipmentProfileDialog: false,
    shipmentProfiles: {
      data: [],
    },
    shipmentProfileHeaders: [
      {
        text: "System Ref",
        value: "systemReference",
        align: "left",
      },
      {
        text: "Status",
        value: "status",
        align: "center",
      },

      {
        text: "Reference",
        value: "reference",
        align: "center",
      },
      {
        text: "Consignee",
        value: "consignee.name",
        align: "center",
      },
      {
        text: "Shipper",
        value: "shipper.name",
        align: "center",
      },
      {
        text: "Forwarder",
        value: "forwarder.name",
        align: "center",
      },
      {
        text: "Origin",
        value: "originCountry.name",
        sortable: false,
        align: "center",
      },
      {
        text: "Loading Ports",
        value: "loadingPorts",
        sortable: false,
        align: "center",
      },
      {
        text: "Destination",
        value: "destinationCountry.name",
        sortable: false,
        align: "center",
      },

      {
        text: "Discharge Ports",
        value: "dischargePorts",
        sortable: false,
        align: "center",
      },
      {
        text: "Final Dest.",
        value: "finalDestinationCity",
        sortable: false,
        align: "center",
        documentName: null,
      },
      {
        text: "Inco Term(s)",
        value: "incoTerm",
        sortable: false,
        align: "center",
      },
    ],
    stockPallets: [],
    stockPalletLoading: false,
    stockPalletModal: false,
    dropDownBookingType: false,
    shippingLines: [],
    shippingLineModal: false,
    siDueDate: {},
    siDueDateKey: 11000,
    orgUsers: [],
    showMenu: false,
    bookingSplitTypeModal: false,
    routeKey: 1400,
    statuses: [
      { id: 4, state: "AT", color: "#006bc3", location: null },
      { id: 3, state: "ASSIGNED", color: "#0094db", location: null },
      { id: 8, state: "COMPLETED", color: "#008a61", location: null },
      { id: 6, state: "DONE LOADING", color: "#2cc194", location: null },
      { id: 5, state: "EN ROUTE TO", color: "#ccb50a", location: null },
      { id: 7, state: "STOPPED", color: "#e55000", location: null },
      { id: 9, state: "CANCELLED", color: "#ff6666", location: null },
      { id: 10, state: "ISSUE", color: "#7f0000", location: null },
      { id: 2, state: "PENDING", color: "#657583", location: null },
      { id: 1, state: "TO BE ALLOCATED", color: "#b4bbc1", location: null },
    ],
    bulkUpdateModal: false,
    statusLocations: [
      { name: "LOADING POINT 1", value: "LP1" },
      { name: "LOADING POINT 2", value: "LP2" },
      { name: "EMPTY DEPOT", value: "EMPTY" },
      { name: "PORT OF LOADING", value: "POL" },
      { name: "DROP OFF", value: "DROPOFF" },
      { name: "YARD", value: "YARD" },
      { name: "FUEL STATION", value: "FUEL STATION" },
    ],
    statusFilters: {
      at: false,
      assigned: false,
      completed: false,
      "done Loading": false,
      "en Route To": false,
      stopped: false,
      cancelled: false,
      issue: false,
      pending: false,
      "to Be Allocated": false,
    },
    statusUpdate: 1200,
    submittingBooking: false,
    suggestedDealTerms: [],
    systemNotes: null,
    tab: 0,
    toggleContainerView: 0,
    transportItem: {},
    transporters: [],
    userUpdateKey: 150,
    ventSettings: [
      "Closed",
      "5",
      "10",
      "15",
      "20",
      "25",
      "28",
      "30",
      "35",
      "40",
      "50",
      "Fully Open",
      "CA",
      "MA",
      "Auto",
    ],
    viewContainerModal: false,
    viewChangesModal: false,
    ventDialog: false,
    verificationMethod: null,

    voyageNumberModal: false,
    weighBridges: [],
    weightDialog: false,
    weekSelection: [
      {
        value: 2,
        label: "2 Weeks",
      },
      {
        value: 4,
        label: "4 Weeks",
      },
      {
        value: 6,
        label: "6 Weeks",
      },
      {
        value: 8,
        label: "8 Weeks",
      },
    ],
    x: null,
    y: null,
    // TEMPORARY IMPORT OF PROPHET CODE FOR TESTING
    creatingDispatch: false,
    CONTAINER_TRACKING_INTEGRATION_CODES
  }),
  async created() {
    this.loading = true;
    // let headerView = await this.$API.getBookingUserCustom();
    // if (headerView) {
    //   this.updateHeaders(headerView);
    // }
    await this.getRelations();

    this.loading = false;
    // this.regimeCodes = await this.$API.getRegimeCodes();
    this.getPoiFunctions();

    // this.containerTypes = await this.$API.listContainerTypes()
    this.getCarrierList();
    this.loadCountries();
    this.loadProducts();
    this.getTransporters();
    this.getOrganisationUsers();
    this.loadingWeighBridges = true;
    this.weighBridges = await this.$API.getPOIByFunction("Weigh-Bridge");
    this.loadingWeighBridges = false;
  },
  unmounted() {
    this.$socket.send(
      JSON.stringify({
        action: "leave",
        room: "booking_" + this.booking.id,
        user: {
          id: this.$store.state.user.id,
          name:
            this.$store.state.user.firstname +
            " " +
            this.$store.state.user.surname,
          avatar: this.$store.state.user.avatar,
          company: this.$store.state.currentOrg.name,
        },
      })
    );
    delete this.$socket.onmessage;
  },
  beforeDestroy() {
    this.$socket.send(
      JSON.stringify({
        action: "leave",
        room: "booking_" + this.booking.id,
      })
    );
  },
  watch: {
    // "stock.ccAdresses":  {
    //   handler(val) {
    //     if (val) {
    //     this.replayAddr()
    //     }
    //   }
    // },
    // "stock.replyAdresses": {
    //   handler(val) {
    //     if (val) {
    //     this.replayAddr()
    //     }
    //   }
    // },
    "chosenContainers.length": {
      immediate: true,
      handler(val) {
        this.disableDispatch()
      }
    },
    //check for any changes in emailAdresses.stockData.emailAdresses or stockData.ccAdresses
    "emailData.stockData": {
      handler(val) {
        console.log('change in stockData')
        if (val) {
          this.replyAdrr(val)
        }
      },
      deep: true
    },
    "containerItem.pluggedInRequired": function (newVal) {
      if (newVal) {
        this.containerItem.transportStatus = "Arrange Plugged In";
      } else {
        this.containerItem.transportStatus = "Unallocated";
      }
    },
    "booking.customerId": {
      immediate: true,
      async handler(val) {
        if (val) {
          this.loadingLinkedOrganisations = true;
          this.linkedOrganisations = await this.$API.getCustomerRelationships(
            val
          );
          this.loadingLinkedOrganisations = false;
        }
      },
    },
    "booking.regimeId": {
      immediate: true,
      handler(val) {
        if (val) {
          this.getRegimeCodeSettings();
        }
      },
    },
    "booking.sailingScheduleId": {
      immediate: true,
      async handler(val) {
        this.routeKey++;
      },
    },
    poiSearch: {
      immediate: true,
      handler(val) {
        if (val) {
          this.searchPois(val);
        }
      },
    },
    // deletedCount: {
    //   immediate: true,
    //   handler(val) {
    //     if (val > 0) {
    //       this.changesMade = true;
    //     } else if (this.addedCount > 0) {
    //       this.changesMade = true;
    //     } else if (this.reinstatedCount > 0) {
    //       this.changesMade = true;
    //     } else {
    //       this.changesMade = false;
    //     }
    //   },
    // },
    // addedCount: {
    //   immediate: true,
    //   handler(val) {
    //     if (val > 0) {
    //       this.changesMade = true;
    //     } else if (this.deletedCount > 0) {
    //       this.changesMade = true;
    //     } else if (this.reinstatedCount > 0) {
    //       this.changesMade = true;
    //     } else {
    //       this.changesMade = false;
    //     }
    //   },
    // },
    reinstatedCount: {
      immediate: true,
      handler(val) {
        if (val > 0) {
          this.changesMade = true;
        } else if (this.deletedCount > 0) {
          this.changesMade = true;
        } else if (this.addedCount > 0) {
          this.changesMade = true;
        } else {
          this.changesMade = false;
        }
      },
    },
    "$route.params.systemReference": {
      immediate: true,
      handler(val) {
        if (val) {
          this.tab = 0;
          this.getBooking(val);
        }
      },
    },

    async quickSearch(val) {
      if (this.searchingBookings) {
        clearTimeout(this.quickSearchTimeout);
      }
      if (val && val.length > 2) {
        this.quickSearchTimeout = setTimeout(async () => {
          this.searchingBookings = true;
          this.quickSearchResults = await this.$API.quickSearchBookings({
            params: {
              search: val,
              limit: 15,
            },
          });
          this.searchingBookings = false;
        }, 200);
        this.menuKey++;
        this.searchingBookings = false;
      } else {
        clearTimeout(this.quickSearchTimeout);
        this.searchingBookings = false;
      }
    },
  },
  computed: {
    checkContainerCargo(){
      let data = this.booking.bookingContainers ?? []
      return data.filter(
    (x) =>
      !x.isDeleted &&
      (x.status == "Allocated" || x.status == null || x.status == 'Short Shipped') &&
      x.bookingId == this.booking.id
  ).length == 0
    
    },
    checkContainerProducts(){
      let data = this.booking.bookingContainers ?? []
      let concatResult = [].concat.apply(
    [],
    data
      .filter(
        (x) =>
          !x.isDeleted &&
          (x.status == "Allocated" || x.status == null || x.status == 'Short Shipped') &&
          x.bookingId == this.booking.id
      )
      .map((x) => x.containerProducts)
  )
      return concatResult.length == 0 
    
    },
    filteredTerms() {
      if (!this.file || !this.file.consigneeProfile) {
        return [];
      }
      return this.file.consigneeProfile.consigneeProfileIncoTerms.filter(term =>
        term.contractOwner === this.booking.contractOwner
      );
    },
    disabledLoadoutMail() {
      if (this.emailData && this.emailData.stockData) {
        return this.emailData.stockData.every(stock =>

          stock.emailAdresses && stock.emailAdresses.trim() !== '' && stock.containers.every(container => container.totalPallets > 0)
        ) === false;

      }
      return true
    },
    avoBox() {
      let result = this.onlyShowActiveContainers
        ? this.activeContainers
        : this.allContainers;

      return result.some((x) =>
        x.containerProducts.some((y) => y.productId == 226)
      );
    },

    filterCustomerOrganisations() {
      let result = this.linkedOrganisations ?? [];
      if (this.booking.customer) {
        let find = result.find(
          (x) => x.relatedOrganisationId == this.booking.customerId
        );
        if (!find) {
          result.push({
            relatedOrganisation: this.booking.customer,
            relatedOrganisationId: this.booking.customerId,
          });
        }
      }
      if (this.searchOrganisation) {
        result = result.filter(
          (organisation) =>
            organisation.relatedOrganisation.name
              .toLowerCase()
              .includes(this.searchOrganisation.toLowerCase()) ||
            (organisation.relatedOrganisation.alias &&
              organisation.relatedOrganisation.alias
                .toLowerCase()
                .includes(this.searchOrganisation.toLowerCase()))
        );
      }
      result.sort((a, b) =>
        a.relatedOrganisation.name > b.relatedOrganisation.name
          ? 1
          : b.relatedOrganisation.name > a.relatedOrganisation.name
            ? -1
            : 0
      );
      return result;
    },
    filteredUsers() {
      let result = this.orgUsers;
      if (this.inttraUserSearch) {
        result = result.filter(
          (x) =>
            x.user.firstname
              .toLowerCase()
              .includes(this.inttraUserSearch.toLowerCase()) ||
            x.user.surname
              .toLowerCase()
              .includes(this.inttraUserSearch.toLowerCase()) ||
            x.user.emailAddress
              .toLowerCase()
              .includes(this.inttraUserSearch.toLowerCase())
        );
      }
      result.sort((a, b) => a.user.firstname.localeCompare(b.user.firstname));
      return result;
    },
    fullRoute() {
      console.log("booking", this.booking);
      let result = [];
      let setTypes = [
        { type: "Port of Load", locode: this.booking.portOfLoadValue },
        {
          type: "Port of Discharge",
          locode: this.booking.portOfDischargeValue,
        },
        {
          type: "Final Destination",
          locode: this.booking.finalDestinationValue,
        },
      ];

      // POL
      result.push(setTypes[0]);

      // Transhipments
      if (
        this.booking.sailingSchedule &&
        this.booking.sailingSchedule.sailingScheduleLegs.length > 1
      ) {
        result.push({
          type: "TS Port 1",
          locode:
            this.booking.sailingSchedule.sailingScheduleLegs[0]
              .portOfDischargeValue,
        });

        // for (let i = 0; i < this.booking.sailingSchedule.sailingScheduleLegs.length; i++) {
        //   let find = setTypes.find((x) => x.locode == this.booking.sailingSchedule.sailingScheduleLegs[i].portOfDischargeValue);
        //   let findExsist = result.find((x) => x.locode == this.booking.sailingSchedule.sailingScheduleLegs[i].portOfDischargeValue);
        //   if (!find && !findExsist) {
        //     result.push({
        //       type:
        //         "TS Port " +
        //         this.booking.sailingSchedule.sailingScheduleLegs[i].leg,
        //       locode:
        //         this.booking.sailingSchedule.sailingScheduleLegs[i].portOfDischargeValue,
        //     });
        //   }
        // }
      }

      // POD & FD
      if (
        this.booking.portOfDischargeValue === this.booking.finalDestinationValue
      ) {
        result.push({
          type: "POD & Final Destination",
          locode: this.booking.finalDestinationValue,
        });
      } else {
        result.push(setTypes[1]);
        result.push(setTypes[2]);
      }
      return result;
    },
    hauliers() {
      let result = [
        {
          index: 2,
          type: "Carrier Haulage",
          name: "Carrier Haulage",
          logo: null,
          icon: "directions_boat",
          flag: "isCarrierHaulage",
        },
        {
          index: 4,
          type: "Merchant Haulage",
          name: "Merchant Haulage",
          icon: "local_shipping",
          logo: null,
          flag: "isPrivateHaulage",
        },
        {
          index: 3,
          type: "Railage",
          name: "Rail",
          icon: "train",
          logo: null,
          flag: "isRailage",
        },
      ];
      return result;
    },
    hasProductsCount() {
      return Object.values(this.bulkUpdateItem).some((x) => x === true);
    },
    hasProducts() {
      let finalProducts = [];
      let products = Object.keys(this.bulkUpdateItem).filter(
        (x) => this.bulkUpdateItem[x] === true
      );
      products = products.map((x) => x.replace(/_/g, " "));
      products.forEach((product) => {
        let productObject = {
          product: product,
          selected: true,
        };
        finalProducts.push(productObject);
      });
      return finalProducts;
    },
    hasDevicesWithCount() {
      return this.devices.some((device) => device.count > 0);
    },
    hasDevices() {
      let devices = this.devices.filter((device) => device.count > 0);
      return devices;
    },

    uniqueProducts() {
      const unique = new Set();
      const result = [];
      for (const device of this.devices) {
        //check device.variation if it is Standard and does not exist need to be added

        if (!unique.has(device.product)) {
          unique.add(device.product);
          result.push(device.product);
        }
      }
      return result;
    },

    containerList() {
      // let containers = []
      let containers = this.booking.bookingContainers ?? [];
      if (this.selectAll && containers) {
        containers.forEach((container) => {
          container.selected = true;
        });
      }

      if (!this.selectAll && containers) {
        containers.forEach((container) => {
          container.selected = false;
        });
      }
      return containers;
    },
    availableParties() {
      let keys = [
        "Shipper",
        "Forwarder",
        "Consignee",
        "Notify Party",
        "Shipping Line",
        "Other",
      ];
      return keys;
    },

    availableDealTerms() {
      let terms = [];
      if (this.chosenProfile) {
        for (
          let i = 0;
          i < this.chosenProfile.consigneeProfileIncoTerms.length;
          i++
        ) {
          let term = this.chosenProfile.consigneeProfileIncoTerms[i];
          if (term.consigneeProfileShippingContracts.length != 0) {
            let result = term.consigneeProfileShippingContracts
              .filter((x) => x.shippingLine)
              .map((y) => ({
                id: term.id,
                incoTerm: term.incoTerm,
                comment: term.comment,
                shippingLine: y.shippingLine,
                scacCode: y.shippingLine.scacCode,
                contractNumber: y.contractNumber,
                contractOwner: y.contractOwner,
                paymentTerms: {
                  paymentTerm: term.paymentTerm,
                  originCharge: term.originCharge,
                  freightCharge: term.freightCharge,
                  destinationCharge: term.destinationCharge,
                },
              }));
            terms.push(...result);
          } else {
            let result = {
              id: term.id,
              incoTerm: term.incoTerm,
              comment: term.comment,
              shippingLine: null,
              scacCode: null,
              contractNumber: null,
              contractOwner: null,
              paymentTerms: {
                paymentTerm: term.paymentTerm,
                originCharge: term.originCharge,
                freightCharge: term.freightCharge,
                destinationCharge: term.destinationCharge,
              },
            };
            terms.push({ ...result });
          }
        }
      }
      return terms;
    },
    displayReeferSettings() {
      let result = false;
      if (this.containerItem && this.containerItem.containerTypeCode) {
        let findType = this.containerTypes.find(
          (x) => x.typeCode == this.containerItem.containerTypeCode
        );
        result = findType && findType.typeCategory == "Reefer";
      }
      return result;
    },

    displayContainers() {
      let result = this.onlyShowActiveContainers
        ? this.activeContainers
        : this.allContainers;
      if (this.tableSearch) {
        result = result.filter(
          (x) =>
            (x.containerNo &&
              x.containerNo
                .toLowerCase()
                .includes(this.tableSearch.toLowerCase())) ||
            (x.sealNo &&
              x.sealNo
                .toLowerCase()
                .includes(this.tableSearch.toLowerCase())) ||
            (x.customerContainerRef &&
              x.customerContainerRef
                .toLowerCase()
                .includes(this.tableSearch.toLowerCase())) ||
            (x.verificationMethod &&
              x.verificationMethod
                .toLowerCase()
                .includes(this.tableSearch.toLowerCase())) ||
            (x.shipmentFile &&
              x.shipmentFile.fileNumber
                .toLowerCase()
                .includes(this.tableSearch.toLowerCase())) ||
            (x.EMPTY_PICKUP &&
              x.EMPTY_PICKUP.description
                .toLowerCase()
                .includes(this.tableSearch.toLowerCase())) ||
            (x.LOADING_POINT_1 &&
              x.LOADING_POINT_1.description
                .toLowerCase()
                .includes(this.tableSearch.toLowerCase())) ||
            (x.LOADING_POINT_2 &&
              x.LOADING_POINT_2.description
                .toLowerCase()
                .includes(this.tableSearch.toLowerCase())) ||
            (x.FULL_DROP_OFF &&
              x.FULL_DROP_OFF.description
                .toLowerCase()
                .includes(this.tableSearch.toLowerCase())) ||
            (x.WEIGH_BRIDGE &&
              x.WEIGH_BRIDGE.description
                .toLowerCase()
                .includes(this.tableSearch.toLowerCase())) ||
            x.containerProducts.some((y) =>
              y.product.name
                .toLowerCase()
                .includes(this.tableSearch.toLowerCase())
            )
        );
      }
      return result;
    },
    filterAvailableBookings() {
      let result = this.availableBookings.filter(
        (x) => x.id != this.booking.id
      );
      if (this.searchBooking) {
        result = result.filter(
          (x) =>
            x.orderNo
              .toLowerCase()
              .includes(this.searchBooking.toLowerCase()) ||
            (x.shipper &&
              x.shipper.name
                .toLowerCase()
                .includes(this.searchBooking.toLowerCase())) ||
            (x.consignee &&
              x.consignee.name
                .toLowerCase()
                .includes(this.searchBooking.toLowerCase())) ||
            (x.portOfLoadValue &&
              x.portOfLoadValue
                .toLowerCase()
                .includes(this.searchBooking.toLowerCase())) ||
            (x.finalDestinationValue &&
              x.finalDestinationValue
                .toLowerCase()
                .includes(this.searchBooking.toLowerCase())) ||
            (x.mainCarriageVesselName &&
              x.mainCarriageVesselName
                .toLowerCase()
                .includes(this.searchBooking.toLowerCase()))
        );
      }
      return result;
    },
    filterContainers() {
      let result = this.booking.bookingContainers ?? [];
      result = result.filter(
        (x) =>
          !x.isDeleted &&
          x.bookingId == this.booking.id &&
          x.status != "Ghost" &&
          x.status != "Cancelled"
      );
      if (this.searchContainer) {
        result = result.filter(
          (x) =>
            (x.containerNo &&
              x.containerNo
                .toLowerCase()
                .includes(this.searchContainer.toLowerCase())) ||
            (x.sealNo &&
              x.sealNo
                .toLowerCase()
                .includes(this.searchContainer.toLowerCase())) ||
            (x.ctoNo &&
              x.ctoNo
                .toLowerCase()
                .includes(this.searchContainer.toLowerCase())) ||
            (x.buyer &&
              x.buyer.alias &&
              x.buyer.alias
                .toLowerCase()
                .includes(this.searchContainer.toLowerCase())) ||
            (x.buyer &&
              x.buyer.name &&
              x.buyer.name
                .toLowerCase()
                .includes(this.searchContainer.toLowerCase())) ||
            (x.consignee &&
              x.consignee.alias &&
              x.consignee.alias
                .toLowerCase()
                .includes(this.searchContainer.toLowerCase())) ||
            (x.consignee &&
              x.consignee.name &&
              x.consignee.name
                .toLowerCase()
                .includes(this.searchContainer.toLowerCase()))
        );
      }
      return result;
    },
    filterAvailableFiles() {
      let result = this.availableFiles;
      if (this.fileItem && this.fileItem.shipmentFileId) {
        result = result.filter((x) => x.id != this.fileItem.shipmentFileId);
      }
      if (this.searchFile) {
        result = result.filter((x) =>
          x.fileNumber.toLowerCase().includes(this.searchFile.toLowerCase())
        );
      }
      return result;
    },
    filteredProfiles() {
      let result = this.shipmentProfiles.data.filter(
        (x) =>
          x.status == "Approved" &&
          x.originCountryId === this.booking.originCountryId &&
          x.consigneeProfileIncoTerms.some(
            (y) => y.contractOwner === this.booking.contractOwner)
          &&
          x.destinationCountryId === this.booking.destinationCountryId &&
          (!x.finalDestinationValue ||
            (x.finalDestinationValue &&
              x.finalDestinationValue === this.booking.finalDestinationValue))
      );
      if (this.search) {
        result = result.filter(
          (x) =>
            (x.systemReference &&
              x.systemReference
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.reference &&
              x.reference
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.buyer &&
              x.buyer.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.forwarder &&
              x.forwarder.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.shipper &&
              x.shipper.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.consignee &&
              x.consignee.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.onBehalfForwarder &&
              x.onBehalfForwarder.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.onBehalfShipper &&
              x.onBehalfShipper.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.onBehalfConsignee &&
              x.onBehalfConsignee.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.consigneeProfileIncoTerms &&
              x.consigneeProfileIncoTerms
                .map((y) => y.incoTerm.toLowerCase())
                .includes(this.searchProfiles.toLowerCase()))
        );
      }
      return result;
    },
    filterRegimeCodes() {
      let result = this.regimeCodes;
      if (this.regimeCodeSearch) {
        result = result.filter((x) =>
          x.code.toLowerCase().includes(this.regimeCodeSearch.toLowerCase())
        );
      }
      return result;
    },

    filterProducts() {
      let result = this.products.data.filter((x) => x.hsCode);
      if (result && this.filterProductType) {
        result = result.filter((x) => x.type == this.filterProductType);
      }
      return result;
    },
    reeferShipment() {
      let containers = this.booking.bookingContainers.filter(
        (x) =>
          !x.isDeleted &&
          (x.status == "Allocated" || x.status == null || x.status == "Short Shipped") &&
          x.bookingId == this.booking.id
      );
      let findType = this.containerTypes.find(
        (x) =>
          containers.map((y) => y.containerTypeCode).includes(x.typeCode) &&
          x.typeCategory == "Reefer"
      );
      return findType ? true : false;
    },
    canViewContainerTelemetry() {
      // const isSystemAdmin = [1, 2].includes(this.$store.state.user.tagId);

      // return (isSystemAdmin && this.booking.shippingLineId === HL_SHIPMENTLINE_ID);
      return this.booking.shippingLineId === HL_SHIPMENTLINE_ID

    },
    menuItems() {
      const items = [
        { name: "View", color: "grey", icon: "launch", action: "view_container" },
        {
          name: "Move",
          color: "blue",
          icon: "exit_to_app",
          action: "move_container",
        },
        {
          name: "Clone",
          color: "teal",
          icon: "content_copy",
          action: "content_copy",
        },
        {
          name: "Mark as Ghost",
          color: "blue-grey",
          icon: "category",
          action: "mark_ghost",
        },
        // {
        //   name: "Mark as Claim",
        //   color: "indigo",
        //   icon: "attach_money",
        //   action: "mark_claim",
        // },
        {
          name: "Remove",
          color: "red",
          icon: "cancel",
          action: "remove_container",
        },
        {
          name: "Reinstate Container",
          color: "blue-grey",
          icon: "autorenew",
          action: "reinstate_container",
        }
      ]

      if (this.container && this.container.containerNo && this.canViewContainerTelemetry) {
        items.push({
          name: "Container Telemetry",
          color: "purple",
          icon: "timeline",
          action: "container_telemetry",
        })
      }

      return items
    }
  },
  methods: {

    openBookingDialog(comment) {
      this.loadingBookingEditComment = false;
      this.newEditedComment = comment
      this.editButton = true;
   
    },
    closeBookingEditDialog() {
      this.editButton = false;
     
    },
    async deleteBookingComment(id){
    this.loadingBookingDeleteComment = true;
     let result =  await this.$API.deleteBookingComment({
          id: id,
          isDeleted: true,
          isActive: false,
          bookingId: this.booking.id
        });
        if(result){
          this.loadingBookingDeleteComment = false;
          this.containerComments = result
          this.getComments(this.booking.id);
        }
    },
    async updateBookingComment(id){
      this.loadingBookingEditComment = true;
     let result =  await this.$API.updateBookingComment({
          id: id,
          isDeleted: true,
          isActive: false,
          bookingId: this.booking.id,
          userId : this.newEditedComment.userId,
          organisationId:this.booking.organisationId,
          comment:this.newEditedComment.comment
        });
        if(result){
          this.containerComments = result;
          this.closeBookingEditDialog();
          this.getComments(this.booking.id);
          this.loadingBookingEditComment = false;
          
        }
    },
    disableDispatch() {
      if (this.chosenContainers.length === 0) {
        this.disalbedDispatch = true
      }
      else {
        let disableStatus = this.chosenContainers.every(container => container.shipmentFile && container.shipmentFile.ucrNo)
        this.disalbedDispatch = !disableStatus
        // return this.disableDispatch        
      }
    },

    getTextClass(pallets) {
      return pallets === 0 ? 'red-text' : '';
    },
    replyAdrr(stock) {
      if (stock.replyAdresses && stock.ccAdresses) {
        return "** Reply addresses will be used for replying to messages.";
      } else if (stock.replyAdresses && !stock.ccAdresses) {
        return "** Reply addresses will be used for replying to messages.";
      } else if (!stock.replyAdresses && stock.ccAdresses) {
        return "** CC addresses will be used for reply addresses.";
      } else if (!stock.replyAdresses && !stock.ccAdresses) {
        return "** No reply addresses will be added if CC or Reply fields are empty.";
      }
      return "** Default case or no data available.";
    },


    async emailLoadout() {
      this.mailingLoadoutInstr = true
      let result = await this.$API.emailLoadOutInstructions(this.emailData)
      console.log('result', result)
      this.chosenContainers = []
      this.mailingLoadoutInstr = false
      this.loadoutModal = false
    },
    async getLoadOutMailData() {
      this.gettingLoadoutData = true
      let containerMilestones = this.chosenContainers.map((x) => x.containerMilestones)
      let result = []
      containerMilestones.forEach((x) => {
        let find = x.filter((y) => y.type == "LOADING_POINT_1" || y.type == "LOADING_POINT_2")
        result.push(...find)
      })

      let uniquePOI = [...new Set(result.map(x => x.poiId))]

      let tempPoiData = []

      for (let i = 0; i < uniquePOI.length; i++) {
        let containerIds = this.chosenContainers.filter((x) => x.containerMilestones.some((y) => y.poiId === uniquePOI[i])).map((x) => x.id);
        let poiData = {
          poiId: uniquePOI[i],
          containerIds: containerIds
        }
        tempPoiData.push(poiData)
      }
      let stockData = await this.$API.getStockLoadoutDetails(tempPoiData)
      let dates = this.formatDate(new Date())

      this.emailData = {
        vessel: this.booking.mainCarriageVesselName,
        pol: this.booking.portOfLoadValue,
        finalDest: this.booking.finalDestinationValue,
        // reicerName: this.booking.receiverName,
        temp: this.booking.regime.setPointTemp + ' ' + '(' + this.booking.regime.code + ')',
        stockData: stockData,
        voyage: this.booking.mainCarriageConveyanceNumber,
        pod: this.booking.portOfDischargeValue,
        title: 'LOAD OUT' + ' - ' + this.booking.mainCarriageVesselName + ' & ' + this.booking.mainCarriageConveyanceNumber + ' - ' + this.booking.shipper.name + ' - ' + dates.isoFormat + ' ' + dates.time
      }

      this.gettingLoadoutData = false
      this.loadoutModal = true

    },
    async bulkGenerateLAR() {
      let bookingContainerIds = this.chosenContainers.map((x) => x.id);
      let obj = {
        bookingContainerIds: bookingContainerIds,
      };
      let result = await this.$API.bulkGenerateLAR(obj);
      this.chosenContainers = [];
    },
    changeDropDownBooking(dropDownBookingType) {
      dropDownBookingType = !dropDownBookingType;
    },
    addInttraUser(email) {
      let find = this.inttraUsers.indexOf(email);
      if (find == -1) {
        this.inttraUsers.push(email);
      } else {
        this.inttraUsers.splice(find, 1);
      }
    },
    toggleCheckbox(product) {
      product.count = product.count === 0 ? 1 : 0;
    },
    addServices() {
      this.addServiceModal = true;
    },

    updateProductCount(product) {
      //check the devices for the product and varaition
      let count = parseInt(product.count);
      this.devices.forEach((x) => {
        if (x.product == product.name && x.variation == product.variation) {
          x.count = count;
        }
        if (x.count < 1) {
          x.checked = 0;
        }
      });
    },
    closeBulkUpdate() {
      (this.devices = [
      {
        product: "TelemPlus",
        variation: "Escavox",
        count: 0,
        // monitoringService: "",
        checked: false,
      },
        {
          product: "TelemPlus",
          variation: "2G GEO",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        {
            product: "TelemPlus",
            variation: "4G PROBE",
            count: 0,
            // monitoringService: "",
            checked: false,
          },
          {
            product: "TelemPlus",
            variation: "STD PROBE",
            count: 0,
            // monitoringService: "",
            checked: false,
          },
        // {
        //   product: "TelemPlus",
        //   variation: "3G GEO",
        //   count: 0,
        //   // monitoringService: "",
        //   checked: false,
        // },
        {
          product: "TelemPlus",
          variation: "T4",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        {
          product: "TelemPlus",
          variation: "RF",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        {
          product: "TelemPlus",
          variation: "4G GEO",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        // {
        //   product: "TelemPlus",
        //   variation: "GEO EAGLE",
        //   count: 0,
        //   // monitoringService: "",
        //   checked: false,
        // },
        {
          product: "TelemPlus",
          variation: "MOST",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        {
          product: "TelemPlus",
          variation: "2G TIVE",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        {
          product: "TelemPlus",
          variation: "5G TIVE",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        {
          product: "Dunnage Bags",
          variation: "Standard",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        {
          product: "Blast Freezing",
          variation: "Standard",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        {
          product: "Kraft paper",
          variation: "Standard",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        {
          product: "Absorb Bags",
          variation: "Standard",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        {
          product: "Stabling bars",
          variation: "Standard",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        {
          product: "Latches",
          variation: "Standard",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        {
          product: "Ratches",
          variation: "Standard",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        {
          product: "Ethylene Filters",
          variation: "Standard",
          count: 0,
          checked: false

        }
      ]),
        (this.bulkUpdateItem = {
          Genset: null,
          Otflow: null,
          Zoono: null,
          // Dunnage_Bags: null,
          Void_Plugs: null,
          Late_Arrival: null,
          Early_Arrival: null,
          Fumigation: null,
          monitoringDevice: null,
          Port_Monitoring: null,
          ContainerLiners: null,
          Heavyload: null,
          SmartFresh: null,
        });
      this.bulkUpdateModal = false;
    },
    async addProducts() {
      this.saveBulkLoader = true;
      this.bulkUpdateModal = false;
      this.saveBulkLoader = false;
    },
    async saveBulkUpdate() {
      this.saveBulkLoader = true;
      let products = this.bulkUpdateItem;
      // debugger
      this.devices = this.devices.map((x) => {
        if (typeof x.count == "string") {
          x.count = parseInt(x.count);
        }
        return x;
      });
      let finalProducts = [...this.devices];
      let newKey = "";
      Object.keys(products).forEach((key) => {
        let obj = {};
        newKey = key;
        if (key.includes("_")) {
          newKey = key.replace("_", " ");
        }

        if (products[key] !== null) {
      
          if (products[key] == true && newKey != "Late Arrival" && newKey !="Early Arrival") {
            obj = {
              product: newKey,
              variation: "Standard",
              count: 1,
            };

            
          } else if (products[key] == true && newKey == "Late Arrival") {
            obj = {
              product: newKey,
              variation: this.lateDescription,
              count: 1,
            };
            console.log(obj + 'late')
          }
          else if (products[key] == true && newKey == "Early Arrival") {
            obj = {
              product: newKey,
              variation: this.earlyDescription,
              count: 1,
            };
            console.log(obj + 'early')
          }
          
          else if (products[key] == true && newKey == "SteriInsurance") {
            obj = {
              product: "Steri Insurance",
              variation: "Standard",
              count: 1,
            };
          }
          else if (products[key] == true && newKey == "SmartFresh") {
            obj = {
              product: "Smart Fresh",
              variation: "Standard",
              count: 1,
            };
          }
          else {
            obj = {
              product: newKey,
              variation: "Standard",
              count: 0,
            };
          }
          finalProducts.push(obj);
        }
      });
      finalProducts = finalProducts.map((x) => {
        if (x.checked) {
          x.checked = null;
        }
        return x;
      });

      let monitoringService = this.bulkUpdateItem.monitoringService;
      console.log("Monitoring service bulk update", this.bulkUpdateItem);
      if (monitoringService) {
        finalProducts.push({
          product: "Monitoring Service",
          variation: monitoringService,
          count: 1,
          monitoringService: monitoringService,
        });
      }
      // debugger
      let update = await this.$API.bulkUpdateAllContainersProducts({
        bookingContainerIds: this.selectedBookingContainerIds,
        products: finalProducts,
        monitoringService: monitoringService,
      });
      if (update.status == 200) {
        this.bookingContainerIds = [];
        (this.devices = [
    
          {
            product: "TelemPlus",
            variation: "2G GEO",
            count: 0,
            // monitoringService: "",
          },
          {
            product: "TelemPlus",
            variation: "4G PROBE",
            count: 0,
            // monitoringService: "",
          },
          {
            product: "TelemPlus",
            variation: "STD PROBE",
            count: 0,
            // monitoringService: "",
          },
          // {
          //   product: "TelemPlus",
          //   variation: "3G GEO",
          //   count: 0,
          //   // monitoringService: "",
          // },
          {
            product: "TelemPlus",
            variation: "T4",
            count: 0,
            // monitoringService: "",
          },
          {
            product: "TelemPlus",
            variation: "RF",
            count: 0,
            // monitoringService: "",
          },
          {
            product: "TelemPlus",
            variation: "4G GEO",
            count: 0,
            // monitoringService: "",
          },
          // {
          //   product: "TelemPlus",
          //   variation: "GEO EAGLE",
          //   count: 0,
          //   // monitoringService: "",
          // },
          {
            product: "TelemPlus",
            variation: "MOST",
            count: 0,
            // monitoringService: "",
          },
          {
            product: "TelemPlus",
            variation: "2G TIVE",
            count: 0,
            // monitoringService: "",
          },
          {
            product: "TelemPlus",
            variation: "5G TIVE",
            count: 0,
            // monitoringService: "",
          },
          {
        product: "TelemPlus",
        variation: "Escavox",
        count: 0,
        // monitoringService: "",
        checked: false,
      },
          {
            product: "Dunnage Bags",
            variation: "Standard",
            count: 0,
            // monitoringService: "",
          },
          {
            product: "Blast Freezing",
            variation: "Standard",
            count: 0,
            // monitoringService: "",
          },
          {
            product: "Kraft paper",
            variation: "Standard",
            count: 0,
            // monitoringService: "",
            checked: false,
          },
          {
            product: "Absorb Bags",
            variation: "Standard",
            count: 0,
            // monitoringService: "",
            checked: false,
          },
          {
            product: "Stabling bars",
            variation: "Standard",
            count: 0,
            // monitoringService: "",
            checked: false,
          },
          {
            product: "Latches",
            variation: "Standard",
            count: 0,
            // monitoringService: "",
            checked: false,
          },
          {
            product: "Ratches",
            variation: "Standard",
            count: 0,
            // monitoringService: "",
            checked: false,
          },
          {
            product: "Ethylene Filters",
            variation: "Standard",
            count: 0,
            checked: false

          }
        ]),
          (this.bulkUpdateItem = {
            Genset: null,
            Industrial: null,
            Otflow: null,
            Zoono: null,
            // Dunnage_Bags: null,
            Void_Plugs: null,
            Late_Arrival: null,
            Early_Arrival: null,
            Fumigation: null,
            monitoringDevice: null,
            Port_Monitoring: null,
            ContainerLiners: null,
            SteriInsurance: null,
            SmartFresh: null,
            Heavyload: null,
          });
        this.saveBulkLoader = false;
        this.bulkUpdateModal = false;
      } else {
        this.$message.error("Internal Server Error");
        this.saveBulkLoader = false;
      }
    },
    containerNoTest(value) {
        if (/\b[A-Z]{4}\d{7}\b/.test(value)) {
          return true;
        }
        else {
          return false
        }
      },
    addMilestone(item, type = null, autosave = true) {
      if (type && item[type]) {
        this.milestoneItem = item[type];
        this.milestoneItem.autosave = autosave;
        this.milestoneContainer = item;
      } else {
        this.milestoneItem = {
          type,
          bookingContainerId: item.id,
          pol: this.booking.portOfLoadValue,
          // date: new Date(),
          autosave,
          isActual: false,
        };
        this.milestoneContainer = item;
      }
      this.addMilestoneModal = true;
      this.$nextTick(() => {
        this.milestoneKey++;
      });
    },
    async checkSubmitBooking() {
      let containers = this.booking.bookingContainers.filter(
        (x) =>
          !x.isDeleted &&
          (x.status == "Allocated" || x.status == null || x.status == "Short Shipped") &&
          x.bookingId == this.booking.id
      );

      let products = [].concat.apply(
        [],
        containers.map((x) => x.containerProducts)
      );
      if (
        !this.booking.contractNumber ||
          !this.booking.finalDestinationValue ||
          !this.booking.portOfLoadValue ||
          !this.booking.forwarder ||
          !this.booking.shipper ||
          !this.booking.mainCarriageConveyanceNumber ||
          !this.booking.vessel ||
          this.reeferShipment
          ? !this.booking.regime
          : false || containers.length == 0 || products.length == 0
      ) {
        this.bookingSubmitWarning = true;
      } else {
        if (!this.booking.notes) {
          this.booking.notes = "";
        }
        this.systemNotes = "";
        let isSteri = this.booking.regime && this.booking.regime.steri;
        if (this.booking.caProduct) {
          this.systemNotes +=
            "Controlled Atmosphere: " + this.booking.caProduct + ". ";
        }
        if (this.booking.serviceType !='BREAKBULK' && this.booking.vents) {
          this.systemNotes += "Vent Settings: " + this.booking.vents + ". ";
        }
        if (isSteri) {
          this.systemNotes += "\nNote FULL STERI Product. ";
          if (
            this.booking.destinationCountry &&
            this.booking.destinationCountry.iso2 == "IL"
          ) {
            let alreadyExists = this.booking.notes
              .toLowerCase()
              .includes("origin of fruit");
            if (!alreadyExists) {
              this.booking.notes += "\nOrigin of Fruit: ";
            }
          }
        }
        let isCarrierHaulage = containers.some((x) => x.isCarrierHaulage);
        if (isCarrierHaulage) {
          this.systemNotes += "\nPlease arrange CARRIER HAULAGE. ";
          let uniqueLoadingPoints = [
            ...new Set(
              containers
                .filter((x) => x.LOADING_POINT_1)
                .map((x) => x.LOADING_POINT_1.description)
            ),
          ];
          uniqueLoadingPoints =
            "\nLoading Points: " +
            uniqueLoadingPoints.map(
              (x) =>
                x +
                ` (${containers
                  .filter((y) => y.LOADING_POINT_1.description == x)
                  .map((y) => y.ctoNo)
                  .join(", ")})`
            );
          this.systemNotes += uniqueLoadingPoints;
        }
        this.inttraUsers = [];
        this.inttraUserModal = true;
      }
    },
    async submitInttraBooking() {
      this.submittingBooking = true;
      let result = await this.$API.submitBooking(this.booking.id, {
        users: this.inttraUsers,
        notes: this.booking.notes,
        systemNotes: this.systemNotes,
      });
      if (result.integrationReference) {
        this.booking.integrationReference = result.integrationReference;
        this.booking.state = result.state;
        this.$message.success("Successfully submitted to Inttra!");
      } else {
        this.inttraErrors = result.responseData.message ?? [];
        this.inttraErrorModal = true;
        // this.$message.error("An error occurred!");
      }
      this.bookingCommentModal = false;
      this.inttraUserModal = false;
      this.submittingBooking = false;
      this.inttraUsers = [];
      this.inttraUserSearch = null;
    },
    async checkSubmitUpdateBooking() {
      let containers =
        this.booking.bookingContainers.filter(
          (x) =>
            !x.isDeleted &&
            (x.status == "Allocated" || x.status == null || x.status == "Short Shipped") &&
            x.bookingId == this.booking.id
        ).length > 0;
      if (
        !this.booking.contractNumber ||
        !this.booking.finalDestinationValue ||
        !this.booking.portOfLoadValue ||
        !this.booking.mainCarriageConveyanceNumber ||
        !this.booking.vessel ||
        !this.booking.regime ||
        !containers
      ) {
        this.bookingSubmitWarning = true;
      } else {
        this.submittingBooking = true;
        let result = await this.$API.submitBookingUpdate(this.booking.id);
        if (result.success) {
          this.booking.integrationChange = false;
          this.$message.success("Successfully sent update to Inttra!");
        } else {
          this.$message.error("An error occurred!");
        }
        this.submittingBooking = false;
      }
    },
    editParty(type, item) {
      this.containerItem = item;
      this.partyType = type;
      this.partyModal = true;
    },
    async getTemporaryProducts(type) {
      if (type == "Bulk") {
        this.bulkType = "Update";
        let bookingContainerIds = this.chosenContainers.map((x) => x.id);
        this.selectedBookingContainerIds = bookingContainerIds;
        this.temporaryProducts = await this.$API.getBookingContainersProducts({
          bookingContainerIds: bookingContainerIds,
        });
        this.bulkUpdateModal = true;
      } else if (type == "Single") {
        let bookingContainerIds = this.chosenContainers.map((x) => x.id);
        this.temporaryProducts = await this.$API.getBookingContainersProducts({
          bookingContainerIds: bookingContainerIds,
        });
        for (let x = 0; x < this.temporaryProducts.length; x++) {
          let arrayProducts = [
            { product: "TelemPlus", variation: "2G GEO", count: 0 },
            { product: "TelemPlus", variation: "3G GEO", count: 0 },
            { product: "TelemPlus", variation: "T4", count: 0 },
            { product: "TelemPlus", variation: "RF", count: 0 },
            { product: "TelemPlus", variation: "4G GEO", count: 0 },
            { product: "TelemPlus", variation: "GEO EAGLE", count: 0 },
            { product: "TelemPlus", variation: "MOST", count: 0 },
            { product: "TelemPlus", variation: "2G TIVE", count: 0 },
            { product: "TelemPlus", variation: "5G TIVE", count: 0 },
          ];
          for (let y = 0; y < this.temporaryProducts[x].type.length; y++) {
            if (
              this.temporaryProducts[x].type[y].product == "TelemPlus" &&
              this.temporaryProducts[x].type[y].count > 0
            ) {
              //where deviceOptions
              let currentVariation =
                this.temporaryProducts[x].type[y].variation;
              let find = arrayProducts.find(
                (x) =>
                  x.product == "TelemPlus" && x.variation == currentVariation
              );
              if (find) {
                find.count = this.temporaryProducts[x].type[y].count;
              }
            }
          }
          this.temporaryProducts[x].deviceOptions = arrayProducts;
        }

        this.productsModal = true;
      } else if (type == "Add") {
        this.bulkType = "Add";
        this.bulkUpdateModal = true;
      }
    },

    addLoadingPoint(container, type, point) {
      this.emptyPoiContainer = {
        id: container.id,
        type: type,
        point: point,
      };
      this.addPoi = true;
    },
    addLoadingPointDense(type) {
      switch (type) {
        case "emptyLoadingPoint":
          this.poiFunctions.forEach((item) => {
            if (item.name == "Container Depot") {
              item.selected = true;
            } else {
              item.selected = false;
            }
          });
          break;
        case "firstLoadingPoint":
          this.poiFunctions.forEach((item) => {
            if (
              item.name == "Commercial Cold Store" ||
              item.name == "Pack-House (Off-Farm)" ||
              item.name == "Pack-House (On-Farm)"
            ) {
              item.selected = true;
            } else {
              item.selected = false;
            }
          });
          break;
        case "secondLoadingPoint":
          this.poiFunctions.forEach((item) => {
            if (
              item.name == "Commercial Cold Store" ||
              item.name == "Pack-House (Off-Farm)" ||
              item.name == "Pack-House (On-Farm)"
            ) {
              item.selected = true;
            } else {
              item.selected = false;
            }
          });
          break;
        case "dropOffPoint":
          this.poiFunctions.forEach((item) => {
            if (item.name == "Port" || item.name == "Port Terminal") {
              item.selected = true;
            } else {
              item.selected = false;
            }
          });
          break;
        default:
          break;
      }
      this.loadingPointItem = {
        type: type
      };
      this.poiModalDense = true;
    },
    selectQuantityType() {
      this.cargoItem.quantityTypeCode = this.selectedQuantityType.value;
      this.cargoItem.quantityType = this.selectedQuantityType.text;
    },
    addLoadingPointContainer(type, item) {
      this.poiItem = {
        type: type,
        container: item,
      };
      switch (type) {
        case "emptyLoadingPoint":
          this.poiFunctions.forEach((item) => {
            if (item.name == "Container Depot") {
              item.selected = true;
            } else {
              item.selected = false;
            }
          });
          this.poiItem.type = "emptyLoadingPoint";
          this.poiItem.friendlyType = "Empty Loading Point";
          break;
        case "firstLoadingPoint":
          this.poiFunctions.forEach((item) => {
            if (
              item.name == "Commercial Cold Store" ||
              item.name == "Pack-House (Off-Farm)" ||
              item.name == "Pack-House (On-Farm)"
            ) {
              item.selected = true;
            } else {
              item.selected = false;
            }
          });
          this.poiItem.type = "firstLoadingPoint";
          this.poiItem.friendlyType = "First Loading Point";
          break;
        case "secondLoadingPoint":
          this.poiFunctions.forEach((item) => {
            if (
              item.name == "Commercial Cold Store" ||
              item.name == "Pack-House (Off-Farm)" ||
              item.name == "Pack-House (On-Farm)"
            ) {
              item.selected = true;
            } else {
              item.selected = false;
            }
          });
          this.poiItem.type = "secondLoadingPoint";
          this.poiItem.friendlyType = "Second Loading Point";
          break;
        case "dropOffPoint":
          this.poiFunctions.forEach((item) => {
            if (item.name == "Port" || item.name == "Port Terminal") {
              item.selected = true;
            } else {
              item.selected = false;
            }
          });
          this.poiItem.type = "dropOffPoint";
          this.poiItem.friendlyType = "Drop Off Point";
          break;

        default:
          break;
      }
      this.containerPOIModal = true;
    },
    addLoadingPointContainerSlot(type, item) {
      this.poiItem = {
        type: type,
        container: item,
      };
      switch (type) {
        case "emptyLoadingPoint":
          this.poiItem.type = "emptyLoadingPoint";
          this.poiItem.friendlyType = "Empty Loading Point";
          if (item.emptyPickupTime) {
            this.poiItem.date = item.emptyPickupTime;
          } else {
            this.poiItem.date = new Date();
          }
          break;
        case "firstLoadingPoint":
          this.poiItem.type = "firstLoadingPoint";
          this.poiItem.friendlyType = "First Loading Point";
          if (item.firstLoadingPoint) {
            this.poiItem.date = item.firstLoadingPointTime;
          } else {
            this.poiItem.date = new Date();
          }
          break;
        case "secondLoadingPoint":
          this.poiItem.type = "secondLoadingPoint";
          this.poiItem.friendlyType = "Second Loading Point";
          if (item.secondLoadingPointTime) {
            this.poiItem.date = item.secondLoadingPointTime;
          } else {
            this.poiItem.date = new Date();
          }
          break;
        case "dropOffPoint":
          this.poiItem.type = "dropOffPoint";
          this.poiItem.friendlyType = "Drop Off Point";
          if (item.dropOffPointTime) {
            this.poiItem.date = item.dropOffPointTime;
          } else {
            this.poiItem.date = new Date();
          }
          break;

        default:
          break;
      }
      this.containerSlotModal = true;
    },
    addLoadingSlotModal(type, slotType) {
      this.loadingPointItem = {
        type: type,
      };
      if (this.bulkEdit[`${slotType}DateSlot`]) {
        this.dateSlot = this.bulkEdit[`${slotType}DateSlot`];
      }
      if (this.bulkEdit[`${slotType}TimeSlot`]) {
        this.timeSlot = this.bulkEdit[`${slotType}TimeSlot`];
      } else {
        this.timeSlot = null;
      }
      if (this.bulkEdit[`${slotType}Passcode`]) {
        this.passCodeSlot = this.bulkEdit[`${slotType}Passcode`];
      } else {
        this.passCodeSlot = null;
      }
      this.loadingTimeModal = true;
    },
    addMissingPoi(data) {
      this.addPoi = false;
      let selectedContainer = this.booking.bookingContainers.find(
        (container) => container.ctoNo === data.container.ctoNo
      );
      selectedContainer[data.container.type] = data.poi.name;
      selectedContainer[data.container.type + "PoiId"] = data.poi.id;
      selectedContainer[data.container.type + "Poi"] = data.poi;
      this.booking.bookingContainerItems[0][data.container.type] =
        data.poi.name;
      this.booking.bookingContainerItems[0][data.container.type + "PoiId"] =
        data.poi.id;
      this.booking.bookingContainerItems[0][data.container.type + "Poi"] =
        data.poi;
      let obj = {
        containerId: data.container.id,
        containerItemId: data.container.bookingContainerItemId,
        tag: data.container.type,
        poi: {
          name: data.poi.name,
          id: data.poi.id,
        },
      };
      let result = this.$API.addPoiToContainerItem(obj);
      if (result) {
        this.$message({
          type: "success",
          message: `POI updated successfully`,
        });
      } else {
        this.$message({
          type: "error",
          message: `Something went wrong trying to change the POI. Please Try Again.\n 
                          If this error persists please contact the support team.`,
        });
      }
    },
    addContainerNo(item) {
      this.containerNo = null;
      this.selectedContainer = item;
      this.containerNoDialog = true;
    },
    async addContainerDetails(type = null) {
      this.bulkUpdateItem = {
        Genset: false,
        Otflow: false,
        Zoono: false,
        // Dunnage_Bags: false,
        Void_Plugs: false,
        Late_Arrival: false,
        Early_Arrival: false,
        Fumigation: false,
        monitoringDevice: null,
        Port_Monitoring: false,
        ContainerLiners: false,
        SteriInsurance: false,
        SmartFresh: false,
        Heavyload: false,
      };
      if (!this.booking.bookingContainerItems) {
        this.booking.bookingContainerItems = [];
      }
      this.containerItem = {
        quantity: 1,
        bookingCargoItems: [],
        containerTypeCode: "45R1",
        index: 0,
        verificationMethod: "Method 2",
        type: this.booking.serviceType == "BREAKBULK"?"BREAKBULK":"CONTAINER",
        consignee: this.booking.consigneeId
          ? this.linkedOrganisations.find(
            (x) => x.relatedOrganisationId == this.booking.consigneeId
          )?.relatedOrganisation
          : null,
        consigneeId: this.booking.consigneeId,
        buyer: this.booking.buyerId
          ? this.linkedOrganisations.find(
            (x) => x.relatedOrganisationId == this.booking.buyerId
          )?.relatedOrganisation
          : null,
        buyerId: this.booking.buyerId,
      };
      if (!this.containerItem.buyer) {
        this.containerItem.buyerId = null;
      }
      if (!this.containerItem.consignee) {
        this.containerItem.consigneeId = null;
      }
      if (this.booking.vents) {
        this.containerItem.vents = this.booking.vents;
      } else if (this.ventSettings.length == 1) {
        this.containerItem.vents = this.ventSettings[0];
      }
      if (this.booking.regime) {
        this.containerItem.setPointTemp = this.booking.regime.setPointTemp;
      }
      if (this.booking.caProduct) {
        this.containerItem.caProduct = this.booking.caProduct;
        this.containerItem.oxygen = this.booking.oxygen;
        this.containerItem.carbonDioxide = this.booking.carbonDioxide;
        this.containerItem.nitrogen = 0;
      }
      this.containerItemModal = true;
    },
    addSealNo(item) {
      this.sealNo = null;
      this.selectedContainer = item;
      this.sealNoDialog = true;
    },
    addContainerCargoDetails() {
      let findContainerType = this.containerTypes.find(
        (x) => x.typeCode == this.containerItem.containerTypeCode
      );
      this.cargoItem = {
        type: "container",
        index: this.containerItem.bookingCargoItems.length,
        volume: findContainerType.teu * 25,
        quantityType: "Carton",
      };
      this.cargoModal = true;
    },
    addCustomerRef(item) {
      this.customerRef = null;
      this.selectedContainer = item;
      this.customerRefDialog = true;
    },
    addVerificationMethod(item) {
      // this.method = {
      //     method: 'Method 1'
      // }
      this.selectedContainer = item;
      this.selectedContainer.verificationMethod = "Method 1";
      this.containerMethodDialog = true;
    },
    bookingTypeColor(type) {
      let find = this.bookingTypes.find((x) => x.value == type);
      return find ? find.color : null;
    },
    bookingTypeIcon(type) {
      let find = this.bookingTypes.find((x) => x.value == type);
      return find ? find.icon : null;
    },
    editVerificationMethod(item) {
      // this.method = {
      //     verificationMethod: item.verificationMethod,

      // }
      this.selectedContainer = item;
      this.containerMethodDialog = true;
    },
    addToFile(id) {
      let index = this.selectedContainers.findIndex((x) => x == id);
      if (index != -1) {
        this.selectedContainers.splice(index, 1);
      } else {
        this.selectedContainers.push(id);
      }
    },
    async cancelBooking() {
      this.loadingCancel = true;
      this.booking.state = "Cancelled";
      this.booking.cancelBooking = true;
      await this.$API.updateBooking(this.booking);
      this.$message.success("Booking Successfully Cancelled");
      this.confirmCancel = false;
      this.loadingCancel = false;
    },
    async clickAction(action) {
      this.showMenu = false;
      switch (action) {
        case "view_container":
          this.viewThisContainer(this.container);
          break;
        case "move_container":
          this.moveContainer("single");
          break;
        case "content_copy":
          await this.duplicateContainer(this.container);
          break;
        case "remove_container":
          this.containerRemovalType = "single";
          this.confirmContainerCancel = true;
          break;
        case "reinstate_container":
          this.reinstateContainer(this.container);
          break;
        case "mark_ghost":
          this.ghostItem = {
            status: "Ghost",
            ghostReason: this.ghostReasonList[0],
            ghostParty: null,
          };
          this.ghostType = "single";
          this.ghostModal = true;
          break;
        case "mark_claim":
          this.markClaimContainer(this.container);
          break;
        case "container_telemetry":
          this.trackContainerMetrics(this.container)
          break;
      }
    },
    containerSelectionComplete() {
      this.selectedContainers = this.selectedContainers.map((x) =>
        this.booking.bookingContainers.find((y) => y.id == x)
      );
      // if (
      //   this.booking.type == "LOGISTICS" ||
      //   this.booking.type == "DOCUMENTATION"
      // ) {
      if (this.booking.consigneeProfile) {
        this.file.consigneeProfile = this.booking.consigneeProfile;
        this.file.consigneeProfileId = this.booking.consigneeProfileId;
        if (this.booking.dealTerm) {
          this.file.dealTerm = this.booking.dealTerm;
          this.file.dealTermId = this.booking.dealTermId;
          this.file.contractPartyId = this.booking.dealTerm.contractPartyId
        } 
        else if (
          this.file.consigneeProfile.consigneeProfileIncoTerms.length > 0
        ) {
          this.file.dealTerm =
            this.file.consigneeProfile.consigneeProfileIncoTerms[0];
          this.file.dealTermId =
            this.file.consigneeProfile.consigneeProfileIncoTerms[0].id;
        }

        this.fileTab = 2;
      } else {
        this.fileTab = 1;
      }
      // } else {
      //   this.fileTab = 2;
      // }
    },
    containerStatusIcon(item) {
      if (item.isDeleted) {
        return "cancel";
      } else if (item.bookingId && item.bookingId != this.booking.id) {
        return "input";
      } else if (item.status) {
        let find = this.containerStatus.find(
          (x) => x.name.toLowerCase() == item.status.toLowerCase()
        );
        if (find) {
          return find.icon;
        } else {
          return "warning";
        }
      } else {
        return "warning";
      }
    },
    containerStatusColor(item) {
      if (item.isDeleted) {
        return "red";
      } else if (item.bookingId && item.bookingId != this.booking.id) {
        return "blue";
      } else if (item.status) {
        let find = this.containerStatus.find(
          (x) => x.name.toLowerCase() == item.status.toLowerCase()
        );
        if (find) {
          return find.color;
        } else {
          return "orange darken-2";
        }
      } else {
        return "orange darken-2";
      }
    },
    convertDate(end, start) {
      // get total seconds between the times
      var delta = Math.abs(end.getTime() - start.getTime()) / 1000;

      // calculate (and subtract) whole days
      var days = Math.floor(delta / 86400);
      delta -= days * 86400;

      // calculate (and subtract) whole hours
      var hours = Math.floor(delta / 3600) % 24;
      delta -= hours * 3600;

      // calculate (and subtract) whole minutes
      var minutes = Math.floor(delta / 60) % 60;
      delta -= minutes * 60;

      // what's left is seconds
      var seconds = delta % 60;
      let time = "";
      if (days > 0) {
        time += days + " days ";
      }
      if (hours < 10) {
        time += "0" + hours + ":";
      } else {
        time += hours + ":";
      }
      if (minutes < 10) {
        time += "0" + minutes + ":";
      } else {
        time += minutes + ":";
      }
      seconds = Math.round(seconds);
      if (seconds < 10) {
        time += "0" + seconds;
      } else {
        time += seconds;
      }

      return {
        days,
        hours,
        minutes,
        seconds,
        time,
      };
    },
    async createBookingFile() {
      this.loadingCreateFile = true;
      let obj = {
        consigneeProfileId: this.file.consigneeProfileId,
        rmsVesselVoyageId: this.booking.rmsVesselVoyaegId,
        incoTermId: this.file.incoTermId,
        term: this.file.term,
        bookingId: this.booking.id,
        customerId: this.booking.customerId,
        containers: this.selectedContainers.map((x) => x.id),
      };
      let result = await this.$API.createBookingFile(obj);
      this.$message.success("File Created Successfully");
      this.getContainers();
      this.loadingCreateFile = false;
      this.confirmFileCreate = false;
      this.createFileDialog = false;
    },
    deselectAllContainers() {
      this.selectedContainers = [];
    },
    editCargoItem(item) {
      this.cargoItem = item;
      this.cargoModal = true;
    },
    editContainerNo(item) {
      if (item.containerNo) {
        this.selectedContainer = item;
        this.containerNo = item.containerNo;
        this.containerNoDialog = true;
      } else if (
        !item.isDeleted &&
        item.status != "Ghost" &&
        item.bookingId == this.booking.id
      ) {
        this.addContainerNo(item);
      }
    },
    editSealNo(item) {
      if (item.containerNo) {
        this.selectedContainer = item;
        this.sealNo = item.sealNo;
        this.sealNoDialog = true;
      } else if (
        !item.isDeleted &&
        item.status != "Ghost" &&
        item.bookingId == this.booking.id
      ) {
        this.addSealNo(item);
      }
    },
    editCustomerReference(item) {
      if (
        !item.isDeleted &&
        item.status != "Ghost" &&
        item.bookingId == this.booking.id
      ) {
        if (item.customerContainerRef) {
          this.selectedContainer = item;
          this.customerRef = item.customerContainerRef;
          this.customerRefDialog = true;
        } else {
          this.addCustomerRef(item);
        }
      }
    },
    editVents(item) {
      if (
        !item.isDeleted &&
        item.status != "Ghost" &&
        item.bookingId == this.booking.id
      ) {
        this.selectedContainer = item;
        this.ventDialog = true;
      }
    },
    addContainerProduct(item) {
      this.container = item;
      this.container.selectedProduct = {};
      let findContainerType = this.containerTypes.find(
        (x) => x.typeCode == this.container.containerTypeCode
      );
      this.container.selectedProduct.volume = findContainerType
        ? findContainerType.teu * 25
        : 50;
      this.containerProductModal = true;
    },

    editContainerProduct(item, product) {
      this.container = item;
      this.container.selectedProduct = product;
      this.containerProductModal = true;
    },

    editFile(item) {
      if (
        !item.isDeleted &&
        item.status != "Ghost" &&
        item.bookingId == this.booking.id
      ) {
        if (
          // !this.booking.shipper
          // || !this.booking.consignee
          !this.booking.originCountry ||
          !this.booking.destinationCountry ||
          !this.booking.regimeCode ||
          !this.booking.portOfLoadValue ||
          !this.booking.portOfDischargeValue ||
          !this.booking.finalDestinationValue ||
          !this.booking.vessel ||
          !this.booking.contractNumber ||
          !this.booking.mainCarriageConveyanceNumber ||
          !this.booking.etd ||
          !this.booking.eta
          // || !this.booking.incoTerm
        ) {
          this.createFileError = true;
        } else {
          this.searchFile = null;
          this.fileItem = item;
          this.fileItem.type = "single";
          this.fileModal = true;
          this.getAvailableFiles();
        }
      }
    },
    editFileMultiple() {
      if (
        !this.booking.originCountry ||
        !this.booking.destinationCountry ||
        !this.booking.regimeCode ||
        !this.booking.portOfLoadValue ||
        !this.booking.portOfDischargeValue ||
        !this.booking.finalDestinationValue ||
        !this.booking.vessel ||
        !this.booking.contractNumber ||
        !this.booking.mainCarriageConveyanceNumber ||
        !this.booking.etd ||
        !this.booking.eta
        // || !this.booking.incoTerm
      ) {
        this.createFileError = true;
      } else {
        this.searchFile = null;
        this.fileItem = {
          type: "multiple",
        };
        this.fileModal = true;
        this.getAvailableFiles();
      }
    },
    editTransportCoordinator(container) {
      this.setSelectableParties();
      this.containerItem = container;
      this.haulierModal = true;
    },
    async getRegimeCodeSettings() {
      if (this.booking.regimeId) {
        let ventSettings = await this.$API.getRegimeVent(this.booking.regimeId);
        if (ventSettings.length > 0) {
          this.ventSettings = [
            "Closed",
            "5",
            "10",
            "15",
            "20",
            "25",
            "28",
            "30",
            "35",
            "40",
            "50",
            "Fully Open",
            "CA",
            "MA",
            "Auto",
          ].filter((x) => ventSettings.map((y) => y.ventCode).includes(x));
        } else {
          this.ventSettings = [
            "5",
            "10",
            "15",
            "20",
            "25",
            "28",
            "30",
            "35",
            "40",
            "50",
            "Closed",
            "Fully Open",
            "CA",
            "MA",
            "Auto",
          ];
        }
      } else {
        this.ventSettings = [
          "5",
          "10",
          "15",
          "20",
          "25",
          "28",
          "30",
          "35",
          "40",
          "50",
          "Closed",
          "Fully Open",
          "CA",
          "MA",
          "Auto",
        ];
      }
      if (!this.booking.vents) {
        this.booking.vents = this.ventSettings[0];
        this.saveBooking();
      }
    },
    async getAvailableFiles() {
      if (this.loadingFiles) {
        clearTimeout(this.fileTimeout);
      }
      if (this.loadingFiles && this.fileQuery) {
        this.finalDestinationQuery.abort();
      }
      this.loadingFiles = true;
      let params = {
        search: this.searchFile,
        portOfLoadValue: this.booking.portOfLoadValue,
        finalDestinationValue: this.booking.finalDestinationValue,
        vesselId: this.booking.vesselId,
        voyage: this.booking.mainCarriageConveyanceNumber,
        shipperId: this.booking.shipperId,
        consigneeId: this.booking.consigneeId,
        contractPartyId: this.booking.contractPartyId,
        customerId: this.booking.customerId,
      };
      this.fileTimeout = setTimeout(async () => {
        this.fileQuery = new AbortController();
        const signal = this.fileQuery.signal;
        let data = await this.$API.listShipmentFileBooking({
          params: params,
          signal,
        });
        this.availableFiles = data.data;
        this.loadingFiles = false;
      }, 750);
    },
    async getOrganisationUsers() {
      // this.loading = true
      this.orgUsers = await this.$API.getOrganisationUsers();
      // this.loading = false
    },
    async getCarrierList() {
      this.shippingLines = await this.$API.getShippingLines();
      this.selectedCarriers = this.shippingLines
        .filter((x) => x.scacCode)
        .map((x) => x.scacCode);
      this.containerTypes = await this.$API.listContainerTypes();
    },

    async getRelations() {
      this.loadingRelatedOrganisations = true;
      this.related = await this.$API.getRelationBasic({});
      this.loadingRelatedOrganisations = false;
    },
    async getRegimeCodes(modal = false) {
      this.regimeCodeModal = modal;
      this.regimeCodeLoading = true;
      let params = {};
      if (this.booking.destinationCountry) {
        params.countryCode = this.booking.destinationCountry.iso2;
      }
      if (this.booking.originCountryId) {
        params.originCountryId = this.booking.originCountry.id;
      }
      if (
        this.booking.bookingContainers &&
        this.booking.bookingContainers &&
        this.booking.bookingContainers.length > 0
      ) {
        params.productCode = [].concat.apply(
          [],
          this.booking.bookingContainers.map((x) => x.containerProducts)
        );
        params.productCode = [
          ...new Set(params.productCode.map((x) => x.productId)),
        ];
      } else if (this.containerItem && this.containerItem.bookingCargoItems) {
        params.productCode = [
          ...new Set(
            this.containerItem.bookingCargoItems.map((x) => x.productId)
          ),
        ];
      }

      this.regimeCodes = await this.$API.getRegimeCodes({
        params: params,
      });
      this.regimeCodeLoading = false;
    },

    async getTransporters() {
      this.loadingTransporters = true;
      let result = await this.$API.getContractors();
      if (result) {
        this.transporters = result;
        this.loadingTransporters = false;
      }
    },
    async massAction(action) {
      switch (action) {
        case "edit":
          this.bulkEdit = {
            containerProducts: [],
          };
          this.isBulkEdit = true;
          this.bulkUpdateCard = true;
          break;
        case "split_booking":
          this.ghostSplitTypeModal = false;
          this.bookingSplitTypeModal = true;
          break;
        case "split_booking_ghost":
          this.ghostSplitTypeModal = true;
          this.bookingSplitTypeModal = true;
          break;
        case "remove":
          this.containerRemovalType = "multiple";
          this.confirmContainerCancel = true;
          break;
        case "mark_ghost":
          this.ghostItem = {
            status: "Ghost",
            ghostReason: this.ghostReasonList[0],
            ghostParty: null,
          };
          this.ghostType = "multiple";
          this.ghostModal = true;
          break;
        case "link_to_file":
          this.editFileMultiple();
          break;
        case "dispatch":
          // this.createQXDispatch();
          this.validateDispatchPalletQauntity();
          break;
        case "xml":
          console.log("xml");
          this.createXML();
          break;
        case "loadOut":
          console.log('loadOut')
          this.getLoadOutMailData()
          // this.loadoutModal = true
          break;

      }
      // await this.getContainers();
      // this.chosenContainers = [];
    },
    async createXML() {
      let selectedContainers = this.chosenContainers.map(x => x.id);
      console.log('selectedContainers', selectedContainers);

      for (let x = 0; x < selectedContainers.length; x++) {
        let result = await this.$API.createPECSXML({
          containerId: selectedContainers[x]
        });
        console.log('result', result)
        if (result && result.xml) {
          this.downloadXML(result.xml, `${result.name}.xml`);
        } else {
          this.$message({
            type: "error",
            message: `Please check if there is stock assigned to this container.\n 
                          If this error persists & stock is assigned please contact the support team.`,
          });
        }
      }
      this.chosenContainers = [];
    },

    downloadXML(data, filename) {
      const blob = new Blob([data], { type: 'application/xml' });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    },
    async validateDispatchPalletQauntity() {
      let selectedContainers = this.chosenContainers.map(x => x.id);

      let result = await this.$API.validateDispatchPalletQauntity({
        containerIds: selectedContainers
      });
      console.log('result', result)

      // let resultCheck = result.every(x => x.passed)
      // if (resultCheck) {
      //   this.qxDispatchData = result

      //   this.createQXDispatch();
      // }
      // else {
        this.qxDispatchData = result
        this.qxDispatchModal = true
      // }
    },
    // TEMPORARY IMPORT OF PROPHET CODE FOR TESTING
    async createQXDispatch() {
      this.creatingDispatch = true;
      let dispatchRequest = {};
      if(this.qxDispatchData){
        
        let passedContainers = this.qxDispatchData.filter(x => x.passed)
        dispatchRequest.containers = passedContainers.map((x) => x.bookingContainerId);
        this.qxDispatchData= []
      } else {
        dispatchRequest.containers = this.chosenContainers.map((x) => x.id);
      }

      if(dispatchRequest.containers.length > 0){
        let res = await this.$API.createQXDispatches(dispatchRequest);
        if (res.status === 201) {
        this.$message.success(res.message);
      } else if (res.status === 420) {
        this.$message.warning(res.message);
      } else if (res.status === 418) {
        this.$message.error(res.message);
      } else {
        this.$message.error("Error when creating Dispatches to QX.");
      }
      this.$message.success("Dispatches created successfully");
      }
      else{
        this.$message.error("No containers is valid for sending to QX");
      }
     
      this.getContainers();
      this.chosenContainers = [];
      this.creatingDispatch = false;
      this.qxDispatchModal = false;
    },


    getStatusChecks(status, statusLocation) {
      let checks = {};
      switch (status) {
        case "EN ROUTE TO":
          switch (statusLocation) {
            case "LOADING POINT 1":
              checks.emptyCheck = true;
              break;
            case "LOADING POINT 2":
              checks.emptyCheck = true;
              checks.firstLoadCheck = true;
              break;
            case "DROP OFF":
              checks.emptyCheck = true;
              checks.firstLoadCheck = true;
              checks.secondLoadCheck = true;
              break;
            case "PORT OF LOADING":
              checks.emptyCheck = true;
              checks.firstLoadCheck = true;
              checks.secondLoadCheck = true;
              break;
            default:
              checks = {};
              break;
          }
          break;
        case "AT":
          switch (statusLocation) {
            case "LOADING POINT 1":
              checks.emptyCheck = true;
              break;
            case "LOADING POINT 2":
              checks.emptyCheck = true;
              checks.firstLoadCheck = true;
              break;
            case "DROP OFF":
              checks.emptyCheck = true;
              checks.firstLoadCheck = true;
              checks.secondLoadCheck = true;
              break;
            case "PORT OF LOADING":
              checks.emptyCheck = true;
              checks.firstLoadCheck = true;
              checks.secondLoadCheck = true;
              break;
            default:
              checks = {};
          }
          break;
        case "DONE LOADING":
          checks.emptyCheck = true;
          checks.firstLoadCheck = true;
          checks.secondLoadCheck = true;
          break;
        default:
          checks = {};
          break;
      }
      return checks;
    },
    async markGhostContainer(item) {
      this.ghostLoading = true;
      // this.loadingGhost = true
      item = { ...item, ...this.ghostItem };
      item.shipmentFile = null;
      item.shipmentFileId = null;
      // let obj = {
      //     ...item,
      //     status: 'Ghost'
      // }
      await this.$API.updateContainer(item);
      this.$message.success("Successfully updated!");
      this.getContainers();
      this.ghostModal = false;
      this.ghostLoading = false;
      // this.loadingGhost = false
    },
    async markBulkAsGhost() {
      this.ghostLoading = true;
      for (let i = 0; i < this.chosenContainers.length; i++) {
        let index = this.booking.bookingContainers.findIndex(
          (x) => x.id == this.chosenContainers[i].id
        );
        let item = this.chosenContainers[i];
        item = { ...item, ...this.ghostItem };
        this.booking.bookingContainers[index] = {
          ...this.booking.bookingContainers[index],
          ...this.ghostItem,
        };
        item.shipmentFile = null;
        item.shipmentFileId = null;
        // this.chosenContainers[i] = { ...this.chosenContainers[i], ...this.ghostItem };
        await this.$API.updateContainer(item);
      }
      this.statusUpdate++;
      this.getContainers();
      // this.loadingGhost = true
      // item = {...item, ...this.ghostItem}
      // // let obj = {
      // //     ...item,
      // //     status: 'Ghost'
      // // }
      // await this.$API.updateContainer(item)
      this.$message.success("Successfully updated!");
      // this.getContainers();
      this.ghostModal = false;
      this.ghostLoading = false;
      this.chosenGhostContainers = [...this.chosenContainers];
      this.massAction("split_booking_ghost");
      this.chosenContainers = [];
    },
    getMileStonePoint(container, type) {
      let find = container.containerMilestones.find((x) => x.type == type);
      return find ? find : null;
    },
    milestoneUpdate(data) {
      let available = this.availableMilestones.filter(
        (x) => x.type == this.booking.movementType
      );
      this.milestoneContainer.containerMilestones = data;
      let findContainer = this.onlyShowActiveContainers
        ? this.activeContainers.find((x) => x.id == this.milestoneContainer.id)
        : this.allContainers.find((x) => x.id == this.milestoneContainer.id);
      available.forEach((x) => {
        let find = data.find((y) => y.type == x.value);
        if (find) {
          findContainer[x.value] = find;
        } else {
          findContainer[x.value] = null;
        }
      });
      // data.forEach(x=>{
      //   findContainer[x.type] = x
      // })
      this.addMilestoneModal = false;
      this.milestoneKey++;
      this.containerTableKey++;
    },
    orderItemStatusColor(status) {
      if (status) {
        let color = this.orderItemStatus.find(
          (x) => x.name.toLowerCase() === status.toLowerCase()
        );
        return color ? color.color : "grey";
      } else return "grey";
    },
    orderItemStatusIcon(status) {
      if (status) {
        let find = this.orderItemStatus.find(
          (x) => x.name.toLowerCase() === status.toLowerCase()
        );
        return find ? find.icon : "";
      } else return "help";
    },
    setMilestonePoint(item) {
      console.log("setMilestonePoint", item);
      if (this.bulkUpdateCard) {
        if (item.type) {
          this.bulkEdit[`${item.type}`] = item;
        }
        if (!this.bulkEdit.containerMilestones) {
          this.bulkEdit.containerMilestones = [];
        }
        let find = this.bulkEdit.containerMilestones.findIndex(
          (x) => x.type == item.type
        );
        if (find == -1) {
          this.bulkEdit.containerMilestones.push(item);
        } else {
          this.bulkEdit.containerMilestones[find] = item;
        }
      } else {
        if (item.type) {
          this.containerItem[`${item.type}`] = item;
        }
        if (!this.containerItem.containerMilestones) {
          this.containerItem.containerMilestones = [];
        }
        let find = this.containerItem.containerMilestones.findIndex(
          (x) => x.type == item.type
        );
        if (find == -1) {
          this.containerItem.containerMilestones.push(item);
        } else {
          this.containerItem.containerMilestones[find] = item;
        }
      }
      console.log(this.bulkEdit, this.containerItem);
      this.addMilestoneModal = false;
      this.containerTableKey++;
    },
    async moveContainerConfirm(item) {
      this.moveContainerModal = false;
      // this.loadingCustomerBooking = true
      this.moveItem = item;
      this.bookingMoveConfirm = true;
    },
    async createBookingMultipleContainer() {
      this.creatingNewBooking = true;
      let obj = {
        bookingId: this.booking.id,
        ghost: this.ghostSplitTypeModal,
        bookingContainers: !this.ghostSplitTypeModal
          ? this.chosenContainers.map((x) => x.id)
          : this.chosenGhostContainers.map((x) => x.id),
      };
      let result = await this.$API.createSplitBooking(obj);
      this.bookingCreateConfirm = false;
      this.$message.success("Successfully split booking!");
      this.$router.push({
        path: "/booking/" + result.systemReference,
      });
      this.chosenContainers = [];
      this.chosenGhostContainers = [];
      this.getContainers();
      this.creatingNewBooking = false;
    },
    async moveSingleContainer(item) {
      this.loadingCustomerBooking = true;
      let obj = {
        ...this.container,
        bookingId: item.id,
      };
      this.container.booking = item;
      this.container.bookingId = item.id;
      await this.$API.updateContainer(obj);
      this.$message.success("Container Moved Successfully");
      this.moveContainerModal = false;
      this.bookingMoveConfirm = false;

      this.loadingCustomerBooking = false;
    },
    async moveMultipleContainer(item) {
      this.loadingCustomerBooking = true;
      if (!this.ghostSplitTypeModal) {
        for (let i = 0; i < this.chosenContainers.length; i++) {
          let container = this.chosenContainers[i];
          let obj = {
            ...container,
            bookingId: item.id,
          };
          container.booking = item;
          container.bookingId = item.id;
          await this.$API.updateContainer(obj);
        }
      } else {
        let result = await this.$API.assignGhostContainers({
          bookingId: item.id,
          bookingContainers: this.chosenGhostContainers.map((x) => x.id),
        });
      }
      this.getContainers();
      this.$message.success("Container Moved Successfully");
      this.moveContainerModal = false;
      this.moveItem = null;
      this.bookingMoveConfirm = false;
      this.loadingCustomerBooking = false;
      this.chosenContainers = [];
    },
    async saveContainerWeights() {
      this.savingWeight = true;
      await this.$API.updateContainer(this.selectedContainer);
      this.$message.success("Successfully updated weights!");
      this.weightDialog = false;
      this.savingWeight = false;
    },
    toggleColumn(item) {
      if (item.show === true) {
        this.containerHeaders.find(
          (header) => header.value === item.value
        ).align = "center";
      } else {
        this.containerHeaders.find(
          (header) => header.value === item.value
        ).align = " d-none";
      }
    },
    async saveContainerItem() {
      try {
        this.savingContainerItem = true;
        if (this.containerItem.verificationMethod == "Method 2") {
          this.containerItem.weighBridgeId = null;
          this.containerItem.containerType = null;
          this.containerItem.verificationMethodOwner = null;
        }
        if (this.booking.regime) {
          this.containerItem.setPointTemp = this.booking.regime.setPointTemp;
        }
        if (this.devices) {
          this.containerItem.devices = this.devices;
          //go over the this.contanerItem.devices and set checked to null
          this.containerItem.devices.forEach((item) => {
            item.checked = null;
          });
        }
        if (this.bulkUpdateItem.monitoringService) {
          this.containerItem.monitoringService =
            this.bulkUpdateItem.monitoringService;
        }
        if (this.lateDescription) {
          this.$set(this.bulkUpdateItem, "lateDescription", this.lateDescription);
        }
        if (this.earlyDescription) {
          
          this.$set(this.bulkUpdateItem, "earlyDescription", this.earlyDescription);
        }

        if (this.bulkUpdateItem) {
          this.containerItem.products = this.bulkUpdateItem;
        }
        let findType = this.containerTypes.find(
          (x) => x.typeCode == this.containerItem.containerTypeCode
        );
        this.containerItem.containerTypeDescription = findType
          ? findType.shortDescription
          : null;
        let res = await this.$API.createContainerItem(
          this.booking.id,
          this.containerItem
        );
        this.$message.success("Successfully added containers!");
        this.containerItemModal = false;
        if (this.booking.integrationReference) {
          this.booking.integrationChange = true;
          await this.$API.updateBooking(this.booking);
          this.bookingChange = false;
        }

        //add devices and products

        (this.devices = [
        {
        product: "TelemPlus",
        variation: "Escavox",
        count: 0,
        // monitoringService: "",
        checked: false,
      },
          {
            product: "TelemPlus",
            variation: "2G GEO",
            count: 0,
            // monitoringService: "",
            checked: false,
          },
          {
            product: "TelemPlus",
            variation: "4G PROBE",
            count: 0,
            // monitoringService: "",
            checked: false,
          },
          {
            product: "TelemPlus",
            variation: "STD PROBE",
            count: 0,
            // monitoringService: "",
            checked: false,
          },
          
          // {
          //   product: "TelemPlus",
          //   variation: "3G GEO",
          //   count: 0,
          //   // monitoringService: "",
          //   checked: false,
          // },
          {
            product: "TelemPlus",
            variation: "T4",
            count: 0,
            // monitoringService: "",
            checked: false,
          },
          {
            product: "TelemPlus",
            variation: "RF",
            count: 0,
            // monitoringService: "",
            checked: false,
          },
          {
            product: "TelemPlus",
            variation: "4G GEO",
            count: 0,
            // monitoringService: "",
            checked: false,
          },
          // {
          //   product: "TelemPlus",
          //   variation: "GEO EAGLE",
          //   count: 0,
          //   // monitoringService: "",
          //   checked: false,
          // },
          {
            product: "TelemPlus",
            variation: "MOST",
            count: 0,
            // monitoringService: "",

            checked: false,
          },
          {
            product: "TelemPlus",
            variation: "2G TIVE",
            count: 0,
            // monitoringService: "",
            checked: false,
          },
          {
            product: "TelemPlus",
            variation: "5G TIVE",
            count: 0,
            // monitoringService: "",
            checked: false,
          },
          {
            product: "Dunnage Bags",
            variation: "Standard",
            count: 0,
            // monitoringService: "",
            checked: false,
          },
          {
            product: "Blast Freezing",
            variation: "Standard",
            count: 0,
            // monitoringService: "",
            checked: false,
          },
          {
            product: "Kraft paper",
            variation: "Standard",
            count: 0,
            // monitoringService: "",
            checked: false,
          },
          {
            product: "Absorb Bags",
            variation: "Standard",
            count: 0,
            // monitoringService: "",
            checked: false,
          },
          {
            product: "Stabling bars",
            variation: "Standard",
            count: 0,
            // monitoringService: "",
            checked: false,
          },
          {
            product: "Latches",
            variation: "Standard",
            count: 0,
            // monitoringService: "",
            checked: false,
          },
          {
            product: "Ratches",
            variation: "Standard",
            count: 0,
            // monitoringService: "",
            checked: false,
          },
          {
            product: "Ethylene Filters",
            variation: "Standard",
            count: 0,
            checked: false

          }
        ]),
          (this.bulkUpdateItem = {
            Genset: null,
            Otflow: null,
            Zoono: null,
            // Dunnage_Bags: null,
            Void_Plugs: null,
            Late_Arrival: null,
            Early_Arrival: null,
            Fumigation: null,
            monitoringDevice: null,
            ContainerLiners: null,
            SteriInsurance: null,
            SmartFresh: null,
            Heavyload: null
          });

        this.lateDescription = null;
        this.earlyDescription= null;

        this.getContainers();
        this.savingContainerItem = false;
      } catch (e) {
        this.$message.error("An error occurred :(");
        this.savingContainerItem = false;
      }
    },
    selectAllContainers() {
      this.selectAll = !this.selectAll;

      if (this.selectAll) {
        this.booking.bookingContainers.forEach((x) => {
          x.selected = false;
        });
        let filtered = this.booking.bookingContainers.filter(
          (x) =>
            x.status != "Ghost" &&
            x.status != "Deleted" &&
            !x.isDeleted &&
            x.bookingId == this.booking.id
        );
        filtered.forEach((item) => {
          item.selected = true;
        });
        this.chosenContainers = filtered;
      } else {
        this.booking.bookingContainers.forEach((x) => {
          x.selected = false;
        });
        this.chosenContainers = [];
      }
    },
    selectOneContainer(item) {
      let container = item;
      if (this.chosenContainers.includes(container)) {
        this.chosenContainers.splice(
          this.chosenContainers.indexOf(container),
          1
        );
        this.booking.bookingContainers.find(
          (x) => x.ctoNo === container.ctoNo
        ).selected = false;
      } else {
        this.chosenContainers.push(container);
        this.booking.bookingContainers.find(
          (x) => x.ctoNo === container.ctoNo
        ).selected = true;
      }
      this.chosenGhostContainers = [];
    },
    selectGhostContainer(item) {
      let container = item;
      if (this.chosenGhostContainers.includes(container)) {
        this.chosenGhostContainers.splice(
          this.chosenGhostContainers.indexOf(container),
          1
        );
        this.booking.bookingContainers.find(
          (x) => x.ctoNo === container.ctoNo
        ).selected = false;
      } else {
        this.chosenGhostContainers.push(container);
        this.booking.bookingContainers.find(
          (x) => x.ctoNo === container.ctoNo
        ).selected = true;
      }
      this.chosenContainers = [];
    },
    selectAllFileContainers(containers) {
      this.selectedContainers = containers.map((x) => x.id);
    },
    setSiDueDate() {
      if (new Date() < new Date(this.booking.documentationCutoffDate)) {
        this.siDueDate = this.convertDate(
          new Date(this.booking.documentationCutoffDate),
          new Date()
        );
        this.siDueDateKey++;
      }
    },
    itemRowBackground(item) {
      if (this.showMenu && this.container && this.container.id == item.id) {
        return "selectedClass";
      }
    },
    deletedRows(item) {
      if (item.isDeleted) {
        return "disabledClass";
      }
      if (item.duplicated) {
        return "duplicatedClass";
      }
    },
    async sendComment() {
      this.sendingMessage = true;
      let result = await this.$API.createBookingComment({
        bookingId: this.booking.id,
        comment: this.comment,
        isInternal: this.internalMessage,
      });
      if (result) {
        result.user = this.$store.state.user;
        this.comments.push(result);
        this.comment = null;
      }
      this.sendingMessage = false;
    },
    setSelectableParties() {
      let keys = [];
      if (this.booking.movementType == "EXPORT") {
        keys = [
          "forwarder",
          "shipper",
          "firstNotifyParty",
          "secondNotifyParty",
        ];
      } else {
        keys = [
          "forwarder",
          "consignee",
          "shipper",
          "buyer",
          "firstNotifyParty",
          "secondNotifyParty",
        ];
      }
      let result = [];
      for (let i = 0; i < keys.length; i++) {
        if (this.booking[keys[i] + "Id"]) {
          result.push(this.booking[keys[i]]);
        }
      }
      if (this.booking.customerId) {
        let findCustomer = result.find((x) => x.id == this.booking.customerId);
        if (!findCustomer) {
          result.unshift(this.booking.customer);
        }
      }
      let findCurrentOrg = result.find(
        (x) => x.id == this.$store.state.currentOrg.id
      );
      if (!findCurrentOrg) {
        result.unshift(this.$store.state.currentOrg);
      }
      this.selectableParties = result;
    },
    async saveCustom() {
      this.loadingSaveCustom = true;
      let obj = {};
      this.containerHeaders.forEach((header) => {
        obj[header.value] = header.show;
      });
      let result = await this.$API.saveBookingCustomLayout(obj);
      if (result) {
        this.loadingSaveCustom = false;
        this.$message({
          type: "success",
          message: `Your layout has been saved!`,
        });
      } else {
        this.$message({
          type: "error",
          message: `Something went wrong while saving your layout. Please try again. \n If the problem persists, please contact support.`,
        });
        this.loadingSaveCustom = false;
      }
    },
    toggleProducts(choice, type) {
      type = type.replace(/ /g, "_");
      this.bulkUpdateItem[type] =
        choice == "YES" ? true : choice == "NO" ? false : null;
      // if (choice === "YES") {
      //   if (this.bulkUpdateItem[type] === true) {
      //     // If already set to true, set it to null
      //     this.bulkUpdateItem[type] = null;
      //   } else if{
      //     // Otherwise, set it to true
      //     this.bulkUpdateItem[type] = true;
      //   }
      // }

      // if (choice === "NO") {
      //   if (this.bulkUpdateItem[type] === false) {
      //     // If already set to false, set it to null
      //     this.bulkUpdateItem[type]=null;
      //   } else {
      //     // Otherwise, set it to false
      //     this.bulkUpdateItem[type] = false;
      //   }
      // }

      // Increment the toggling keys to force a re-render
      this.gensetToggleKey++;
      this.otflowKey++;
      this.zoonoKey++;
      this.industrialKey++;
      this.portMonitoringKey++;
      this.voidPlug++;
      this.lateArrivalKey++;
      this.earlyArrivalKey++;
    },

    toggleButton(choice, type) {
      if (choice === "YES") {
        if (
          !Object.hasOwn(this.bulkEdit, type) ||
          this.bulkEdit[type] === false
        ) {
          this.bulkEdit[type] = true;
        } else {
          delete this.bulkEdit[type];
        }
        this.gensetToggleKey++;
        this.controlledAtmosphereKey++;
        this.sensorDeviceKey++;
      }
      if (choice === "NO") {
        if (
          !Object.hasOwn(this.bulkEdit, type) ||
          this.bulkEdit[type] === true
        ) {
          this.bulkEdit[type] = false;
        } else {
          delete this.bulkEdit[type];
        }
        this.gensetToggleKey++;
        this.controlledAtmosphereKey++;
        this.sensorDeviceKey++;
        
      }
    },
    toggleProduct(productName) {
      let item = this.temporaryProducts;
      const index = item.type.findIndex(
        (product) => product.product === productName
      );
      if (index > -1) {
        if (item.type[index].count > 0) {
          item.type[index].count = 0;
        } else {
          item.type[index].count = 1;
        }
      }
    },
    moveContainer(type) {
      if (!this.booking.customer) {
        this.bookingMoveError = true;
      } else {
        this.moveContainerType = type;
        this.moveContainerModal = true;
        this.getCustomerBooking();
      }
    },
    async saveContainerChanges() {
      this.loadingChanges = true;
      if (this.changesMade) {
        let duplicatedContainers = this.booking.bookingContainers.filter(
          (x) => x.duplicated
        );
        let deletedContainers = this.booking.bookingContainers.filter(
          (x) => x.isDeleted
        );
        let reinstatedContainers = this.booking.bookingContainers.filter(
          (x) => x.reinstated
        );

        let obj = {
          bookingId: this.booking.id,
          added: duplicatedContainers,
          deleted: deletedContainers,
          reinstated: reinstatedContainers,
        };
        let result = await this.$API.updateBookingContents(obj);
        if (result) {
          this.$message({
            type: "success",
            message: `Your changes have been saved!`,
          });
          // this.getBooking(this.booking.systemReference)
          this.getContainers();
          this.addedCount = 0;
          this.deletedCount = 0;
          this.reinstatedCount = 0;
          this.editContainers = false;
          this.loadingChanges = false;
        } else {
          this.$message({
            type: "error",
            message: `Something went wrong while saving your changes. Please try again. \n If the problem persists, please contact support.`,
          });
        }
      }
    },
    setHaulier(haulier) {
      let types = ["isPrivateHaulage", "isCarrierHaulage", "isRailage"];
      if (haulier.flag) {
        let setFalse = types.filter((x) => x != haulier.flag);
        for (let i = 0; i < setFalse.length; i++) {
          this.containerItem[setFalse[i]] = false;
        }
        this.containerItem[haulier.flag] = true;
        this.containerItem.transportCoordinator = null;
        this.containerItem.transportCoordinatorId = null;
      } else {
        for (let i = 0; i < types.length; i++) {
          this.containerItem[types[i]] = false;
        }
        this.containerItem.transportCoordinator = haulier;
        this.containerItem.transportCoordinatorId = haulier.id;
      }
      if (
        this.containerItem.transportCoordinatorId ==
        this.$store.state.currentOrg.id &&
        this.booking.movementType == "EXPORT"
      ) {
        this.containerItem.preAdviseRequired = true;
      } else {
        this.containerItem.preAdviseRequired = false;
      }
      this.haulierKey++;
      this.$emit("bookingChange");
    },

    updateHeaders(obj) {
      if (obj) {
        Object.entries(obj).forEach(([key, value]) => {
          this.containerHeaders.forEach((header) => {
            if (header.value === key) {
              header.show = value;
              if (value === true) {
                header.align = "center";
              } else {
                header.align = " d-none";
              }
            }
          });
        });
        return this.containerHeaders;
      } else {
        return this.containerHeaders;
      }
    },
    updateTransporter() {
      if (this.containerItem.transportCoordinator) {
        this.containerItem.transportCoordinatorId =
          this.containerItem.transportCoordinator.id;
      } else {
        this.containerItem.transportCoordinatorId = null;
      }
      if (
        this.containerItem.transportCoordinatorId ==
        this.$store.state.currentOrg.id
      ) {
        this.containerItem.preAdviseRequired = true;
      }
    },
    async updateContainerStatus(container, status) {
      let checks = this.getStatusChecks(status, container.statusLocation);

      if (status != "AT" && status != "EN ROUTE TO") {
        let obj = {
          id: container.id,
          status: status,
          statusLocation: null,
          statusDate: new Date(),
        };
        if (checks) {
          obj = {
            ...obj,
            ...checks,
          };
        }
        this.containerStatusMenuKey++;
        let result = await this.$API.updateContainerStatus(obj);
        if (result) {
          this.$message({
            type: "success",
            message: `The container status has been updated!`,
          });
        } else {
          this.$message({
            type: "error",
            message: `Something went wrong while updating the container status. Please try again. \n If the problem persists, please contact support.`,
          });
        }
      } else {
        let obj = {
          id: container.id,
          status: status,
          statusLocation: container.statusLocation,
        };
        if (checks) {
          obj = {
            ...obj,
            ...checks,
          };
        }
        let result = await this.$API.updateContainerStatus(obj);
        if (result) {
          this.$message({
            type: "success",
            message: `The container status has been updated!`,
          });
        } else {
          this.$message({
            type: "error",
            message: `Something went wrong while updating the container status. Please try again. \n If the problem persists, please contact support.`,
          });
        }
      }
    },
    formatDate(date) {
      let isoFormat = null;
      let fullDate = null;
      let dateTime = null;
      let dayOfWeek = null;
      let shorterDate = null;
      let time = null;
      if (date) {
        isoFormat = dateFormat(new Date(date), "dd-mm-yyyy");
        fullDate = dateFormat(new Date(date), "dddd, mmmm dS, yyyy");
        dateTime = dateFormat(new Date(date), "dddd, mmmm dS, yyyy HH:MM");
        dayOfWeek = dateFormat(new Date(date), "ddd");
        shorterDate = dateFormat(new Date(date), "mmm dS, yyyy");
        time = dateFormat(new Date(date), "HH:MM");
      }
      return { isoFormat, fullDate, dateTime, dayOfWeek, shorterDate, time };
    },
    formatDateTime(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd mmm, yyyy HH:MM");
      }
      return result;
    },
    formatVoyageLegs(bookingId, legs) {
      let result = [];
      if (legs.legs && legs.legs.length > 0) {
        result = legs.legs.map((x) => ({
          bookingId: bookingId,
          leg: x.sequence,
          eta: x.arrivalDate,
          etd: x.departureDate,
          originLocode: x.departureUnloc,
          destinationLocode: x.arrivalUnloc,
          originCity: x.departureCityName,
          destinationCity: x.arrivalCityName,
          originTerminal: x.departureTerminal,
          destinationTerminal: x.arrivalTerminal,
          vesselName: x.transportName,
          voyageNumber: x.conveyanceNumber,
          transporterName: x.transportName,
          imoNumber: x.transportID,
          serviceName: x.serviceName,
        }));
      } else {
        result = [
          {
            bookingId: bookingId,
            leg: 1,
            eta: legs.destinationArrivalDate,
            etd: legs.originDepartureDate,
            originLocode: legs.originUnloc,
            destinationLocode: legs.destinationUnloc,
            originCity: legs.originCityName,
            destinationCity: legs.destinationCityName,
            originTerminal: legs.originTerminal,
            destinationTerminal: legs.destinationTerminal,
            voyageNumber: legs.voyageNumber,
            vesselId: legs.vesselId,
            vesselName: legs.vesselName,
            imoNumber: legs.imoNumber,
            serviceName: legs.serviceName,
            transporterCode: legs.scac,
          },
        ];
      }
      return result;
    },
    async goToBooking(val) {
      if (val.systemReference) {
        this.$router.push({
          path: `/booking/${val.systemReference}`,
        });
      }
    },
    formatContainerNo() {
      let remainingChars = "";
      let firstFourChars = this.containerNo
        .substring(0, 4)
        .replace(/[^a-zA-Z]/g, "")
        .toUpperCase();

      if (
        this.containerNo === null ||
        this.containerNo === "" ||
        this.containerNo.length < 4
      ) {
        this.containerError = null;
        this.containerMessageType = null;
      }

      if (this.containerNo.length > 11) {
        this.containerNo = this.containerNo.substring(0, 11);
        this.containerNoKey++;
      }

      if (this.containerNo.length > 4) {
        remainingChars = this.containerNo.substring(4).replace(/\D/g, "");
      }

      if (this.containerNo.length > 3) {
        if (
          !["U", "J", "Z"].includes(firstFourChars.charAt(3)) &&
          firstFourChars.charAt(3) !== " " &&
          this.containerNo.length > 3
        ) {
          this.containerError = "Invalid Container Number";
        } else if (["U"].includes(firstFourChars.charAt(3))) {
          this.containerMessageType = "Freight Container";
          this.containerError = null;
        } else if (["J"].includes(firstFourChars.charAt(3))) {
          this.containerMessageType = "Detachable Freight Container";
          this.containerError = null;
        } else if (["Z"].includes(firstFourChars.charAt(3))) {
          this.containerMessageType = "Trailer / Chassis";
          this.containerError = null;
        }
      }
      const formattedContainerNo =
        firstFourChars + remainingChars.substring(0, 7);
      if (formattedContainerNo.length === 11 && this.containerError === null) {
        this.containerSave = true;
      }
      this.containerNo = formattedContainerNo;
    },
    formatSealNo() {
      this.sealNo = this.sealNo.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();
      if (
        this.sealNo === null ||
        this.sealNo === "" ||
        this.sealNo.length < 5
      ) {
        this.sealNoSave = false;
      } else {
        this.sealNoSave = true;
      }
    },
    formatCustomerRef() {
      this.customerRef = this.customerRef
        .replace(/[^a-zA-Z0-9]/g, "")
        .toUpperCase();
      if (
        this.customerRef === null ||
        this.customerRef === "" ||
        this.customerRef.length < 5
      ) {
        this.customerRefSave = false;
      } else {
        this.customerRefSave = true;
      }
    },
    async getContainers() {
      this.loadingContainers = true;
      this.booking.bookingContainers = [];
      this.containerTableKey++;
      let containers = await this.$API.getBookingContainers(this.booking.id);
      console.log('containers', containers)
      containers.forEach((container) => {
        container.showDetails = false;
      })
      this.booking.bookingContainers = containers;
      let initialHazard = this.booking.isHazardous;
      if (!initialHazard) {
        this.booking.isHazardous = this.booking.bookingContainers.some((x) =>
          x.containerProducts.some((y) => y.hazardous == true)
        );
        if (this.booking.isHazardous) {
          this.bookingChange = true;
        }
      }
      this.allContainers = containers;
      console.log(this.allContainers);
      this.activeContainers = containers.filter(
        (x) =>
          !x.isDeleted &&
          x.status != "Ghost" &&
          x.status != "Cancelled" &&
          x.status != "Deleted" &&
          x.bookingId == this.booking.id
      );
      // for (let i = 0; i < this.booking.bookingContainers.length; i++) {
      //     this.booking.bookingContainers[i].bookingContainerItem = this.booking.bookingContainerItems.find(x => x.id == this.booking.bookingContainers[i].bookingContainerItemId)
      // }
      if (this.booking.bookingContainers) {
        let allProducts = [].concat.apply(
          [],
          this.booking.bookingContainers
            .filter(
              (x) =>
                !x.isDeleted &&
                x.status != "Ghost" &&
                x.status != "Cancelled" &&
                x.bookingId == this.booking.id
            )
            .map((x) => x.containerProducts)
        );
        let uniqueProducts = [...new Set(allProducts.map((x) => x.productId))];
        let result = [];
        for (let i = 0; i < uniqueProducts.length; i++) {
          let find = allProducts.filter(
            (x) =>
              x.productId == uniqueProducts[i] && x.varietyProductId == null
          );
          let obj = {
            id: uniqueProducts[i],
            productName: find[0].product.name,
            grossWeight: find
              .filter((x) => x.grossWeight)
              .map((x) => x.grossWeight)
              .reduce((a, b) => a + b, 0),
            nettWeight: find
              .filter((x) => x.nettWeight)
              .map((x) => x.nettWeight)
              .reduce((a, b) => a + b, 0),
            quantity: find
              .filter((x) => x.quantity)
              .map((x) => x.quantity)
              .reduce((a, b) => a + b, 0),
            volume: find
              .filter((x) => x.volume)
              .map((x) => x.volume)
              .reduce((a, b) => a + b, 0),
            industrial: find[0].industrial,
            organic: find[0].organic,
            hazardous: find[0].hazardous,
          };
          result.push(obj);
        }
        this.productSummary = result;
      } else {
        this.productSummary = [];
      }
      let uniqueFiles = [
        ...new Set(
          this.booking.bookingContainers
            .filter(
              (x) =>
                !x.isDeleted &&
                x.bookingId == this.booking.id &&
                x.status != "Cancelled" &&
                x.status != "Ghost"
            )
            .map((x) => x.shipmentFileId)
        ),
      ].filter(Boolean);
      let result = uniqueFiles.map(
        (x) =>
          this.booking.bookingContainers.find((y) => y.shipmentFileId == x)
            .shipmentFile
      );
      this.linkedFiles = result;
      this.loadingContainers = false;
      this.containerKey++;
      this.containerTableKey++;
      this.headerKey++;
    },
    async manaulUpdateBookingType() {
      await this.$API.manaulUpdateBookingType({
        bookingId: this.booking.id,
        type: this.booking.type,
      });
    },
    async manaulUpdateServiceType() {
      await this.$API.manaulUpdateServiceType({
        bookingId: this.booking.id,
        serviceType: this.booking.serviceType,
      });
    },
    async updateManaulStatus() {
      await this.$API.updateManaulUpdateStatus({
        bookingId: this.booking.id,
        status: this.booking.state,
      });
    },
    async getCustomerBooking() {
      if (this.loadingCustomerBooking) {
        clearTimeout(this.bookingTimeout);
      }
      if (this.loadingCustomerBooking && this.bookingQuery) {
        this.bookingQuery.abort();
      }
      this.loadingCustomerBooking = true;
      let params = {
        filter: { customerId: this.booking.customerId },
      };
      this.bookingTimeout = setTimeout(async () => {
        this.bookingQuery = new AbortController();
        const signal = this.bookingQuery.signal;
        let data = await this.$API.getBookings({
          params: params,
          signal,
        });
        this.availableBookings = data.data;
        this.loadingCustomerBooking = false;
      }, 750);
    },
    async getBooking(systemReference) {
      this.loading = true;
      this.booking = await this.$API.getBookingDetail(systemReference);
      if (this.booking.clientStatus == "ON HOLD") {
        this.onHoldWarningModal = true;
      }
      if (this.booking.consigneeProfileId) {
        this.booking.consigneeProfile = await this.$API.getConsigneeProfile(
          this.booking.consigneeProfileId
        );
        console.log('this.booking', this.booking)

        // if(this.booking.bookingContainers.length > 0){
        //   //add for each container a showDetails as false
        //   this.booking.bookingContainers.forEach((container) => {
        //     container.showDetails = false;
        //   });  
        // }
      }
      // setInterval(() => { this.setSiDueDate() }, 1000);
      setTimeout(() => {
        this.$socket.send(
          JSON.stringify({
            action: "join",
            room: "booking_" + this.booking.id,
            user: {
              id: this.$store.state.user.id,
              name:
                this.$store.state.user.firstname +
                " " +
                this.$store.state.user.surname,
              avatar: this.$store.state.user.avatar,
              company: this.$store.state.currentOrg.name,
            },
          })
        );
      }, 1000);
      this.$socket.onopen = () => {
        this.$socket.send(
          JSON.stringify({
            action: "join",
            room: "booking_" + this.booking.id,
            user: {
              id: this.$store.state.user.id,
              name:
                this.$store.state.user.firstname +
                " " +
                this.$store.state.user.surname,
              avatar: this.$store.state.user.avatar,
              company: this.$store.state.currentOrg.name,
            },
          })
        );
      };
      this.$socket.onmessage = (data) => {
        let message = JSON.parse(data.data);
        if (this.booking && this.booking.id) {
          if (
            message.type == "users" &&
            message.room == "booking_" + this.booking.id
          ) {
            this.activeUsers = message.data;
            this.userUpdateKey++;
          }
        }
      };
      this.getComments();
      if (this.booking.customerId) {
        this.getShipmentProfiles();
      }
      this.booking.bookingContainers = [];
      this.getContainers();

      let terms = [];
      if (this.booking.consigneeProfile) {
        for (
          let i = 0;
          i < this.booking.consigneeProfile.consigneeProfileIncoTerms.length;
          i++
        ) {
          let term = this.booking.consigneeProfile.consigneeProfileIncoTerms[i];
          let result = term.consigneeProfileShippingContracts
            .filter((x) => x.shippingLine)
            .map((y) => ({
              id: term.id,
              incoTerm: term.incoTerm,
              shippingLine: y.shippingLine,
              scacCode: y.shippingLine.scacCode,
              contractNumber: y.contractNumber,
              contractOwner: y.contractOwner,
            }));
          terms = [...terms, ...result];
        }
      }
      // this.suggestedDealTerms = terms.filter(
      //   (x) => x.scacCode == this.booking.carrierScac
      // );
      // if (this.suggestedDealTerms.length == 1) {
      //   this.booking.contractOwner = this.suggestedDealTerms[0].contractOwner;
      //   this.booking.contractNumber = this.suggestedDealTerms[0].contractNumber;
      // }
      this.addedCount = 0;
      this.deletedCount = 0;
      this.reinstatedCount = 0;
      this.loading = false;
    },
    async getComments() {
      this.comments = await this.$API.getBookingComment(this.booking.id);
    },
    linkedContainers(id) {
      return this.booking.bookingContainers.filter(
        (x) => x.bookingContainerItemId == id
      );
    },
    async loadCountries() {
      this.loadingCountry = true;
      this.countries = await this.$API.searchCountriesBasic({});
      this.loadingCountry = false;
    },
    async loadProducts() {
      this.loadingProduct = true;
      this.products = await this.$API.getProducts({});
      this.loadingProduct = false;
    },

    async getPoiFunctions() {
      this.loadingFunctions = true;
      this.poiFunctions = await this.$API.getPoiFunctions();
      if (this.poiFunctions.length > 0) {
        this.loadingFunctions = false;
      } else {
        this.loadingFunctions = false;
        this.$message({
          message: "Error loading the Poi Functions. Please try again later.",
          type: "warning",
        });
      }
    },
    setLoadingPoint(point) {
      if (this.isBulkEdit) {
        if (this.loadingPointItem.type == "emptyLoadingPoint") {
          this.bulkEdit.emptyPickup = point.name;
          this.bulkEdit.emptyPickupPoi = point;
          this.bulkEdit.emptyPickupPoiId = point.id;
        } else if (this.loadingPointItem.type == "firstLoadingPoint") {
          this.bulkEdit.firstLoadingPoint = point.name;
          this.bulkEdit.firstLoadingPointPoi = point;
          this.bulkEdit.firstLoadingPointPoiId = point.id;
        } else if (this.loadingPointItem.type == "secondLoadingPoint") {
          this.bulkEdit.secondLoadingPoint = point.name;
          this.bulkEdit.secondLoadingPointPoi = point;
          this.bulkEdit.secondLoadingPointPoiId = point.id;
        } else if (this.loadingPointItem.type == "dropOffPoint") {
          this.bulkEdit.dropOffPoint = point.name;
          this.bulkEdit.dropOffPointPoi = point;
          this.bulkEdit.dropOffPointPoiId = point.id;
        }
      } else {
        if (this.loadingPointItem.type == "emptyLoadingPoint") {
          this.containerItem.emptyPickup = point.name;
          this.containerItem.emptyPickupPoi = point;
          this.containerItem.emptyPickupPoiId = point.id;
        } else if (this.loadingPointItem.type == "firstLoadingPoint") {
          this.containerItem.firstLoadingPoint = point.name;
          this.containerItem.firstLoadingPointPoi = point;
          this.containerItem.firstLoadingPointPoiId = point.id;
        } else if (this.loadingPointItem.type == "secondLoadingPoint") {
          this.containerItem.secondLoadingPoint = point.name;
          this.containerItem.secondLoadingPointPoi = point;
          this.containerItem.secondLoadingPointPoiId = point.id;
        } else if (this.loadingPointItem.type == "dropOffPoint") {
          this.containerItem.dropOffPoint = point.name;
          this.containerItem.dropOffPointPoi = point;
          this.containerItem.dropOffPointPoiId = point.id;
        }
      }

      this.poiSearch = "";
      this.poiModalDense = false;
      this.loadingPointItem = {};
    },
    setLoadingSlots() {
      switch (this.loadingPointItem.type) {
        case "emptyLoadingPoint":
          this.bulkEdit.emptyDateSlot = this.dateSlot ? this.dateSlot : null;
          this.bulkEdit.emptyTimeSlot = this.timeSlot ? this.timeSlot : null;
          this.bulkEdit.emptyPasscode = this.passCodeSlot
            ? this.passCodeSlot
            : null;
          break;
        case "firstLoadingPoint":
          this.bulkEdit.firstDateSlot = this.dateSlot ? this.dateSlot : null;
          this.bulkEdit.firstTimeSlot = this.timeSlot ? this.timeSlot : null;
          this.bulkEdit.firstPasscode = this.passCodeSlot
            ? this.passCodeSlot
            : null;
          break;
        case "secondLoadingPoint":
          this.bulkEdit.secondDateSlot = this.dateSlot ? this.dateSlot : null;
          this.bulkEdit.secondTimeSlot = this.timeSlot ? this.timeSlot : null;
          this.bulkEdit.secondPasscode = this.passCodeSlot
            ? this.passCodeSlot
            : null;
          break;
        case "dropOffPoint":
          this.bulkEdit.dropOffDateSlot = this.dateSlot ? this.dateSlot : null;
          this.bulkEdit.dropOffTimeSlot = this.timeSlot ? this.timeSlot : null;
          this.bulkEdit.dropOffPasscode = this.passCodeSlot
            ? this.passCodeSlot
            : null;
          break;
        default:
          break;
      }
      this.timeSlot = null;
      this.passCodeSlot = null;
      this.loadingPointItem = {};
      this.loadingTimeModal = false;
    },
    removePoi(type) {
      switch (type) {
        case "emptyLoadingPoint":
          this.bulkEdit.emptyPickup = null;
          this.bulkEdit.emptyPickupPoi = null;
          this.bulkEdit.emptyPickupPoiId = null;
          break;
        case "firstLoadingPoint":
          this.bulkEdit.firstLoadingPoint = null;
          this.bulkEdit.firstLoadingPointPoi = null;
          this.bulkEdit.firstLoadingPointPoiId = null;
          break;
        case "secondLoadingPoint":
          this.bulkEdit.secondLoadingPoint = null;
          this.bulkEdit.secondLoadingPointPoi = null;
          this.bulkEdit.secondLoadingPointPoiId = null;
          break;
        case "dropOffPoint":
          this.bulkEdit.dropOffPoint = null;
          this.bulkEdit.dropOffPointPoi = null;
          this.bulkEdit.dropOffPointPoiId = null;
          break;
        default:
          break;
      }
      this.poiModalDense = false;
    },
    async searchPois(val) {
      if (this.poiSearchLoading) {
        clearTimeout(this.poiSearchTimeout);
      }
      if (this.poiRequest) {
        this.poiRequest.abort();
      }
      this.poiParams.filter = this.poiFunctions
        .filter((item) => item.selected)
        .map((item) => item.name);
      this.poiParams.search = val;
      if (val && val.length > 1) {
        this.poiSearchLoading = true;
        this.poiSearchTimeout = setTimeout(async () => {
          this.poiRequest = new AbortController();
          const signal = this.poiRequest.signal;
          this.pointsOfInterest = await this.$API.getPois({
            params: this.poiParams,
            signal,
          });
          this.poiSearchLoading = false;
        }, 750);
      } else {
        clearTimeout(this.poiSearchTimeout);
        this.poiSearchLoading = false;
      }
    },
    statusColor(status) {
      let color = this.bookingStatus.find(
        (x) => x.name.toLowerCase() === status.toLowerCase()
      ).color;
      return color;
    },

    deleteCargoItem(item) {
      let find = this.bulkEdit.containerProducts.findIndex(
        (x) => x.hsCode == item.hsCode
      );
      if (find > -1) {
        this.bulkEdit.containerProducts.splice(find, 1);
        this.productListKey++;
      }
    },
    async duplicateContainer(original) {
      let bookingRef = this.booking.orderNo;
      let highest = this.booking.bookingContainers.reduce((max, container) => {
        let number = parseInt(container.ctoNo.split("/")[1]);
        return number > max ? number : max;
      }, 0);
      let high = highest + 1;
      let numRef = high < 10 ? `0${high}` : `${high}`;
      let newCTOnum = `${bookingRef}/${numRef}`;
      let duplicateContainer = {
        // ...original,
        // ctoNo: newCTOnum,
        temporaryProducts: original.temporaryProducts,
        bookingCargoItems: original.containerProducts,
        containerMilestones: original.containerMilestones.filter(
          (milestone) => milestone.type !== "PLUG_IN" && milestone.type !== "PLUG_OUT"
        ),
        transportCoordinator: original.transportCoordinator,
        booking: original.booking,
        organisationId: original.organisationId,
        transportCoordinatorId: original.transportCoordinatorId,
        bookingId: original.bookingId,
        isActive: true,
        isDeleted: false,
        setPointTemp: original.setPointTemp,
        isConfirmedBooking: original.isConfirmedBooking,
        humidity: original.humidity,
        nitrogen: original.nitrogen,
        carbonDioxide: original.carbonDioxide,
        oxygen: original.oxygen,
        caProduct: original.caProduct,
        verificationMethod: original.verificationMethod,
        transportStatus: 'Unallocated',
        nettWeight: original.nettWeight,
        grossWeight: original.grossWeight,
        packageType: original.packageType,
        containerTypeCode: original.containerTypeCode,
        gensetRequired: original.gensetRequired,
        type: original.type,
        isTempCheck: original.isTempCheck,
        tempRecorderType: original.tempRecorderType,
        // id: null,
        selected: false,
        duplicated: true,
        status: null,
        shipmentFile: null,
        shipmentFileId: null,
        statusLocation: null,
        quantity: 1
      };
      duplicateContainer.containerMilestones.forEach((milestone) => {
        milestone.id = null;
        milestone.date = null
        milestone.time = null
      });
      //remove containermilestone if milestone.type === 'PLUG_IN'
      duplicateContainer.bookingCargoItems.forEach((product) => {
        product.id = null;
      });
      duplicateContainer.temporaryProducts.forEach((product) => {
        product.id = null;
      });


      let res = await this.$API.createContainerItem(
        this.booking.id,
        duplicateContainer
      );
      if (res) {
        this.$message({
          type: "success",
          message: `The container has been succesfully cloned!`,
        });
        duplicateContainer.ctoNo = newCTOnum;
        duplicateContainer.id = res[0].id;
        // this.booking.bookingContainers.push(duplicateContainer);
        this.getContainers();
        this.addedCount++;
        this.containerKey++;
      }
      else {
        this.$message({
          type: "error",
          message: `Something went wrong while duplicating the container. Please try again. \n If the problem persists, please contact support.`,
        });
      }
    },
    async deleteContainer(item) {
      this.loadingCancel = true;
      let findChosen = this.chosenContainers.findIndex(
        (x) => x.ctoNo === item.ctoNo
      );
      if (findChosen > -1) {
        this.chosenContainers.splice(findChosen, 1);
      }
      if (item.duplicated) {
        let find = this.booking.bookingContainers.findIndex(
          (x) => x.ctoNo === item.ctoNo
        );
        if (find) {
          this.booking.bookingContainers.splice(find, 1);
          this.addedCount--;
        }
      } else if (item.deletedContainer) {
        let find = this.booking.bookingContainers.find(
          (x) => x.ctoNo === item.ctoNo
        );
        if (find) {
          find.isDeleted = true;
          find.selected = false;
          find.reinstated = false;
          this.reinstatedCount--;
        }
      } else {
        let find = this.booking.bookingContainers.find(
          (x) => x.ctoNo === item.ctoNo
        );
        if (find) {
          find.isDeleted = true;
          find.selected = false;
          this.deletedCount++;
        }
      }
    let result =  await this.$API.cancelBookingContainers({
        containers: [item.id],
      });

     if(result){
      this.getContainers();
      this.loadingCancel = false; //yes button
      this.confirmContainerCancel = false; //main loading
      

     }


    },
    async deleteMultipleContainers() {
      this.loadingCancel = true;
      for (let i = 0; i < this.chosenContainers.length; i++) {
        let item = this.chosenContainers[i];
        let findChosen = this.booking.bookingContainers.findIndex(
          (x) => x.ctoNo === this.chosenContainers[i].ctoNo
        );
        if (findChosen > -1) {
          this.chosenContainers.splice(findChosen, 1);
        }
        if (item.duplicated) {
          let find = this.booking.bookingContainers.findIndex(
            (x) => x.ctoNo === item.ctoNo
          );
          if (find) {
            this.booking.bookingContainers.splice(find, 1);
          }
        } else if (item.deletedContainer) {
          let find = this.booking.bookingContainers.find(
            (x) => x.ctoNo === item.ctoNo
          );
          if (find) {
            find.isDeleted = true;
            find.selected = false;
            find.reinstated = false;
          }
        } else {
          let find = this.booking.bookingContainers.find(
            (x) => x.ctoNo === item.ctoNo
          );
          if (find) {
            find.isDeleted = true;
            find.selected = false;
          }
        }
      }
    let result =  await this.$API.cancelBookingContainers({
        containers: this.chosenContainers.map((x) => x.id),
      });

      if(result){
      this.getContainers();
      this.loadingCancel = false;
      this.confirmContainerCancel = false;
      }
 
    },
    async getShipmentProfiles() {
      this.loadingShipmentProfiles = true;
      this.shipmentProfiles = await this.$API.listCustomerConsigneeProfile(
        this.booking.customerId,
        {}
      );
      this.loadingShipmentProfiles = false;
    },
    ISOWeek(dt) {
      var tdt = new Date(dt.valueOf());
      var dayn = (dt.getDay() + 6) % 7;
      tdt.setDate(tdt.getDate() - dayn + 3);
      var firstThursday = tdt.valueOf();
      tdt.setMonth(0, 1);
      if (tdt.getDay() !== 4) {
        tdt.setMonth(0, 1 + ((4 - tdt.getDay() + 7) % 7));
      }
      return 1 + Math.ceil((firstThursday - tdt) / 604800000);
    },
    async reinstateContainer(container) {
      if (container.status === "Cancelled") {
        let item = this.booking.bookingContainers.find(
          (x) => x.id == container.id
        );
        item.isDeleted = false;
        item.status = "Unallocated";
        item.reinstated = true;
        this.reinstatedCount++;
      }
      // else if (container.isDeleted && !container.deletedContainer) {
      //   let item = this.booking.bookingContainers.find(
      //     (x) => x.id == container.id
      //   );
      //   item.isDeleted = false;
      //   this.deletedCount--;
      // }
      else if (container.status == "Ghost") {
        let item = this.booking.bookingContainers.find(
          (x) => x.id == container.id
        );
        item.status = null;
        item.ghostReason = null;
        item.ghostParty = null;
        item.ghostNote = null;
        this.reinstatedCount++;
      }
      console.log("container before reinstate", container);
      let status = container.transportCoordinator
        ? container.status
        : "Unallocated";
      await this.$API.reinstateBookingContainer({
        container: container.id,
        status: status,
      });
    },
    markClaimContainer(container) {
      container.status = "Claim";
    },
    async bulkUpdateContainers() {
      this.savingBulkUpdate = true;
      let data = this.bulkEdit;
      let updateCount = 0;
      let updateArray = [];

      this.chosenContainers.forEach((container) => {
        if (container.selected === true) {
          // BOOKING CONTAINER ITEMS
          if (this.bulkEdit.verificationMethod) {
            // container.customerContainerRef = this.bulkEdit.verificationMethod;
            if (this.bulkEdit.verificationMethod == "Method 2") {
              container.verificationMethodOwner = null;
              container.weighBridgeId = null;
            } else {
              if (this.bulkEdit.verificationMethodOwner) {
                container.verificationMethodOwner =
                  this.bulkEdit.verificationMethodOwner;
              }
              if (this.bulkEdit.weighBridgeId) {
                container.weighBridgeId = this.bulkEdit.weighBridgeId;
              }
            }
          }
          if (this.bulkEdit.customerContainerRef) {
            container.customerContainerRef = this.bulkEdit.customerContainerRef;
          }
          if (this.bulkEdit.emptyPickupPoiId) {
            container.emptyPickupPoiId = this.bulkEdit.emptyPickupPoiId;
            container.emptyPickup = this.bulkEdit.emptyPickupPoi.name;
          }
          if (data.emptyPickupCode) {
            container.emptyPickupCode = this.bulkEdit.emptyPickupCode;
          }
          if (data.emptyPickupTime) {
            container.emptyPickupTime = this.bulkEdit.emptyPickupTime;
          }
          if (data.emptyPickupDateSlot && data.emptyPickupTimeSlot) {
            container.emptyPickupTime = new Date(
              data.emptyPickupDateSlot && data.emptyPickupTimeSlot
            );
          }

          if (this.bulkEdit.firstLoadingPointPoiId) {
            container.firstLoadingPointPoiId =
              this.bulkEdit.firstLoadingPointPoiId;
            container.firstLoadingPoint =
              this.bulkEdit.firstLoadingPointPoi.name;
          }
          if (data.firstLoadingPointCode) {
            container.firstLoadingPointCode =
              this.bulkEdit.firstLoadingPointCode;
          }
          if (data.firstLoadingPointTime) {
            container.firstLoadingPointTime = data.firstLoadingPointTime;
          }

          if (this.bulkEdit.secondLoadingPointPoiId) {
            container.secondLoadingPointPoiId =
              this.bulkEdit.secondLoadingPointPoiId;
            container.secondLoadingPoint =
              this.bulkEdit.secondLoadingPointPoi.name;
          }

          if (data.secondLoadingPointCode) {
            container.secondLoadingPointCode =
              this.bulkEdit.secondLoadingPointCode;
          }
          if (data.secondLoadingPointTime) {
            container.secondLoadingPointTime = data.secondLoadingPointTime;
          }
          if (this.bulkEdit.dropOffPointPoiId) {
            container.dropOffPointPoiId = this.bulkEdit.dropOffPointPoiId;
            container.dropOffPoint = this.bulkEdit.dropOffPointPoi.name;
          }
          if (data.dropOffPointCode) {
            container.dropOffPointCode = this.bulkEdit.dropOffPointCode;
          }
          if (data.dropOffPointTime) {
            container.dropOffPointTime = data.dropOffPointTime;
          }
          // CONTAINER PRODUCTS
          if (data.containerProducts && data.containerProducts.length > 0) {
            container.containerProducts = data.containerProducts;
          }
          if (data.containerMilestones && data.containerMilestones.length > 0) {
            container.containerMilestones = data.containerMilestones.map((x) => ({
             ...x,
             bookingContainerId: container.id
            }))
          }
          if (data.controlledAtmosphere == false) {
            container.controlledAtmosphere = false;
            container.caProduct = null;
          } else if (data.controlledAtmosphere) {
            container.controlledAtmospher = true;
            container.caProduct = data.caProduct;
          }

          if (data.status) {
            container.status = data.status;
          }

          // if (data.gensetRequired != null) {
          //   container.gensetRequired = data.gensetRequired;
          // }

          //remove gensetRequired from container.gensetRequired

          delete container.gensetRequired;

          if (data.comment) {
            container.containerComments = data.comment;
          }

          if (data.sensorDevice != null) {
            if (data.sensorDevice) {
              container.sensorDevice = data.sensorDevice;
              container.sensorDeviceType = data.sensorDeviceType;
            } else {
              container.sensorDevice = false;
              container.sensorDeviceType = null;
            }
          }
          if (data.sensorService) {
            container.sensorService = data.sensorService;
          }

          if (data.verificationMethod) {
            container.verificationMethod = data.verificationMethod;
          }
          // if (data.containerProducts.length>0) {
          //     container.containerProducts = data.containerProducts
          // }
          container.selected = false;
          updateArray.push(container);
          updateCount++;
        }
      });
      let obj = {
        bookingContainers: updateArray,
        bookingId: this.booking.id,
      };
      if (obj.bookingContainers.length > 0) {
        let result = await this.$API.updateBookingContainers(obj);
        if (result) {
          this.$message({
            type: "success",
            message: `Successfully updated ${updateCount} containers.`,
          });
          this.bulkEdit = {};
          this.chosenContainers = [];
          this.bulkUpdateCard = false;
        } else {
          this.$message({
            type: "error",
            message: `Something went wrong trying to update the containers. Please Try Again.\n 
                          If this error persists please contact the support team.`,
          });
          return;
        }
      }
      if (this.booking.integrationReference) {
        this.booking.integrationChange = true;
        await this.$API.updateBooking(this.booking);
        this.bookingChange = false;
      }
      this.getContainers();
      this.chosenContainers = [];
      this.isBulkEdit = false;
      this.savingBulkUpdate = false;
    },
    removeCustomer() {
      this.booking.customer = undefined;
      this.booking.customerId = undefined;
      this.booking.consigneeProfile = undefined;
      this.booking.consigneeProfileId = undefined;
      this.booking.dealTerm = undefined;
      this.booking.dealTermId = undefined;
      this.bookingChange = true;
    },

    setBulkStatus(status) {
      this.bulkEdit.status = status;
      this.statusMenuKey++;
    },

    async setContainerFile(file) {
      this.loadingFiles = true;
      this.fileItem.status = "Allocated";
      this.fileItem.shipmentFile = file;
      this.fileItem.shipmentFileId = file.id;
      await this.$API.updateContainer({
        id: this.fileItem.id,
        shipmentFileId: file.id,
        status: "Allocated",
      });
      this.$message.success("Successfully allocated container!");
      this.fileModal = false;
      this.loadingFiles = false;
    },
    async setContainerMultipleFile(file) {
      this.loadingFiles = true;
      for (let i = 0; i < this.chosenContainers.length; i++) {
        this.chosenContainers[i].status = "Allocated";
        this.chosenContainers[i].shipmentFile = file;
        this.chosenContainers[i].shipmentFileId = file.id;
        await this.$API.updateContainer({
          id: this.chosenContainers[i].id,
          shipmentFileId: file.id,
          status: "Allocated",
        });
      }
      // this.fileItem.status = 'Allocated'
      // this.fileItem.shipmentFile = file
      // this.fileItem.shipmentFileId = file.id
      // await this.$API.updateContainer({
      //     id: this.fileItem.id,
      //     shipmentFileId: file.id,
      //     status: 'Allocated'
      // })
      this.$message.success("Successfully allocated container!");
      this.fileModal = false;
      this.getContainers();
      this.chosenContainers = [];
      this.loadingFiles = false;
    },
    saveCargoItem() {
      if (this.cargoItem.type == "container") {
        let find = this.containerItem.bookingCargoItems.findIndex(
          (x) => x.index === this.cargoItem.index
        );
        this.cargoItem.productId = this.cargoItem.product.id;
        this.cargoItem.hsCodeId = this.cargoItem.hsCode.id;

        if (find > -1) {
          this.containerItem.bookingCargoItems[find] = this.cargoItem;
        } else {
          this.containerItem.bookingCargoItems.push(this.cargoItem);
        }
      } else {
        let find = this.booking.bookingCargoItems.findIndex(
          (x) => x.index === this.cargoItem.index
        );
        this.cargoItem.productId = this.cargoItem.product.id;
        this.cargoItem.hsCodeId = this.cargoItem.hsCode.id;
        if (find > -1) {
          this.booking.bookingCargoItems[find] = this.cargoItem;
        } else {
          this.booking.bookingCargoItems.push(this.cargoItem);
        }
      }
      this.cargoKey++;
      this.cargoModal = false;
      this.cargoItem = {};
    },
    async saveHaulier() {
      this.savingHaulier = true;
      if (this.containerItem.id) {
        await this.$API.updateContainer({
          id: this.containerItem.id,
          transportCoordinatorId: this.containerItem.transportCoordinatorId,
          isRailage: this.containerItem.isRailage,
          isCarrierHaulage: this.containerItem.isCarrierHaulage,
          isPrivateHaulage: this.containerItem.isPrivateHaulage,
          preAdviseRequired: this.containerItem.preAdviseRequired,
          transportStatus: this.containerItem.transportStatus,
          pluggedInRequired: this.containerItem.pluggedInRequired,
        });
      }

      this.haulierModal = false;
      this.savingHaulier = false;
      this.$message.success("Successfully updated haulage instructions!");
    },
    setCADetails(bulk = false) {
      if (!bulk) {
        if (this.containerItem.caProduct) {
          let find = this.caProducts.find(
            (x) => x.name == this.containerItem.caProduct
          );
          if (find) {
            this.containerItem.oxygen = find.oxygen;
            this.containerItem.carbonDioxide = find.carbonDioxide;
            this.containerItem.nitrogen = 0;
          }
        }
      } else {
        if (this.bulkEdit.caProduct) {
          let find = this.caProducts.find(
            (x) => x.name == this.bulkEdit.caProduct
          );
          if (find) {
            this.bulkEdit.oxygen = find.oxygen;
            this.bulkEdit.carbonDioxide = find.carbonDioxide;
            this.bulkEdit.nitrogen = 0;
          }
        }
      }
    },
    setCargoItemDetails() {
      if (this.cargoItem.product) {
        this.cargoItem.description = this.cargoItem.product.hsCode.explanation;
        this.cargoItem.hsCode =
          this.cargoItem.product.hsCode.alternateCode.replace(/ /g, "");
          this.cargoItem.productId = this.cargoItem.product.id;
      }
    },
    async setPointOfLoadingContainer(item) {
      switch (this.poiItem.type) {
        case "emptyLoadingPoint":
          this.poiItem.container.emptyPickup = item.name;
          this.poiItem.container.emptyPickupPoi = item;
          this.poiItem.container.emptyPickupPoiId = item.id;
          break;
        case "firstLoadingPoint":
          this.poiItem.container.firstLoadingPoint = item.name;
          this.poiItem.container.firstLoadingPointPoi = item;
          this.poiItem.container.firstLoadingPointPoiId = item.id;
          break;
        case "secondLoadingPoint":
          this.poiItem.container.secondLoadingPoint = item.name;
          this.poiItem.container.secondLoadingPointPoi = item;
          this.poiItem.container.secondLoadingPointPoiId = item.id;
          break;
        case "dropOffPoint":
          this.poiItem.container.dropOffPoint = item.name;
          this.poiItem.container.dropOffPointPoi = item;
          this.poiItem.container.dropOffPointPoiId = item.id;
          break;
      }
      await this.$API.updateContainer({
        id: this.poiItem.container.id,
        ...this.poiItem.container,
      });
      this.containerPOIModal = false;
      this.poiItem = null;
    },
    async saveSlot(item) {
      if (!this.bulkUpdateCard) {
        switch (this.poiItem.type) {
          case "emptyLoadingPoint":
            this.poiItem.container.emptyPickupTime = new Date(
              item.date + " " + item.time
            ).toISOString();
            break;
          case "firstLoadingPoint":
            this.poiItem.container.firstLoadingPointTime = new Date(
              item.date + " " + item.time
            ).toISOString();
            break;
          case "secondLoadingPoint":
            this.poiItem.container.secondLoadingPointTime = new Date(
              item.date + " " + item.time
            ).toISOString();
            break;
          case "dropOffPoint":
            this.poiItem.container.dropOffPointTime = new Date(
              item.date + " " + item.time
            ).toISOString();
            break;
        }
        await this.$API.updateContainer({
          id: this.poiItem.container.id,
          ...this.poiItem.container,
        });
        this.containerSlotModal = false;
        this.poiItem = null;
      } else {
        switch (this.poiItem.type) {
          case "emptyLoadingPoint":
            this.bulkEdit.emptyPickupTime = new Date(
              item.date + " " + item.time
            ).toISOString();
            break;
          case "firstLoadingPoint":
            this.bulkEdit.firstLoadingPointTime = new Date(
              item.date + " " + item.time
            ).toISOString();
            break;
          case "secondLoadingPoint":
            this.bulkEdit.secondLoadingPointTime = new Date(
              item.date + " " + item.time
            ).toISOString();
            break;
          case "dropOffPoint":
            this.bulkEdit.dropOffPointTime = new Date(
              item.date + " " + item.time
            ).toISOString();
            break;
        }
        this.containerSlotModal = false;
        this.poiItem = null;
      }
    },

    setFileProfile(profile) {
      this.file.consigneeProfile = profile;
      this.file.consigneeProfileId = profile.id;
      if (profile.consigneeProfileIncoTerms.length > 0) {
        if (this.booking.incoTerm) {
          let filterProfiles = profile.consigneeProfileIncoTerms.filter((x) => x.contractOwner === this.booking.contractOwner)

          let find = filterProfiles.find(
            (x) => x.incoTerm === this.booking.incoTerm
          );
          console.log('find',find)
          if (find) {
            this.setProfileIncoterm(filterProfiles);
          } else {
            this.setProfileIncoterm(profile.consigneeProfileIncoTerms[0]);
          }
        } else {
          let filterProfiles = profile.consigneeProfileIncoTerms.filter((x) => x.contractOwner === this.booking.contractOwner)

          console.log('find', find)
          this.setProfileIncoterm(filterProfiles[0]);
        }
      } else {
        this.file.term = null;
        this.file.incoTermId = null;
      }
      this.fileTab = 2;
    },
    setProfileIncoterm(term) {
      console.log('term',term)
      if (!Array.isArray(term)) {
          this.file.contractPartyId = term.contractPartyId;
          this.file.incoTermId = term.id;
          this.file.term = term.incoTerm;
          this.incoTermKey++;
        } else if (term.length === 1) {
          this.file.contractPartyId = term[0].contractPartyId;
          this.file.incoTermId = term[0].id;
          this.file.term = term[0].incoTerm;
          this.incoTermKey++;
        } else if (term.length > 1) {
          this.file.contractPartyId = null;
          this.file.incoTermId = null;
          this.file.term = null;
          this.incoTermKey++;
        }

    },


    async setComment() {
      this.savingContainerComment = true;
      await this.$API.updateContainer({
        id: this.selectedContainer.id,
        containerComments: this.selectedContainer.containerComments,
      });
      this.commentDialog = false;
      this.savingContainerComment = false;
    },
    async setVent() {
      this.savingVent = true;
      await this.$API.updateContainer({
        id: this.selectedContainer.id,
        vents: this.selectedContainer.vents,
      });
      this.ventDialog = false;
      this.savingVent = false;
    },
    async removeBuyer(organisation) {
      switch (this.partyType) {
        case "Consignee":
          this.containerItem.consignee = organisation;
          this.containerItem.consigneeId = organisation.id;
          if (this.containerItem.id) {
            this.savingParty = true;
            await this.$API.updatePartyIdToNull({
              id: this.containerItem.id,
              buyerId: this.containerItem.buyerId,
              consigneeId: this.containerItem.consigneeId = null,
            });
            this.savingParty = false;
            this.getContainers();
            this.partyModal = false;
            this.containerItem = {};
          } else {
            this.partyModal = false;
          }
          break;
        case "Buyer":
          this.containerItem.buyer = organisation;
          this.containerItem.buyerId = organisation.id;
          if (this.containerItem.id) {
            this.savingParty = true;
            await this.$API.updatePartyIdToNull({
              id: this.containerItem.id,
              buyerId: this.containerItem.buyerId = null,
              consigneeId: this.containerItem.consigneeId,
            });
            this.savingParty = false;
            this.getContainers();
            this.partyModal = false;
            this.containerItem = {};
          } else {
            this.partyModal = false;
          }
          break;
      }

    },
    async setParty(organisation) {
      switch (this.partyType) {
        case "Consignee":
          this.containerItem.consignee = organisation;
          this.containerItem.consigneeId = organisation.id;
          break;
        case "Buyer":
          this.containerItem.buyer = organisation;
          this.containerItem.buyerId = organisation.id;
          break;
      }
      if (this.containerItem.id) {
        this.savingParty = true;
        await this.$API.updateContainer({
          id: this.containerItem.id,
          buyerId: this.containerItem.buyerId,
          consigneeId: this.containerItem.consigneeId,
        });
        this.savingParty = false;
        this.getContainers();
        this.partyModal = false;
        this.containerItem = {};
      } else {
        this.partyModal = false;
      }
    },
   
    summaryContainerTypes(containers) {
      let uniqueContainerTypes = [
        ...new Set(
          containers
            .filter((x) => x.containerTypeCode)
            .map((x) => x.containerTypeCode)
        ),
      ];
      let result = uniqueContainerTypes.map(
        (x) =>
          containers.filter((y) => y.containerTypeCode == x).length + " x " + x
      );
      return result.join(", ");
    },
    summaryCountPallets(containers) {
      let products = [].concat.apply(
        [],
        containers.map((x) => x.containerProducts)
      );
      let uniqueQuantityTypes = [
        ...new Set(
          products.filter((x) => x.quantityType).map((x) => x.quantityType)
        ),
      ];
      let result = uniqueQuantityTypes.map(
        (x) =>
          products
            .filter((y) => y.quantityType == x && y.quantity)
            .map((y) => y.quantity)
            .reduce((a, b) => a + b, 0) +
          " x " +
          x +
          "(s)"
      );
      return result;
    },
    summaryCountTotalPallets(containers) {
      let products = [].concat.apply(
        [],
        containers.map((x) => x.containerProducts)
      );
      return products
        .filter((x) => x.pallets)
        .map((x) => x.pallets)
        .reduce((a, b) => a + b, 0);
    },
    async deleteSelectedContainerProduct() {
      let find = this.container.containerProducts.findIndex(
        (x) => x.id == this.container.selectedProduct.id
      );
      if (find > -1) {
        // await this.$API.updateContainerProduct({
        //   id: this.container.selectedProduct.id,
        //   isActive: false,
        //   isDeleted: true
        // });
        this.container.containerProducts.splice(find, 1);
      }
      this.containerProductModal = false;
      this.booking.isHazardous = this.booking.bookingContainers
        .filter(
          (x) =>
            !x.isDeleted &&
            (x.status == "Allocated" || x.status == null || x.status == "Short Shipped") &&
            x.bookingId == this.booking.id
        )
        .some((x) => x.containerProducts.some((y) => y.hazardous == true));
      this.$message.success("Successfully removed product!");
    },
    async updateSelectedContainerProduct(item) {
      this.containerProductModal = false;
      if (item.hazardous) {
        this.booking.isHazardous = true;
        this.bookingChange = true;
      } else {
        this.booking.isHazardous = this.booking.bookingContainers
          .filter(
            (x) =>
              !x.isDeleted &&
              (x.status == "Allocated" || x.status == null || x.status == "Short Shipped") &&
              x.bookingId == this.booking.id
          )
          .some((x) => x.containerProducts.some((y) => y.hazardous == true));
      }
      if (item.id) {
        await this.$API.updateContainerProduct(item);
      } else {
        item = {
          ...item,
          bookingId: this.booking.id,
          bookingContainerId: this.container.id,
          productId: item.product.id,
        };
        await this.$API.createContainerProduct(item);
      }
      this.container = null;
      this.$message.success("Successfully updated container product!");
      this.getContainers();
    },
    async updateContainerProduct(item) {
      if (this.bulkUpdateCard) {
        if (
          this.bulkEdit.containerProducts &&
          this.bulkEdit.containerProducts.length > 0
        ) {
          this.bulkEdit.containerProducts.push(item);
        } else {
          this.bulkEdit.containerProducts = [item];
        }
      }
      this.booking.isHazardous = this.booking.bookingContainers
        .filter(
          (x) =>
            !x.isDeleted &&
            (x.status == "Allocated" || x.status == null || x.status == "Short Shipped") &&
            x.bookingId == this.booking.id
        )
        .some((x) => x.containerProducts.some((y) => y.hazardous == true));
      // if (item.hazardous && !this.booking.isHazardous) {
      //   this.booking.isHazardous = true;
      //   this.bookingChange = true;
      // }
      this.productView = false;
    },
    routeToBooking(reference) {
      this.$router.push({ path: "/booking/" + reference });
    },
    async updateVents() {
      await this.$API.updateContainerVents({
        vents: this.booking.vents,
        bookingId: this.booking.id,
        containers: this.booking.bookingContainers
          .filter(
            (x) =>
              !x.isDeleted &&
              (x.status == "Allocated" || x.status == null || x.status == "Short Shipped") &&
              x.bookingId == this.booking.id
          )
          .map((x) => x.id),
      });
      this.booking.bookingContainers
        .filter(
          (x) =>
            !x.isDeleted &&
            (x.status == "Allocated" || x.status == null || x.status == "Short Shipped") &&
            x.bookingId == this.booking.id
        )
        .forEach((x) => (x.vents = this.booking.vents));
      this.$message.success("Successfully updated vent settings!");
    },
    async saveBooking() {
      try {
        let changeRegime = false
        this.savingBooking = true;
        // this.booking.bookingOffice = this.chosenBookingOffice
        if (this.booking.integrationReference) {
          this.booking.integrationChange = true;
        }
        this.booking.numContainers = this.booking.bookingContainers.filter(
          (x) =>
            !x.isDeleted &&
            (x.status == "Allocated" || x.status == null || x.status == "Short Shipped") &&
            x.bookingId == this.booking.id
        ).length;
        if (this.booking.integrationReference) {
          this.booking.integrationChange = true;
        }
        if (this.booking.changeRegime) {
          changeRegime = true
          delete this.booking.changeRegime
        }
        await this.$API.updateBooking(this.booking);

        if (changeRegime) {
          console.log('change in regimes')
          for (let i = 0; i < this.booking.bookingContainers.length; i++) {
            this.booking.bookingContainers[i].setPointTemp = this.booking.setPointTemp
          }
        }
        // this.booking.id = result.id;
        this.savingBooking = false;
        this.$message.success("Successfully updated booking!");
        this.bookingChange = false;
      } catch (e) {
        this.savingBooking = false;
        this.$message.error(e.message);
      }
    },
    saveContainerNo() {
      let obj = {
        id: this.selectedContainer.id,
        containerNo: this.containerNo,
      };
      let result = this.$API.updateContainerNo(obj);
      if (result) {
        let thisContainer = this.booking.bookingContainers
          .filter(
            (x) =>
              !x.isDeleted &&
              (x.status == "Allocated" || x.status == null || x.status == "Short Shipped") &&
              x.bookingId == this.booking.id
          )
          .find((x) => x.id === this.selectedContainer.id);
        thisContainer.containerNo = this.containerNo;
        this.containerNoDialog = false;
        this.$message({
          type: "success",
          message: `Container Number successfully updated`,
        });
      } else {
        this.$message({
          type: "error",
          message: `Something went wrong trying to update the Container Number. Please Try Again.\n 
                          If this error persists please contact the support team.`,
        });
      }
    },

    async saveCustomerRef() {
      this.customerRefDialog = false;
      let obj = {
        id: this.selectedContainer.id,
        customerContainerRef: this.customerRef,
      };
      let result = await this.$API.updateCustomerRef(obj);
      if (result) {
        let thisContainer = this.booking.bookingContainers
          .filter(
            (x) =>
              !x.isDeleted &&
              (x.status == "Allocated" || x.status == null || x.status == "Short Shipped") &&
              x.bookingId == this.booking.id
          )
          .find((x) => x.id === this.selectedContainer.id);
        thisContainer.customerContainerRef = this.customerRef;
        this.customerRefDialog = false;
        this.$message({
          type: "success",
          message: `Customer Reference successfully updated`,
        });
      } else {
        this.$message({
          type: "error",
          message: `Something went wrong trying to update the Customer Reference. Please Try Again.\n 
                          If this error persists please contact the support team.`,
        });
      }
    },
    async saveVerificationMethod() {
      try {
        this.savingVerificationMethod = true;
        let obj = {
          id: this.selectedContainer.id,
          verificationMethod: this.selectedContainer.verificationMethod,
          weighBridgeId:
            this.selectedContainer.verificationMethod == "Method 1"
              ? this.selectedContainer.weighBridgeId
              : null,
          verificationMethodOwner:
            this.selectedContainer.verificationMethod == "Method 1"
              ? this.selectedContainer.verificationMethodOwner
              : null,
        };
        await this.$API.updateVerificationMethod(obj);
        // this.selectedContainer.verificationMethod = this.verificationMethod
        this.containerMethodDialog = false;
        this.savingVerificationMethod = false;
        this.$message({
          type: "success",
          message: `Customer Reference successfully updated`,
        });
      } catch (e) {
        this.$message({
          type: "error",
          message: `Something went wrong trying to update the Customer Reference. Please Try Again.\n 
                          If this error persists please contact the support team.`,
        });
        this.savingVerificationMethod = false;
      }
    },
    saveSealNo() {
      let obj = {
        id: this.selectedContainer.id,
        sealNo: this.sealNo,
      };
      let result = this.$API.updateSealNo(obj);
      if (result) {
        let thisContainer = this.booking.bookingContainers
          .filter(
            (x) =>
              !x.isDeleted &&
              (x.status == "Allocated" || x.status == null || x.status == "Short Shipped") &&
              x.bookingId == this.booking.id
          )
          .find((x) => x.id === this.selectedContainer.id);
        thisContainer.sealNo = this.sealNo;
        this.sealNoDialog = false;
        this.$message({
          type: "success",
          message: `Seal Number successfully updated`,
        });
      } else {
        this.$message({
          type: "error",
          message: `Something went wrong trying to update the Seal Number. Please Try Again.\n 
                          If this error persists please contact the support team.`,
        });
      }
    },

    setFlagUpdate() {
      this.bookingChange = true;
    },
    viewFileDialog() {
    //  if (this.bookingChange) {
    //    this.saveChangeSnackbar = true;
    // } else {
        this.selectedContainers = [];
        this.fileTab = 0;
        let containers = this.booking.bookingContainers.filter(
          (x) =>
            !x.shipmentFileId &&
            !x.isDeleted &&
            x.bookingId == this.booking.id &&
            x.status != "Ghost" &&
            x.status != "Cancelled"
        );
        let findType = this.containerTypes.some(
          (x) =>
            containers.map((y) => y.containerTypeCode).includes(x.typeCode) &&
            x.typeCategory == "Reefer"
        );
        if (
          this.booking.movementType !== 'LOCAL' &&
          (!this.booking.originCountry ||
            !this.booking.destinationCountry ||
            findType
            ? !this.booking.regimeCode
            : false ||
            !this.booking.portOfLoadValue ||
            !this.booking.portOfDischargeValue ||
            !this.booking.finalDestinationValue ||
            !this.booking.vessel ||
            !this.booking.contractNumber ||
            !this.booking.mainCarriageConveyanceNumber ||
            !this.booking.carrierReferenceNumber ||
            !this.booking.etd ||
            !this.booking.eta)
          // || !this.booking.incoTerm
        ) {
          this.createFileError = true;
        } else {
          if (this.booking.consigneeProfile) {
            this.selectedContainers = this.booking.bookingContainers
              .filter(
                (x) =>
                  !x.shipmentFileId &&
                  !x.isDeleted &&
                  x.bookingId == this.booking.id &&
                  x.status != "Ghost" &&
                  x.status != "Cancelled"
              )
              .map((x) => x.id);

            // this.booking.bookingContainers.filter(x => !x.isDeleted).map(x => x.id)
            this.file = {
              consigneeProfileId: this.booking.consigneeProfileId,
              incoTermId: this.booking.dealTermId,
            };
            // this.confirmFileCreate = true
            this.createFileDialog = true;
          } else {
            this.file = {};
            this.createFileDialog = true;
          }
        }
     // }
    },
    updateBookingChange() {
      this.bookingChange = true;
    },
    async viewStockPallets(item) {
      this.selectedContainer = item;
      console.log('selected container', item)
      this.stockPalletLoading = true;
      this.stockPalletModal = true;
      this.stockPallets = await this.$API.getManagedAllocatedPallets(item.id);
      this.stockPalletLoading = false;
    },
    viewThisContainer(item) {
      this.selectedContainer = item;
      this.viewContainerModal = true;
    },
    viewContainerMenu(e, item) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.container = item.item;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },

    viewGhostContainer(item) {
      this.ghostContainer = item;
      this.ghostContainerModal = true;
    },
    viewShipmentFile(file) {
      this.selectedFile = file;
      this.previewKey++;
      this.previewShipmentFile = true;
    },
    trackContainerMetrics(item) {
      this.$refs.containerTrackingDialog.open({ containerReference: item.containerNo, bookingId: this.booking.id });
    },
  },
};
</script>

<style>
.colorBlock {
  height: 12px;
  width: 12px;
  border-radius: 2.5px;
}

.highlightRow {
  background-color: #418633 !important;
  color: #418633 !important;
}

.selectedClass {
  /* background-color: #418633 !important; */
  color: #418633 !important;
}

.disabledClass {
  opacity: 0.5;
  pointer-events: none;
}

.duplicatedClass {
  color: #418633 !important;
}

.pulse-beacon-orange {
  animation: pulse-beacon-orange 2s infinite;
  z-index: 10;
}

@keyframes pulse-beacon-orange {
  0% {
    border-radius: 50%;
    box-shadow: 0 0 0 0px rgb(237, 146, 8);
  }

  100% {
    border-radius: 50%;
    box-shadow: 0 0 0 20px rgb(0, 0, 0, 0);
  }
}

.details-container {
  margin-top: 20px;
  /* Adjust the top margin as needed */
  margin-bottom: 20px;
  /* Adjust the bottom margin as needed */
  margin-left: 20px;
  /* Adjust the left margin as needed */
}

.red-text {
  color: red;
}
</style>